import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function OftenRadio({value, handleChange}) {
  

  return (
   
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>Daily</p>
      </div>
     
      <FormControlLabel value='daily'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>Weekly</p>
      </div>
     
      <FormControlLabel value='weekly'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>Monthly</p>
      </div>
     
      <FormControlLabel value='monthly'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
     
      </RadioGroup>
   
  );
}