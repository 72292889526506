import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import Bank from '../../Assets/images/bank.png'
import Button from '../SelectValue/Button';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function BankAdded({ open, handleClose}) {
  
   
  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' h-[60vh] grid place-items-center'>
                <div className=''>
                    <img src={Bank} alt='Bank' className=' mx-auto'/>
                    <p className=' mt-7 text-[18px] font-[500] text-center text-back-color2'>Bank added</p>
                    <p className=' mt-4 my-10 text-[16px] text-center text-[#808285] font-[400]'>You have successfully added a bank account</p>
                   <Link to='/bankcards'>
                   <Button
                        name='Done'
                        bool ={true}
                    />
                   </Link> 
                </div>
                
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}