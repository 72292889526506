import React,{useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '../SelectValue/Button'
import Back from '../Back';
import FundWalletPin from './Pins';
import postService from '../../Services/post-service';
import { toast } from 'react-toastify';
import WalletSuccess from './WalletSuccess';
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield';
import Inputtt from '../SelectValue/Inputtt';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};


function Withdrawal({ open, handleClose}) {
  const [opeen, setOpeen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [amountt, setAmount] = useState('')
  const [reason, setReason] = useState('')
  const [oopen, setOopen] = useState(false)
  const handleCloose = () => setOpeen(false)
   
  const handleClooose = () => {
    window.location.reload()
  }
  const [pin, setPin] = useState('')
   
      const onSubmit = (e) => {
       e.preventDefault()
        setOpeen(true)
      }
      const FinalSubmit = (e) =>{


        e.preventDefault()
        console.log(amountt)
        setLoading(true)
        postService.Withdrawer(amountt * 100, pin, reason).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              // toast.success("Funds withdrawed successfully");
              // setTimeout(() => {
              //   window.location.reload()
              // }, 5000);
              setOpeen(false)
            setOopen(true)
             console.log(response.data.description)
             }else{
              toast.error(response.data.description);
             
             }
             
            },
            (error) => {
              setLoading(false)
              toast.error(error.message);
              setTimeout(() => {
                window.location.reload()
              }, 5000);
              }
        )
      }
  return (
    <div>  
    <FundWalletPin
      open={opeen}
      handleClose={handleCloose}
      loading={loading}
      pin={pin}
      setPin={setPin}
      onSubmit={FinalSubmit}
    />
    <WalletSuccess
      open={oopen}
      handleClose={handleClooose}
      header='Withdrawal successful'
      con={<p>You have withdrawn <span className='text-back-color2 font-[500]'>NGN {amountt}</span>  to your bank account</p>}
    />
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <Box sx={style} className=' rounded-lg p-6 pt-10'> 
        <div className=' h-[40vh] '>
            <div className=''>
              
              <div className=' flex justify-between mb-3'>
                   <div>
                    <Back/>
                   </div>
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
              
              </div>
              <div className=' mt-7'>
             
        <form onSubmit={onSubmit}>
            
               <NonnegativeInputField
                label='Enter amount(NGN)'
                valuee={amountt}
                onChange={setAmount}
              />  
               <div className=' my-3'>
              <Inputtt
                label='Reason for withdrawal'
                value={reason}
                onChange={setReason}
              />
              </div>
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Withdraw'
              />
              </div>
             
              
        </form>
              </div>
            </div>
            
        </div>
      </Box>
    </Fade>
  </Modal></div>
  )
}

export default Withdrawal