import axios from "axios";
import authHeader from "./AuthHeader";


const getAllBank = () => {
    return axios.get( "/api/get-banks");
}
const getUserBanks = () => {
    return axios.get( "/api/user-bankDetail", { headers: authHeader() });
}
const getTransactionHistory = () => {
    return axios.get( "/api/transaction-history", { headers: authHeader() });
}
const refreshToken = () => {
    return axios.get( "/api/refresh-token", { headers: authHeader() });
}
const verifyBankAccount = (accNum, bankCode) => {
    return axios.get( `/api/verify-accountNumber?accountNumber=${accNum}&bankCode=${bankCode}`, { headers: authHeader() });
}
const getNextOfKin = () =>{
    return axios.get( "/api/next-of-kin", { headers: authHeader() });
}
const getWallerBalance = () =>{
    return axios.get( "/api/get-balance", { headers: authHeader() });
}
const getAllProject = (location, maxPrice, minPrice,projectName, projectType) =>{
    return axios.get( `/api/all-projects?location=${location}&maxPrice=${maxPrice}&minPrice=${minPrice}&projectName=${projectName}&projectType=${projectType}`, { headers: authHeader() });
}
const getAllProperty = (location, maxPrice, minPrice,projectName, projectType) =>{
    //return axios.get( `/api/return-all-properties`, { headers: authHeader() });
    return axios.get( `/api/all-properties?maxPrice=${maxPrice}&minPrice=${minPrice}`, { headers: authHeader() });
}
const getSingleProject = (id, status) =>{
    return axios.get( `/api/property-id?propertyId=${id}`, { headers: authHeader() });
}
const getSingleInvestProject = (id, status) =>{
    return axios.get( `/api/project-id?projectId=${id}`, { headers: authHeader() });
}
const getUserInvestment = (status) =>{
    return axios.get(`/api/project-id`, { headers: authHeader() })
}
const getVerifyPayment = (ref) =>{
    return axios.get(`/api/verify-wallet?reference=${ref}`, { headers: authHeader() })
}
const getVerifySavingPayment = (ref) =>{
    return axios.get(`/api/verify-plan?reference=${ref}`, { headers: authHeader() })
}
const walletHistory = () =>{
    return axios.get('/api/transaction-history', { headers: authHeader() })
}
const savings = (status) =>{
    return axios.get(`/api/plan-id`, { headers: authHeader() })
}
const investmentHistory = (id) =>{
    return axios.get(`/api/project-id?projectId=${id}&status=recursive`, { headers: authHeader() })
}
const getSavingPlanDetails = (id, status) =>{
    return axios.get(`/api/plan-id?planId=${id}&savingStatus=${status}`, { headers: authHeader() })
}
const notification = () =>{
    return axios.get(`/api/get-notifications`, { headers: authHeader() })
}
const getSavedProduct = () =>{
    return axios.get(`/api/saved-items-details`, { headers: authHeader() })
}
const getServices = {
    getAllBank,
    verifyBankAccount,
    getUserBanks,
    getTransactionHistory,
    refreshToken,
    getNextOfKin,
    getWallerBalance,
    getAllProject,
    getAllProperty,
    getSingleProject,
    getSingleInvestProject,
    getUserInvestment,
    getVerifyPayment,
    getVerifySavingPayment,
    walletHistory,
    savings,
    investmentHistory,
    getSavingPlanDetails,
    notification,
    getSavedProduct
}

export default getServices;
