import  React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Back from '../Back';
import CancelIcon from '@mui/icons-material/Cancel';
import {  Link, Outlet } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function NamePlan({ handleClose}) {
      
  return (
    <div>
   
     
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' md:mt-4'>
                <div className=' flex justify-between mb-10'>
                    <Back/>
                    <div>
                   <Link to='/save'> <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="medium"/></Link>

                    </div>
                </div>
                <div className=' mb-5'>
                <Outlet/>
                </div>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}