import React, { useEffect, useState } from 'react'
import getServices from '../../Services/get-service';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { format, parseISO } from 'date-fns';
import Loading from '../SelectValue/Loading';
import Constant from '../../Constant';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CCF2E1',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
function Transactions() {
  const [transaction, setTransaction] = useState([])
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() =>{
    setLoading(true)
    getServices.walletHistory().then(
        (response) => {
          setTransaction(response.data.data.reverse());
          console.log(response.data.data)
          setLoading(false)
        },
        (error) => {
          
        }
      )
}, [])
const filteredData = transaction.filter(item => item.status  === 'success');
  return (
    <div className=' mt-6'>
    <div className=' flex mb-4 justify-between'>
        <p className=' text-2xl text-back-color2 text-[500]'>Transactions</p>
        <div>

        </div>

    </div>
    <Loading
      open={loading}
    />
    <Paper className=' ' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Transaction type</StyledTableCell>
            <StyledTableCell align="left">Date/Time</StyledTableCell>
            <StyledTableCell align="left">Amount</StyledTableCell>
            {/* <StyledTableCell align="left">Narrative</StyledTableCell> */}
            <StyledTableCell align="left">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tran) => (
                
            <TableRow
              key={tran.transactionDate}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
             <span className=' capitalize'>{tran.transType === 'funding' ? <span className=' text-green-600'>{tran.transType}</span> :   <span className=' text-red-600'>{tran.transType}</span>}</span> 
              </TableCell>
              <TableCell align="left">{format(parseISO(tran.transactionDate), 'MMM d yyyy hh:mm:ss a')}</TableCell>
              <TableCell align="left"> {tran.creditAmount === null ? tran.creditAmount :  Constant.FormatNumber(tran.creditAmount) } {tran.debitAmount === null ?tran.debitAmount: Constant.FormatNumber(tran.debitAmount) }</TableCell>
              {/* <TableCell align="left">{tran.narration}</TableCell> */}
              <TableCell align="left">{tran.status ==='success' ? 'Successfull':tran.status} </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {filteredData.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No transaction</p>}
    </Paper>
    </div>
  )
}

export default Transactions