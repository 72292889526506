import React from 'react'
import CreateWealth from '../../Components/Dashboard/CreateWealth'
import MakeInvestment from '../../Components/Dashboard/MakeInvestment'
import Start from '../../Components/Dashboard/Start'
import Topsec from '../../Components/Dashboard/Topsec'

function MainDashboaard() {
  return (
    <div>
        <Topsec/>
        <Start/>
        <div className=' mt-5 grid grid-cols-2 gap-4'>
            <MakeInvestment/>
            <CreateWealth />                      
        </div>
    </div>
  )
}

export default MainDashboaard