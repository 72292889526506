import React from 'react'
import House from '../../Assets/images/houso.png'
import StarRateIcon from '@mui/icons-material/StarRate';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
function AwaitInvite() {
  return (
    <div className=' border border-gray-200 my-3 shadow-md rounded p-3 '>
         <div className=' mb-3 flex justify-between'>
            <img src={House} alt='House'/>
            <div>
                <p className=' text-[#002615] p-2 text-[12px] rounded-[24px] bg-[#FDEAD2]'>Invite received</p>
            </div>
        </div>
        <div className=' mb-2 grid grid-cols-3'>
            <p className=' text-back-color2 font-[500] text-[14px]'>Name of building goes here</p>
            <p className=' text-back-color2 font-[400] text-[14px]'><StarRateIcon className=' text-primary-color'/>4.0 (25)</p>
        </div>
        <div className=' mb-1 grid grid-cols-3'>
            <p className=' text-back-color2 font-[500] text-[14px]'>ID- #123456</p>
            <p className=' text-back-color2 font-[400] text-end p-1 border-gray-200 border bg-[#F5F7FA] text-[14px]'>5 apartments classes</p>
        </div>
        <div className=' mb-2'>
                <p className='text-back-color2 font-[400] text-[14px]'><FmdGoodOutlinedIcon/> Lekki . Lagos state</p>
        </div>
    </div>
  )
}

export default AwaitInvite