import React from 'react'

function BuyDetails({des, cArea, statuss, tA, refs, date, type}) {
  return (
    <div>
        <div className=' p-3 mb-7 border border-primary-color6 rounded-lg'>
                <p className=' text-[16px] mb-5 font-[500] text-back-color2'>Land description</p>
                <p className=' text-[16px] mt-3 font-[400] text-[#808285]'>{des} </p>
        </div>
        <div className=' p-3 mb-7 border border-primary-color6 rounded-lg'>
                <p className=' text-[16px] mb-5 font-[500] text-back-color2'>Land details</p>
                <div className=' flex  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Covered area: <span className=' text-back-color2'>{cArea}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Market status: <span className=' text-back-color2'>{statuss}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Total area:  <span className=' text-back-color2'>{tA}</span></p>      
                </div>
                <div className=' flex  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Property Ref:  <span className=' text-back-color2'>{refs}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Added on: <span className=' text-back-color2'> {date}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Type:   <span className=' text-back-color2'>{type}</span></p>      
                </div>
                </div>
    </div>
  )
}

export default BuyDetails