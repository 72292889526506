import React, { useEffect, useState } from 'react'
import Land from '../../Assets/images/land.png'
import Bank from '../../Assets/images/bankk.png'
import InvestComp from './InvestComp'
import InvestCom from './InvestCom'
import { Link } from 'react-router-dom'
import getServices from '../../Services/get-service'
import { toast } from 'react-toastify'
import House from '../../Assets/images/house.png';
function MakeInvestment() {
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() =>{
        setLoading(true)
        getServices.getAllProject('',0.0,0.0,'','').then(
            
            (response) => {
                setLoading(false)
              if(response.data.status === "2000"){
                setProjects(response.data.data.reverse())
              }else{
               toast.error(response.data.description)
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                toast.error(error.message)
            }
          )
    }, [])
  return (
    <>
    <Link to='/buy'>
    <div className=' rounded-lg border border-[#D2D3D5] p-4'>
        <p className=' my-3 text-[#808285] text-[14px]'>Make a new investment</p>
        <div className=' my-5 grid md:grid-cols-2 gap-4'>
            <InvestComp
                img={Land}
                title='Land banking'
                sub='Join other investors to raise funds for projects and earn ROI based on your investment.'
                color='bg-[#FDEAD2] border-[#FCDBB4]'
            />
            <InvestComp
                img={Bank}
                title='Property banking'
                sub='Join other investors to raise funds for projects and earn ROI based on your investment.'
                color='bg-[#CCF2E1] border-[#AAE9CC]'
            />
        </div>
        <div>
            <div className=' mb-4 flex justify-between'>
                <p className=' text-[#808285] text-[16px]'>Top investments</p>
                <p className=' pb-2 border-b border-b-primary-color text-[14px] text-primary-color'>Go to investments</p>
            </div>
            <div className=' mt-4 grid md:grid-cols-2 gap-3'>
           
           {projects && projects
           .map((project, index) =>{
             if(index <2){
               return  <div key={project.id}>
               <InvestCom
              link={`/buy`}
              img={project.imageUrl ?? House} 
               percentage={project.percentSold}
               per={Math.floor(project.percentSold)}
               name={project.projectName}
               state={project.projecLocation ?? 'Lagos'}
               target={project.projectPriceTotal}
              raised={project.pricePerUnit * project.unitSold}
           />
             
               </div>
             }
             return null
            
           }).filter(Boolean)}
           </div>
           {projects.length === 0 && <div><p className=' text-center mt-9 text-red-700'>No data found </p>
              </div>} 
        </div>
    </div>
    </Link>
    </>
  )
}

export default MakeInvestment