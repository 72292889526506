import React from 'react'
import Loginform from '../../Components/Login/Loginform'
import Mobile from '../../Components/Mobile'

function Login() {

  return (
    <>
    <div className=' block md:hidden'>
      <Mobile/>
    </div>
    <div className='hidden md:block bg-signin-bg'>
    <div className='  grid w-10/12 md:w-8/12   mx-auto h-[100vh] place-items-center'>
   
      <Loginform/>
    </div>
    </div>
    </>
  )
}

export default Login