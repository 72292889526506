import axios from "axios";
import authHeader from "./AuthHeader";
import cookie from 'react-cookies'
const signupUser = (dob, email, mName, fName, gender, lName, pNum, password ) =>{
    const value = {"dateOfBirth" : dob, "middleName": mName, "emailAddress": email, "firstName": fName, "gender": gender, "lastName": lName, "password": password, "phoneNumber": pNum, "userRole": "FACILITATOR" }
    return axios.post('/api/users-signup', value );
}
const verifyEmail = (otp, requestId, userEmailAddress) =>{
    const value = {"otp":otp, "requestId": requestId, "userEmailAddress": userEmailAddress, "channel": "web",}

    return axios.post('/api/verify-email', value)
}


const setupTransactionPin = ( pim) =>{
    const tokens = cookie.load('jwttoken')
    const value = { "transactionPin":pim}

    return axios.post('/api/set-pin', value,{
        headers: {
          'Authorization': tokens 
        }
      } )
}
const resendOtp = (email) =>{
    return axios.post(`/api/re-sendOtp?email=${email}`)
}
const forgetPassword = (email) =>{

    return axios.post(`/api/forgot-password?email=${email}`)
}
const userLogin = (email, password) =>{
    const value = {"emailAddress": email, "password": password, "channel": "web",}
    return axios.post(`/api/user-login`, value)
}

const nextOfKins = (nextOfKinAddress,nextOfKinBankAccountNumber,nextOfKinBankName,nextOfKinEmail,nextOfKinFullname,nextOfKinGender,nextOfKinPhoneNumber,nextOfKinRelationship,transactionPin) =>{
    const value = {"nextOfKinAddress": nextOfKinAddress,
                     "nextOfKinBankAccountNumber": nextOfKinBankAccountNumber,
                     "nextOfKinBankName":nextOfKinBankName,
                     'nextOfKinEmail': nextOfKinEmail,
                     'nextOfKinFullname': nextOfKinFullname,
                     'nextOfKinGender': nextOfKinGender,
                     'nextOfKinPhoneNumber': nextOfKinPhoneNumber,
                     'nextOfKinRelationship': nextOfKinRelationship,
                     'transactionPin': transactionPin
                    }
    return axios.post(`/api/edit-next-ofKin`, value,{ headers: authHeader()})
}
const bankInfo = (accountNumber, bankName, transactionPin)=>{
        const value = { 'accountNumber': accountNumber, 'bankName': bankName, 'transactionPin': transactionPin}
        return axios.post(`/api/edit-bank-info`, value, { headers: authHeader()})
}
const changePin = (newPin, oldPin) =>{
    const value = {"newPin": newPin, "oldPin": oldPin}
    return axios.post(`/api/change-pin`, value, { headers: authHeader()})
}
const changePassword =(newPassword,transactionPin) =>{
    const value = {"newPassword": newPassword, "transactionPin": transactionPin}
    return axios.post(`/api/change-password`, value, { headers: authHeader()})
}
const resetPassword =(otp,requestId, password) =>{
    const value = {"otp": otp, "requestId": requestId, "password": password}
    return axios.post(`/api/reset-password`, value, { headers: authHeader()})
}
const addBankAcc =(accountNumber,bankName, transactionPin, bankCode) =>{
    const value = {"accountNumber": accountNumber, "bankName": bankName, "transactionPin": transactionPin, "bankCode": bankCode}
    return axios.post(`/api/add-bank-info`, value, { headers: authHeader()})
}
const forgetPin =() =>{

    const value = {}
    return axios.post(`/api/forgot-pin`, value, { headers: authHeader()})
}
const resetPin = (otp,requestId,pin) =>{
    const value = {"otp": otp, "requestId": requestId, "pin": pin}
    return axios.post(`/api/reset-pin`, value, { headers: authHeader()})
}
const verifyBvn = (account_number,bank_code,bvn) =>{
    const value = {"account_number": account_number, "bank_code": bank_code, "bvn": bvn}
    return axios.post(`/api/verify-bvn`, value, { headers: authHeader()})
}
const fundWallet = (amount, email, transactionPin) =>{
    const value = {"amount": amount, "callback_url": 'string', "email": email, "transactionPin": transactionPin}

    return axios.post(`/api/fund-wallet`, value, { headers: authHeader()})
}
const Withdrawer = (amount, pin, reason) =>{
    const value = {"reason": reason,"amount":amount,"transactionPin":pin}
    return axios.post(`/api/initiate-transfer`, value, { headers: authHeader()})
}
const savings = (amount, duration, frequency, planName, transactionPin, type, channel) =>{
    const value = {"amount":amount, "duration":duration,"frequency":frequency, "planName":planName, "transactionPin":transactionPin , "savingsType": type, "channel":channel}
    return axios.post(`/api/savings-plan`, value, { headers: authHeader()})
}
const buyProject= (projectId, units, transactionPin, method) =>{
    const value = {"paymentMethod":method, "projectId":projectId,"units":units,  "transactionPin":transactionPin }
    return axios.post(`/api/buy-project`, value, { headers: authHeader()})
}
const buyProperty= (projectId, units, transactionPin, method) =>{
    const value = {"paymentMethod":method, "propertyId":projectId, "units":units,  "transactionPin":transactionPin }
    return axios.post(`/api/buy-properties`, value, { headers: authHeader()})
}

const liquidate= (projectId, transactionPin, method) =>{
    const value = {"investmentId":projectId, "requestType":method,  "transactionPin":transactionPin }
    return axios.post(`/api/liquidate-project`, value, { headers: authHeader()})
}

const deAccount= (emailAddress, password) =>{
    const value = {"emailAddress":emailAddress, "password":password, }
    return axios.post(`/api/user-de-activate`, value, { headers: authHeader()})
}

const saveProduct= (id, type) =>{
    const value = {"projectId":id, "projectType":type, }
    return axios.post(`/api/buy-later`, value, { headers: authHeader()})
}



const postService ={
    signupUser,
    verifyEmail,
    liquidate,
    setupTransactionPin,
    resendOtp,
    userLogin,
    forgetPassword,
    nextOfKins,
    bankInfo,
    changePin,
    changePassword,
    resetPassword,
    addBankAcc,
    forgetPin,
    resetPin,
    verifyBvn,
    fundWallet,
    Withdrawer,
    savings,
    buyProject,
    buyProperty,
    deAccount,
    saveProduct
}

export default postService