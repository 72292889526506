import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/images/Zlogo.png'
function CreatePinForm() {
    const navigator = useNavigate()
    const [pin, setPin] =useState('')
    const onSubmit = (e) =>{
        e.preventDefault()
            navigator('/confirmpassword')
            localStorage.setItem('pin', pin)
        console.log(pin)
    }
  return (
    <div className=' bg-signin-bg h-[90vh]'>
    <div className='grid h-[85vh] p-3 md:p-0 place-items-center'>
    <div className=' md:w-1/3 bg-white p-5 rounded-[16px] shadow-lg  md:p-10'>
    <img src={Logo} width='152' height='40' className="  "  alt=" logo" /> 
        <h1 className=' pt-3 text-[18px] mt-4 text-back-color2 mb-5 md:text-[40px] font-[500]'>Create your Zouse PIN</h1>
       <p className=' mb-3 text-[#44474E]'>This PIN will be used for every transaction on Zouse. Keep it safe!</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Continue'
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
</div>
  )
}

export default CreatePinForm