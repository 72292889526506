import React from 'react'
import PaymentMethod from '../Buy/PaymentMethod'
import BlackButton from '../SelectValue/BlackButton'
import Button from '../SelectValue/Button'
import BuyButton from '../SelectValue/BuyButton'
import Inputtt from '../SelectValue/Inputtt'
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom'
function TargetCard() {
  return (
    <div>
         <div className=' md:mt-8'>
                
                <div className=' mt-4'>
                    <h1 className=' text-back-color2 text-[18px] mb-2 font-[500]'>How many units would you like to buy?</h1>
                    <div className=' my-3 flex gap-4'>
                    <BuyButton
                        name='1 units'
                    />
                    <BuyButton
                        name='2 units'
                    />
                    </div>
                    <div className=' my-3 flex gap-4'>
                    <BuyButton
                        name='3 units'
                    />
                    <BuyButton
                        name='4 units'
                    />
                    </div>
                </div>
                <div>
                    <p className=' my-2 text-[#1E222B]'>Or enter amount manually</p>
                    <Inputtt
                        placeholder='Amount'
                    />
                </div>
                <div className=' my-4 '>
                <p className=' font-[500] mb-3 text-back-color2 text-[16px]'>Payment method</p>
                
                </div>
                <div className=' w-full'>
                    <PaymentMethod/>
                </div>
                <div className=' mt-3 flex gap-3'>
                    <BlackButton
                        name='Choose other card'
                    />
                    <div>
                        <button className=' rounded-lg bg-[#FAFBFC] p-3 text-center text-primary-color'>
                        New card <span className=' pl-1'> <AddIcon/></span>
                        </button>
                    </div>
                </div>
              <Link to='/save/targetplanname/buytarget/notice'>
              <div className=' mt-16 mb-3 grid grid-cols-1'>
                   <Button
                        name='Add cash'
                       
                    />
                    
                </div>
              </Link>
                
               
            </div>
    </div>
  )
}

export default TargetCard