import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import postService from '../../Services/post-service';
import Loading from '../SelectValue/Loading';
import Logo from '../../Assets/images/Zlogo.png'
import cookie from 'react-cookies'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function VerifyAccountForm() {
    const navigator = useNavigate()
    const [open, setOpen] = useState(false)
    const [pin, setPin] =useState('')
   let user
   user = localStorage.getItem('user')
    let email = sessionStorage.getItem('sEmail')
    const userValue =  JSON.parse(user)


    
    const onSubmit = (e) =>{
      e.preventDefault()
     
      console.log(userValue.emailAddress)
       
        if(pin.length === 6){
          setOpen(true)
          postService.verifyEmail(pin, userValue.requestId, email).then(
            (response) => {
                console.log(response.data)
                setOpen(false)
               if(response.data.status === '2000'){
                cookie.save("jwttoken", response.data.data.jwtToken, {
                  path: "/",
                  expires: new Date(Date.now() + 60 * 10 * 1000),
                });
  
                 toast.success('Email verified successfully')  
                setTimeout(() => {
                  navigator('/createpin')
                }, 5000);
               }else{
                toast.error(response.data.description)
               setTimeout(() => {
                window.location.reload()
              }, 5000);
               
               }
               
              },
              (error) => {
                setOpen(false)
                toast.error('Email validation failed')
                setTimeout(() => {
                 window.location.reload()
               }, 5000);
                 
                }
          )
        } 
            // navigator('/createpin')
        console.log(pin)
    }
      const resendOtp = () =>{
        
          console.log('rtyyityfghgf')
          setOpen(true)
          postService.resendOtp(email).then(
            (response) => {
                console.log(response.data)
                setOpen(false)
               if(response.data.status === '2000'){
                
                 toast.success('Otp resent successfully !!')
                setTimeout(() => {
                  window.location.reload()
                }, 5000);

               
               }else{
                toast.error(response.data.description)
               setTimeout(() => {
                window.location.reload()
              }, 5000);
                // swal(response.data.description).then((value) => {
                //   window.location.reload()
                // });
               
               }
               
              },
              (error) => {
                setOpen(false)
                 toast.error('Otp resend failed!!')
                 setTimeout(() => {
                  window.location.reload()
                }, 5000);
                }
          )
        
      }
  return (
    <div className=' bg-signin-bg h-[95vh]'>
    <div className='grid h-[85vh] p-3 md:p-0 place-items-center'>
    <Loading
      open={open}
    />
    <ToastContainer/>
    <div className=' md:w-1/3 bg-white rounded-[16px] shadow-lg p-5 md:p-10'>
    <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
        <h1 className=' pt-7 text-[18px] text-back-color2 mb-5 md:text-[32px] font-[500]'>Please verify your account</h1>

       <p className=' mb-3 text-[#44474E]'>An OTP has been sent to your associated email address {userValue.emailAddress}. Enter the 6-digit code to verify your account.</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={6}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Verify account'
          />
        </div>
      
        </form>
        <div onClick={resendOtp}>
          <p className=' text-center'>Didn’t get OTP? <button className=' text-primary-color cursor-pointer font-[500] text-[16px]' > Resend</button></p>
        </div>
         
          </div>
      
        </div>
    </div>
</div>
</div>
  )
}

export default VerifyAccountForm