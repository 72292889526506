import React from 'react'

function StartComp({img, title, sub}) {
  return (
    <div>
        <div className=' p-4 flex gap-3 '>
        <div className=' pt-1'>
            <img src={img} alt='cardimg' />
        </div>
        <div>
            <p className='  text-back-color2 font-[500] text-[16px] mb-1'>{title}</p>
            <p className=' text-[#808285] font-[400] text-[14px] mb-3'>{sub}</p>
        </div>
    </div>
    </div>
  )
}

export default StartComp