import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function StartRadio() {
  const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
   
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>I’ll start today</p>
      </div>
     
      <FormControlLabel value="female" control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
        
       
      </RadioGroup>
   
  );
}