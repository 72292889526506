import React, {useEffect, useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom'
import SavingSuccess from './SavingSuccess';
import postService from '../../Services/post-service';
import getServices from '../../Services/get-service';
import Loading from '../SelectValue/Loading';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePaystackPayment } from 'react-paystack';


function MakePayment() {
    const [pin, setPin] =useState('')
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const email = user.emailAddress;
    const amount = sessionStorage.getItem('savingsAmount')
    const name = sessionStorage.getItem('savingsName')
    const fre = sessionStorage.getItem('savingsFre')
    const type =  sessionStorage.getItem('savingsType')
    //const startDate = sessionStorage.getItem('startSate')
   // const endDate = sessionStorage.getItem('savingEndDate')
    const duration = sessionStorage.getItem('savingDuration')
    //const originalDate = new Date(startDate);
    const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
    const [configP, setConfig] = useState(initialConfig);
    let initializePayment = usePaystackPayment(configP);
  

const navigate = useNavigate()
   const handleClose = () => setOpen(false)
   const handleOpen = () => setOpen(true)
    const onSubmit =  (e) =>{
        e.preventDefault()
        setLoading(true)
        postService.savings(
          amount * 100, 
          duration,
          fre,
          name,
          pin,
          type,
          [
            'card',
          ],
          ).then(
          (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            const reference =  response.data.data.reference
            console.log(reference)
            setConfig({
              ...configP, channel: [
                'card',
              ], email: email, reference: reference, amount: parseInt(amount) * 100,
            });
            
           console.log(response.data.description)
           }else{
             
             toast.error(response.data.description)
           }
           
          },
          (error) => {
            setLoading(false)
              return toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
              });
            })   
    }

    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      console.log(reference);
       verifyPayment(reference.reference)
    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }

  const verifyPayment = (ref) =>{
    setLoading(true)
    getServices.getVerifySavingPayment(ref).then(
      (response) => {
       setLoading(false)
        console.log(response.data)
        navigate('/save')
        
      },
      (error) => {
        setLoading(false)
        navigate('/save')
      }
    )
  }


  useEffect(() => {
    console.log(configP)
    if (configP.reference === null || configP.email === undefined) return;
    initializePayment(onSuccess, onClose)
  }, [configP])

  return (
    <div>
    <SavingSuccess
        open={open}
        handleClose= {handleClose}
    />
    <Loading
      open={loading}
    />
    <div className=' h-[40vh] '>
    <div className=''>
    <Loading
      open={loading}
    />

        <p className=' text-[18px] text-back-color2 mb-5  font-[500]'>Enter your Zouse PIN</p>
       <p className=' mb-3 text-[#44474E]'>Enter your 4-Digit PIN to confirm transaction</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Confirm'
            
          />
          
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
</div>
  )
}

export default MakePayment