import React from 'react'

function DashCardComp({img, title, sub, bool, leadBalance}) {
  return (
    <div className=' p-4 flex gap-3 border border-[#D2D3D5] rounded-lg'>
        <div className=' pt-1'>
            <img src={img} alt='cardimg' />
        </div>
        <div>
            <p className=' text-[#1A1A1B] text-[14px] mb-1'>{title}</p>
            <p className=' text-back-color2 font-[500] text-[24px] mb-3'>₦ {sub}</p>
           { bool && <div className=' flex justify-between'>
           <p className=' text-[#1A1A1B] text-[12px] mb-1'>Ledger Balance:  </p>
            <p className=' text-back-color2 font-[800] text-[14px] mb-3'> ₦{leadBalance}</p>
           </div>}
        </div>
    </div>
  )
}

export default DashCardComp