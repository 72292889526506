import React from 'react'
import { Drawer } from '@mui/material';
import House from '../../Assets/images/rentrental.png'
import StarIcon from '@mui/icons-material/Star';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '../SelectValue/Button';
const drawerWidth = 650;
function RentalSidebar({open, handleClose}) {
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
        <div >
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 capitalize text-[18px] pt-3'>Rent details</p>
            <div className='pt-2'>
            <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/>
            </div>  
        </div>
        <div className=' p-3'>
        <img src={House} alt='house'/>
            <div className=' mt-6'>
            <p className=' text-[14px] font-[500] text-back-color2 mb-3'>5-Bed apartment in Lekki</p>
            <p className='text-[14px] font-[700] text-back-color2'>NGN250,000<span className='  text-[14px] text-[#808285] font-[400]'>/year</span></p>
            <div className=' mt-3 flex gap-4 '>
           <p className=''><StarIcon className=' text-primary-color '/> 4.0 (26) <FmdGoodOutlinedIcon/> Lekki . Lagos state  </p> 
            </div>
            <div className=' my-3 bg-[#F5F7FA] border-b-primary-color border-b-2 p-3 '>
                <div className=' mb-2 flex justify-between'>
                    <div>
                        <p className=' text-[#696C72] font-[500] text-[14px]'>Amount paid</p>
                    </div>
                    <div>
                        <p className=' text-primary-color font-[500] text-[14px]'>NGN 750,000</p>
                    </div>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <div>
                        <p className=' text-[#696C72] font-[500] text-[14px]'>Rent tenure</p>
                    </div>
                    <div>
                        <p className=' text-back-color2 font-[500] text-[14px]'>2years(1 year, 200 days left)</p>
                    </div>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <div>
                        <p className=' text-[#696C72] font-[500] text-[14px]'>Rent expires</p>
                    </div>
                    <div>
                        <p className=' text-back-color2 font-[500] text-[14px]'>Sep 4, 2021</p>
                    </div>
                </div>
            </div>
            <div className=' my-4 border border-gray-200 rounded p-3'>
                <p className=' text-[#808285] font-[500] text-[14px]'>Rent payment history</p>
                <div className=' mb-2 flex justify-between'>
                    <div>
                        <p className=' text-back-color2 font-[500] text-[16px]'>3 years rent payment</p>
                        <p className=' text-[#808285] font-[400] text-[14px]'>Success</p>
                    </div>
                    <div>
                        <p className=' text-primary-color font-[500] text-[16px]'>+NGN750,500.00</p>
                        <p className=' text-[#808285] font-[400] text-end text-[14px]'>Sep 4, 2021</p>
                    </div>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <div>
                        <p className=' text-back-color2 font-[500] text-[16px]'>3 years rent payment</p>
                        <p className=' text-[#808285] font-[400] text-[14px]'>Success</p>
                    </div>
                    <div>
                        <p className=' text-primary-color font-[500] text-[16px]'>+NGN750,500.00</p>
                        <p className=' text-[#808285] font-[400] text-end text-[14px]'>Sep 4, 2021</p>
                    </div>
                </div>
            </div>
            <div className=' grid mt-10'>
                <Button
                    name='Renew Rent'
                />
            </div>
            </div>
        </div>
            
        </div>
        </Drawer>
    </div>
  )
}

export default RentalSidebar