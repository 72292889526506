
import  React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import PinInput from 'react-pin-input';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import postService from '../../Services/post-service';
import { useNavigate } from 'react-router-dom';
import Loading from '../SelectValue/Loading';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
   
    boxShadow: 24,
   
  };
function LiquidateInvestment({handleClose, open, id}) {
    const [pin, setPin] =useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
        postService.liquidate(id,pin,'investment').then(
            (response) => {
             setLoading(false)
             if(response.data.status === '2000'){
                toast.success(response.data.description)
                setTimeout(() => {
                    navigate('/buy')
              }, 2000);
                
             }else{
                toast.error(response.data.description)
             }
              
            
            },
            (error) => {
              setLoading(false)
            
              navigate('/buy')
            
            }
          )
    }
  return (
    <div>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
     
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
                open={loading}
           />
            <div className=' md:mt-8'>
                <div className=' flex justify-between mb-3'>
                    <div></div>
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="medium"/>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
               <div>
               <div>
               <h1 className=' font-[800] text-[24px] mb-2'>Enter your Zouse PIN</h1>
               <p className=' text-[14px] mb-3'>Enter your 4-Digit PIN to confirm transaction</p>
              <PinInput
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    // handleSubmit()
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
               </div>
                
                
                <div className=' mt-16 mb-3 grid grid-cols-1'>
                    <Button
                     name='Confirm'
                    />
                </div>
                </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}

export default LiquidateInvestment