import  React, {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
// import BuyButton from '../SelectValue/BuyButton';
// import Inputtt from '../SelectValue/Inputtt';
// import PaymentMethod from './PaymentMethod';
// import BlackButton from '../SelectValue/BlackButton';
// import AddIcon from '@mui/icons-material/Add';
import {  Outlet } from 'react-router-dom';
import WalletPayment from '../Buy/WalletPayment';
import Checkout from './Checkout';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function BuyUnits({ open, handleClose, number, amount, id, type}) {
     let num = number ?? 1
     const numbers = Array.from({ length: num }, (_, index) => index + 1);
     const [unit, setUnit] = useState(1)
     const [value, setValue] = useState('card')
    const [opens, setOpens] = useState(false)
    const handleOpen = () =>{
      setOpens(true)}
    const handleClosez = () => setOpens(false)

    const handleSubmit = (e) =>{
      e.preventDefault()
        console.log(unit)
        console.log(value)
    }
  return (
    <div>
    <Checkout
     open={opens}
     handleClose={handleClosez}
     amount= {amount}
     unit= {unit}
     id= {id}
     payment={value}
    />
     <Outlet/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' md:mt-8'>
                <div className=' flex justify-end mb-3'>
                    {/* <Back/> */}
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="medium"/>
                    </div>
                </div>
                <form onSubmit={ handleSubmit}>

               
                <div className=' mt-4'>
                {type === 'land' ? 
                <div>
                <h1 className=' text-back-color2 text-[18px] mb-2 font-[500]'>How many units would you like to buy?</h1>
                    <select
                     className=' w-full p-3 border border-gray-100'
                     value={unit}
                     onChange= {(e) => setUnit(e.target.value)}
                    >
                      {/* Map over the array of numbers to generate options */}
                      {numbers.map(number => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                </div>
                : null
                }
                </div>
                {/* <div>
                    <p className=' my-2 text-[#1E222B]'>Or enter amount manually</p>
                    <Inputtt
                        placeholder='Amount'
                    />
                </div> */}
                <div className=' my-4 '>
                <p className=' font-[500] mb-3 text-back-color2 text-[16px]'>Payment method</p>
                
                </div>
                <div className=' w-full'>
                    <WalletPayment
                      value={value}
                      onchange={setValue}
                    />
                </div>
                {/* <div className=' mt-3 flex gap-3'>
                    <BlackButton
                        name='Choose other card'
                    />
                    <div>
                        <button className=' rounded-lg bg-[#FAFBFC] p-3 text-center text-primary-color'>
                        New card <span className=' pl-1'> <AddIcon/></span>
                        </button>
                    </div>
                </div> */}
              
                <div className=' mt-16 mb-3 grid grid-cols-1'>
                   <Button
                        name='Check out'
                        onClick={handleOpen}
                    />
                    
                </div>
                </form>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}