import React, {useState} from 'react'
import Land from '../../Assets/images/land.png'
import Bank from '../../Assets/images/bankk.png'
import MakeInvestmentComp from './MakeInvestmentComp'
import LandDrawer from './LandDrawer'
import PropertyDrawer from './PropertyDrawer'
function MakeInvestment() {
  const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const [open1, setOpen1] = useState(false)
    const handleClose1 = () => setOpen1(false)
    const handleOpen1 = () => setOpen1(true)
  return (
    <div className=' my-4'>
    <LandDrawer
        open={open}
        handleClose={handleClose}
      />
      <PropertyDrawer
         open={open1}
        handleClose={handleClose1}
      />
        <p className=' text-[#808285] text-[14px] font-[500] mb-3'>Make a new investment</p>
        <div className=' grid sm:grid-cols-2 xl:grid-cols-3 gap-4'>
            <MakeInvestmentComp
                img={Land}
                title='Land banking'
                sub='Join other investors to raise funds for projects and earn ROI based on your investment.'
                color='bg-[#FDEAD2] border-[#FCDBB4]'
                onClick={handleOpen}
            />
            <MakeInvestmentComp
                img={Bank}
                title='Property banking'
                sub='Join other investors to raise funds for projects and earn ROI based on your investment.'
                color='bg-[#CCF2E1] border-[#AAE9CC]'
                onClick={handleOpen1}
            />
        </div>
    </div>
  )
}

export default MakeInvestment