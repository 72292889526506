import React, { useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import Button from '../SelectValue/Button'
import OftenRadio from './OftenRadion'

function HowOften() {
   const [freq, setFreq] = useState('')
   const navigate = useNavigate()
      const submit = (e) =>{
           e.preventDefault()
           sessionStorage.setItem('savingsFre', freq)
          navigate('/save/plan/createpayment')
      }
  return (
   <div className=''>
    <div className=' h-[60vh] '>
        <p className=' text-[18px] text-back-color2 mb-5 font-[500]'>How often would you like to save?</p>
       <form onSubmit={submit}>
       <div className='  mt-5'>
                <OftenRadio
                  value={freq}
                  handleChange={setFreq}
                />
        </div>
        
        <div className=' grid grid-cols-1  mt-5'>
        <Button
                name='Next'
            />
            </div>
       
       </form>
           
    </div>
</div>
  )
}

export default HowOften