import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {  Outlet, useNavigate } from 'react-router-dom';
const drawerWidth = 650;

function ProjectDetails({open, handleClose}) {
    const navigate = useNavigate();
    
    const handleBack = () => navigate(-1)
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={true}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
       <div className=' p-4 border-b border-b-[#e2e4e6] flex justify-between'>
          <div className=' flex gap-5'>
          <div className=' pt-3'>
          <KeyboardBackspaceIcon onClick={handleBack} className=' cursor-pointer'/>
          </div>
          
            <p className=' text-back-color2 text-[18px] pt-3'>Project details</p>
          </div>
            
            <div className='pt-2'>
            <CancelIcon onClick={handleBack} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
            </div>  
        </div>
          <div className='mx-6 my-5'>
          
            <div className=' my-4'>
                <Outlet/>
            </div>
          </div>
       </div>
         
        </Drawer>
    </div>
  )
}

export default ProjectDetails