import React from 'react'
import Target from '../../Assets/images/targett.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import House from '../../Assets/images/hs.png'
import Tag from '../../Assets/images/tar1.png'
import ChooseComp from './ChooseComp';
import { Link } from 'react-router-dom';
function CreateWealth() {
  return (
    <>
    <Link to='/save'>
    <div className=' rounded-lg border border-[#D2D3D5] p-4'>
        <div className=' my-4 flex justify-between'>
                <p className=' text-[#808285] text-[16px]'>Create wealth</p>
                <p className=' pb-2 border-b border-b-primary-color text-[14px] text-primary-color'>See saving plans</p>
            </div>
            <div className=' my-4 rounded-lg border border-[#D2D3D5] p-4'>
                <div className=' flex gap-3 justify-between'>
                <div className=' flex gap-3'>
                <img src={Target} alt='Target'/>
                <div>
                    <p className=' text-[14px] text-[#808285] mb-3'>Target savings </p>
                    <p className=' text-[16px] font-[500] text-[#002615] mb-3'>NGN 300,000</p>
                </div>
                </div>
                <div className=' pt-5'>
                  <KeyboardArrowRightIcon className='  text-back-color2'/>
                  </div>
                </div>
            </div>
            <div className=' my-7'>
                <p className=' text-[#808285] mt-3 text-[14px] font-[500]'>Choose a savings plan</p>
                <div className=' mt-5 grid gap-4 md:grid-cols-2'>
                    <ChooseComp
                        img={House}
                        title='Rent Savings'
                        sub='Save towards your rent with the Rent savings plan'
                        color='bg-[#FDEAD2] border-[#FCDBB4]'
                    />
                     <ChooseComp
                        img={Tag}
                        title=' Target savings'
                        sub='Save towards an investment with the target savings plan'
                        color='bg-[#CCF2E1] border-[#AAE9CC]'
                    />
                </div>
            </div>
    </div>
    </Link>
    </>
  )
}

export default CreateWealth