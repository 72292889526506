import React, { useState } from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Save from '../../Assets/images/saa.svg'
import Progress from '../Home/Progress';
import { Link } from 'react-router-dom';
import Constant from '../../Constant';
import postService from '../../Services/post-service';
import { toast } from 'react-toastify';
import Loading from '../SelectValue/Loading';

function InvestCom({name, state, per,img, percentage, target, raised, link, id}) {
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
          postService.saveProduct(id,'investment').then(
            (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
                toast.success("Investment Saved");
                // setTimeout(() => {
                //   window.location.reload()
                // }, 5000);
            
              }else{
                toast.error(response.data.description,
                  {
                      position: toast.POSITION.TOP_LEFT
                    }
                  );
             // setTimeout(() => {
               // window.location.reload()
               //}, 5000);
            
             
            }
             
            },
            (error) => {
              setLoading(false)
                 toast.error(error.message, {
                  position: toast.POSITION.TOP_LEFT
                });
                setTimeout(() => {
               window.location.reload()
               }, 5000);
              }
          )
        
        
      }
  return (
    <div className=' p-2  border-gray-200 shadow rounded-lg '>
        <Loading
            open={loading}
        />
        <div>
        <Link to={link}>
            <img src={img}  className=' w-full h-48' alt='House'/>
        </Link>
            <div  className=' flex justify-between mt-3 mb-3 '>
                <p className=' text-back-color2 font-[500] text-[16px]  capitalize '>{name}</p>
                <img src={Save} onClick={handleSubmit} className=' inline-block cursor-pointer' alt='save'/>
            </div>
            <Link to={link}>
            <div className=' mb-4'>
                <PlaceOutlinedIcon className='text-primary-color6'/> <p className=' text-[#44474E] inline-block pl-1'>{state}</p>
            </div>
            <div>
                <p className=' pb-2'>{per}% sold</p>
                <Progress value={percentage}/>
                <div className=' mb-4 flex justify-between'>
                    <p className='text-primary-color6'>Target</p>
                    <p className='text-primary-color6'>Raised</p>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <p>NGN {Constant.FormatNumber(target) }</p>
                    <p className=' text-primary-color'>NGN {Constant.FormatNumber(raised)}</p>
                </div>
            </div>
            </Link>
        </div>
        
    </div>
  )
}

export default InvestCom