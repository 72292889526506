import React from 'react'
import YouTubeVideoComponent from './Videoo'
function Video({url}) {
  return (
    <div className=' bg-[#E6E6E7] rounded-lg grid h-[35vh] place-items-center'>
       <YouTubeVideoComponent
        videoId={url}
       />
    </div>
  )
}

export default Video