import React from 'react'
import ForgetPasswordForm from '../../Components/Login/ForgetPasswordForm'
import NavbarBack from '../../Components/NavbarBack'


function ForgetPassword() {
  return (
    <div >
      <NavbarBack/>
      <ForgetPasswordForm/>
    </div>
  )
}

export default ForgetPassword