import  React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import Back from '../Back';
import CancelIcon from '@mui/icons-material/Cancel';
import Payment from './Payment';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function Checkout({open, handleClose, amount, unit, id, payment}) {
   const [opens, setOpens] = useState(false)
    const handleOpen = () => {
        handleClose()
        setOpens(true)
    }
    const handleCloses = () =>  setOpens(false)
  return (
    <div>
     <Payment
            open={opens}
            handleClose={handleCloses}
            id={id}
            unit={unit}
            method={payment}
            amount= {unit * amount}
        />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' md:mt-8'>
                <div className=' flex justify-end mb-3'>
                    {/* <Back/> */}
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="medium"/>
                    </div>
                </div>
                <div className=' mt-4'>
                    <h1 className=' text-back-color2 text-[18px] mb-2 font-[500]'>Summary</h1>
                    <div className=' my-4 flex justify-between'>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-1'>UNITS</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] '>{unit}</p>
                        </div>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-1'>VALUE PER UNIT</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] text-end'>NGN {amount}</p>
                        </div>
                    </div>
                    <div className=' my-4 flex justify-between'>
                        <div>
                            <p className=' text-[14px] text-primary-color6'>TOTAL VALUE</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] '>NGN {unit * amount}</p>
                        </div>
                        <div>
                            <p className=' text-[14px] text-primary-color mb-2'>2% PROCESSING FEE</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] text-end '>+NGN 0</p>
                        </div>
                    </div>
                    <div className=' my-4 flex justify-between'>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-2'>SUM TOTAL</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] text-start '>NGN {unit * amount}</p>
                        </div>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-2'>PAYMENT METHOD</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] capitalise text-end'>{payment} <span onClick={handleClose} className=' p-1 text-white  cursor-pointer text-[14px] rounded-[32px] bg-[#F7941D]'>Change</span></p>
                        </div>
                    </div>
                </div>
                
                
                <div className=' mt-16 mb-3 grid grid-cols-1'>
                    <Button
                        name={`Pay ${unit * amount}`}
                        onClick={handleOpen}
                    />
                </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}