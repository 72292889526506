
import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import ForgetPassword from './Pages/Login/ForgetPassword';
import NewPassword from './Pages/Login/NewPassword';
import ResetPassword from './Pages/Login/ResetPassword';
import Signup from './Pages/Signup';
import ConfirmPassword from './Pages/Signup/ConfirmPassword';
import CreatePin from './Pages/Signup/CreatePin';
import VerifyAccount from './Pages/Signup/VerifyAccount';
import React from 'react';
import cookie from 'react-cookies'
import Delete from './Pages/Delete';

const Logout = React.lazy(() => import('./Pages/logout'));
class App extends React.Component {

  constructor(props) {
    super(props);
    // var token = localStorage.getItem('token');
    var tokens = cookie.load('token')
    console.log(tokens)
    if(tokens === undefined){
      this.loggedIn = false;
     
    }else{
      this.loggedIn = true;
      // setTimeout(this.logout, 100000);
     
    }
  }

  logout() {
    // localStorage.removeItem('token');
    cookie.remove("token");
    localStorage.removeItem('userdetails');
    localStorage.removeItem('accountStatuss');
    this.loggedIn = false;
    window.location.href = '/';
  }
    render() {
    return this.loggedIn === true ? (
      <div>
      <BrowserRouter>
      <Routes>
        
        <Route path='/logout' element={ <Logout /> } />
        <Route path='*' element={ <Dashboard /> } />
      </Routes>

      
      </BrowserRouter>  
      </div>
    ): (
      <div>
      <BrowserRouter>
   
      <Routes>
        <Route path='/' element={<Login/>}/>
          <Route path='/forgetpassword' element={<ForgetPassword/>}/>
          <Route path='/newpassword' element={<NewPassword/>}/>
          <Route path='/resetpassword' element={<ResetPassword/>}/>
          <Route path='/deleteaccount' element={<Delete/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/confirmpassword' element={<ConfirmPassword/>}/>
          <Route path='/createpin' element={<CreatePin/>}/>
          <Route path='/verifyaccount' element={<VerifyAccount/>}/>
           <Route path='*'  element={<Login/>} />
      </Routes>
      </BrowserRouter>  
      </div>
    )
      ;
  }
}

export default App;
