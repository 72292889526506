import React from 'react'
import {  useLoadScript,  } from "@react-google-maps/api";
import LocationPin from './LocationPin'
function Map() {
   
     
      const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAgT2Z6IV7Q0dZd1jgKaoKlXZJkpW-sEEw',
      });
      if (!isLoaded) return <div>Loading...</div>;
        return <LocationPin />;
    }
 


export default Map