import React from 'react'
import ActiverentCom from './ActiverentCom'

function Activerent() {
  return (
    <div className=' pt-4'>
        <ActiverentCom/>
        <ActiverentCom/>
        <ActiverentCom/>
    </div>
  )
}

export default Activerent