import React, { useEffect, useState } from 'react'
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import { TablePagination } from '@mui/material';
import House from '../../Assets/images/hh.png';
import Loading from '../SelectValue/Loading';
import ListingCom from '../Listing/ListingCom';
import ListingDrawer from '../Listing/ListingDrawer';
import ListPropertyDrawer from '../Listing/ListPropertyDrawer';
function Listing() {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = useState(false)
  const handleClose1 = () => setOpen1(false)
  const [id, setId] = useState()

  const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
      useEffect(() =>{
        setLoading(true)
        getServices.getSavedProduct().then(
            
            (response) => {
                setLoading(false)
              if(response.data.status === "2000"){
                setProjects(response.data.propertyPlan.reverse())
              }else{
               swal(response.data.description)
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                swal('No project found')
            }
          )
    }, [])
  return (
    <div className=' mt-4'>
    <Loading
      open={loading}
    />
    <ListingDrawer
        open={open}
        handleClose={handleClose}
        id={id}
    />
    <ListPropertyDrawer
        open={open1}
        handleClose={handleClose1}
        id={id}
    />
    <div className=' grid sm:grid-cols-1 lg:grid-cols-2 gap-4'>
    { projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(project =>{
            return  <div key={project.id}>
            <ListingCom
                    name={project.title}
                    saveStatus={true}
                    id={project.propertyId}
                    img={project.imageUrl[0] ??Listing }
                    header={project.propertyType}
                    location={project.locality}
                    dis={project.description === null ?project.description : project.description.slice(0,150) }
                    date={project.createdDate === null ? project.createdDate :project.createdDate.slice(0,10)}
                    amount={project.price}
                    area={project.totalArea}
                    onClick={() =>{
                      project.propertyType === 'property'?
                      setOpen1(true) :
                      setOpen(true)
                        setId(project.propertyId)
                    }}
                />

                </div> 
             
            })}
            
    </div>
    {projects.length === 0 && <div><p className=' text-center mt-9 text-red-700'>No data found </p>
               </div>} 
               <div className=' flex justify-center'>
               <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={projects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
    </div>
  )
}

export default Listing