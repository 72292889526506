import { Drawer, TablePagination } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {useEffect, useState} from 'react'
import Inputtt from '../SelectValue/Inputtt';
import Sort from '../../Assets/images/sort.png'
import LandCom from './LandCom';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import House from '../../Assets/images/hh.png';
const drawerWidth = 750;

function LandDrawer({open, handleClose, window}) {
    const [color, setColor] = useState(false)
     const   handleChange = () => setColor(!color)
     const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [page, setPage] = useState(0)
    const [minP, setMinP] = useState(0.0)
    const[maxP, setMaxP]= useState(0.0)
    const[location, setLocation]= useState('')
    const[type, setType] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
      useEffect(() =>{
        setLoading(true)
        getServices.getAllProject(location,maxP,minP,name,type).then(
            
            (response) => {
                setLoading(false)
              if(response.data.status === "2000"){
                setProjects(response.data.data.reverse())
              }else{
               swal(response.data.description)
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                swal('No project found')
            }
          )
    }, [location,maxP,name,minP,type])

  
  const filteredData = projects.filter(item => item.projectType === 'land');
  
  const filteredQuery = filteredData.filter(
    person => {
      return (
        person
        .projectName
        .toLowerCase()
        .includes(value.toLowerCase()) 
      );
    }
  );
   

  

  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 text-[18px] pt-3'>Land banking</p>
            <div className='pt-2'>
            <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/>
            </div>  
        </div>

        <div className=' mt-4 p-2 flex'>
        <div className=' grow'>
        <Inputtt
            placeholder='Filter properties'
            
        onChange={setValue}
           /> 
        </div>
           <div>
            <img src={Sort} alt='Sort'/>
           </div>
        </div>
        
       </div>
       <div className='p-3 grid md:grid-cols-2 gap-3'>
          { filteredQuery.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(project =>{
            return  <div key={project.id}>
                <LandCom
                bool={color}
                id={project.projectId}
                img={project.imageUrl?? House}
                onClick={handleChange}
                link={`productdetails/${project.projectId}`}
                percentage={project.percentSold}
                per={Math.floor(project.percentSold)}
                name={project.projectName}
                state={project.projecLocation ?? 'Lagos'}
                totalAmount={project.projectPriceTotal}
                raisedAmount={project.pricePerUnit * project.unitSold}
            />

                </div> 
             
            })}
            </div>
            {filteredQuery.length === 0 && <div><p className=' text-center mt-9 text-red-700'>No data found </p>
               </div>} 
               <div className=' flex justify-center'>
               <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredQuery.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
               </div>
        </Drawer>
    </div>
  )
}

export default LandDrawer