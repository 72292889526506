import React, { useEffect, useState} from 'react'
import Land from '../../Assets/images/land.png'
import Bank from '../../Assets/images/bankk.png'
import ActiveInvestmentCom from './ActiveInvestmentCom'
import LandActive from './LandActive'
import PropertyActive from './PropertyActive'
import getServices from '../../Services/get-service'
import { toast } from 'react-toastify'
function ActiveInvestment() {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const [open1, setOpen1] = useState(false)
    const handleClose1 = () => setOpen1(false)
    const handleOpen1 = () => setOpen1(true)
    const [activeLand, setActiveLand] = useState(0)
    const [activeProp, setActiveProp] = useState(0)
    useEffect(() => {
      getServices.getWallerBalance().then(
        (response) => {
         
          if(response.data.status === "2000"){
           
            setActiveProp(response.data.data.zouseInvestmentPlanProperty)
            setActiveLand(response.data.data.zouseInvestmentPlanLand)
          }
          else{
            toast('Session Expired', {
              onClose: () => {
                window.location.href = '/'
              },
              autoClose: 5000
            })
          }
          
         
          console.log(response.data.data)
          
        },
        (error) => {
          toast('Session Expired', {
            onClose: () => {
              window.location.href = '/'
            },
            autoClose: 5000
          })
        }
      )}, [])
  return (
    

    <div className=' my-6'>
      <LandActive
        open={open}
        handleClose={handleClose}
      />
      <PropertyActive
         open={open1}
        handleClose={handleClose1}
      />
        <p className=' text-[14px] font-[500] text-[#808285] mb-2'>Active investments</p>
        <div className='  grid md:grid-cols-2 gap-4'>
        
            <ActiveInvestmentCom
                img={Land}
                title='Property banking'
                sub={activeProp}
                onClick={handleOpen1}
            />
             <ActiveInvestmentCom
                img={Bank}
                title='Land Banking'
                sub={activeLand}
                onClick={handleOpen}
            />
        </div>
    </div>
  )
}

export default ActiveInvestment