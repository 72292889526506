import React from 'react'
import Constant from '../../Constant';
function TranCom({img, header, sub, date}) {
  return (
    <div className=' flex my-6 justify-between'>
        <div className=' flex gap-3'>
            <div>
                <img src={img} alt='imgg'/>
            </div>
            <div>
                <p className=' text-[18px] text-back-color2 font-[500]'>₦{Constant.FormatNumber(header)}</p>
                <p className=' text-[14px] text-primary-color6'>{sub}</p>
            </div>
        </div>
        <div>
            <p className=' text-[14px] text-primary-color6'>{date}</p>
        </div>
    </div>
  )
}

export default TranCom