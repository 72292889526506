import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../SelectValue/Loading'
import Camera from '../../Assets/images/camera.png'
function Account() {
  const [loading, setLoading] = useState(false)
  const user = localStorage.getItem('userdetails')
    const userValue =  JSON.parse(user)
  const dropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'Male', value: 'male' },
    { key: 'Female', value: 'female' },
    
  ]
  
    const initialValues = { 
        fName: userValue.firstName,
       lName: userValue.lastName,
       pNum: userValue.phoneNumber,
       dob: '',
       gender: '',

      }
      const validationSchema = Yup.object({
        fName: Yup.string().required('Required'),
        lName: Yup.string().required('Required'),
        pNum: Yup.string().required('Required'),
        dob: Yup.string().required('Required'),
        gender: Yup.string().required('Required'),
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
      
       
       
        // navigate('/dashboard')
      }

  return (
    <div className=''>
    <Loading
      open={loading}
    />
        <div className=' '>
            <div className=' flex gap-5'>
                <div>
                    <img src={Camera} alt='Camera'/>
                </div>
                <div className=' flex flex-col justify-center'>
                <p className=' text-2xl font-[500] text-back-color2'>{userValue.firstName} {userValue.lastName}</p>
                </div>
               
            </div>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize= {true}
                >
        {formik => (
        <Form>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
            <div className=' flex flex-col justify-center'>
            <p className=' text-2xl font-[500] text-back-color2'>Full name</p>
            </div>
               
                <FormikControl
                control='input'
                type='text'
                label='First name'
                name='fName'
              />  
               
               <FormikControl
                control='input'
                type='text'
                label='Last name'
                name='lName'
              /> 
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Phone number</p>
                <div className=' col-span-2'>
                <FormikControl
                control='input'
                type='tel'
                label='Phone number'
                name='pNum'
              />  
                </div>
                
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Date of birth</p>
                <div className=' col-span-2'>
                <FormikControl
                control='date'
                label='Date of birth'
                name='dob'
              />  
                </div>
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Gender</p>
                <div className=' col-span-2'>
                <FormikControl
                control='select'
                label='Select gender'
                 name='gender'
                  options={dropdownOptions}
              />  
                </div>
            </div>
              
              <div className=' md:grid grid-cols-4 mb-5 mt-6'>
              <div>

              </div>
              {/* <div className=' col-span-2'>
              <Button
                name='Save changes'
                bool={true}
              />
              </div> */}
             
              </div>
             
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default Account