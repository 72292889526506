import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Congra from '../../Assets/images/congra.png'
import Button from '../SelectValue/Button';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function SignupModal({open, handleClose}) {
 

  return (
    <div>
     
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' p-6 pt-10'>
            <div className=' md:mt-8'>
                <img src={Congra} alt='Con' className=' mx-auto mb-4'/>
                <p className='text-[16px]  md:text-[32px] text-back-color2 text-center mb-3'>Welcome to zouse</p>
                <p className=' text-center mb-4 md:text-[16px] text-[#44474E]'>You have created your Zouse account, proceed to dashboard to finish setting up your account .</p>

                <div className=' mt-5 mb-7 flex justify-center'>
                <Link to='/'>
                <Button
                        name='Go to Login'
                       bool={true}
                    />
                </Link>
                   
                </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}