import React from 'react'
import Logo from '../Assets/images/Zlogo.png'
import Cancel from '../Assets/images/canc.svg'
import Apple from '../Assets/images/app.svg'
import Google from '../Assets/images/goo.svg'
function Mobile() {
  return (
    <div>
        <div className=' bg-white '>
        <div>
        <div className='flex justify-between mt-3 p-4'>
        <img src={Logo} alt='Logo' />
       <a href='https://zouse.co/'><img src={Cancel} alt='Cancel'/></a>  
        </div>
        <div className=' h-[90vh] p-4 bg-[#002615]'>
            <h1 className=' text-[24px] font-[800] text-white mb-4'>We are currently not optimised  for smaller screens </h1>
            <p className='text-[14px] font-[500] text-white mb-2'>Visit the link on a desktop view</p>
            <p className='text-[14px] font-[500] text-white mb-2'>Or get it on</p>
            <div className=' mt-3 flex gap-4 justify-between'>
            <img src={Apple} alt='Apple' />
        <img src={Google} alt='Google'/>
            </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Mobile