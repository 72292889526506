import React,{useState, useEffect} from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import getServices from '../../Services/get-service';
import SelectBank from '../BankCard/SelectBank';
import TextBox from '../SelectValue/TextBox';
import User from '../../Assets/images/userr.png'
import Button from '../SelectValue/Button';
import Loading from '../SelectValue/Loading';
import postService from '../../Services/post-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
 
};

export default function BVN({open, handleClose}) {
   
    const [bankCode, setBankCode] = useState('')
    const [acc, setAcc] = useState('')
    const [accName, setAccName] = useState('')
    const [bvn, setBvn]= useState('')                                                                                                                                                                                                                                                     
    const [bName, setBName]= useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() =>{
        if(acc.length === 10 && bName.length !== 0){
          setLoading(true)
          getServices.verifyBankAccount(acc,bName).then(
            (response) => {
              setLoading(false)
              if(response.data.status === "2000"){
                setAccName(response.data.data.accountName);
              }else{
                setAccName('')
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
              setLoading(false)
                
            }
          )
        }
    }, [acc, bName, accName])

    const handleSubmit = (e) =>{
            e.preventDefault()
            setLoading(true)
                if(accName.length !== 0 && bvn.length === 11){
                    postService.verifyBvn(acc,bName,bvn).then(
                        (response) => {
                            console.log(response.data)
                            setLoading(false)
                           if(response.data.status === '2000'){
                            toast.success('BVN verified successfully')
                            setTimeout(() => {
                              localStorage.setItem('accountStatuss',"true" )
                              navigate('/')
                            }, 4000);
                           
                           }else{
                            toast.error(response.data.description)
                            setTimeout(() => {
                              window.location.reload()
                            }, 4000);
                            
                           
                           }
                           
                          },
                          (error) => {
                            setLoading(false)
                           return  toast.error('BVN verification failed')
                           
                              // return  swal('BVN verification failed')
                              // .then((value) => {
                              //   window.location.reload()
                              // });
                            }
                      )
                }else{
                    setLoading(false)
                    toast.error('Invalid Account details or BVN')
                    // swal('Invalid Account details or BVN')
                }
            
    }

  return (
    <div>
     <ToastContainer/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
       
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
        open={loading}
        />
            <div className=' h-[80vh] md:mt-5'>
                <div className=' mt-6 flex justify-between mb-3'>
                    <p>Bank verification number(BVN)</p>
                    <div>
                    <CancelIcon onClick={handleClose}  className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div >
                <div className=''>
                  <p className=' text-[#808285] font-[400] text-[16px]'>Please enter your BVN. We use it to confirm your
                    identity and protect your account.</p>
               </div>
                    <div>
                    <form onSubmit={handleSubmit}>
                <div className=' my-3'>
                  <TextBox
                    label='Account number'
                    type='text'
                      value={acc}
                      onChange={setAcc}
                  />
                </div>
               
               <div className=' my-3'>
               <SelectBank
                bool={true}
                value={bankCode}
                onChange={setBankCode}
                bName={setBName}
              />
               </div>
               <div className=' my-3'>
                  <TextBox
                    label='Bank verification number(BVN)'
                    type='text'
                      value={bvn}
                      onChange={setBvn}
                  />
                </div>
               <div className='my-5 flex gap-4'>
                <img src={User} alt='user' />
                <p className=' text-[18px] pt-2 text-back-color2 font-[500]'>{accName}</p>
               </div>
               <p className=' my-5 text-center font-[500] text-[#44474E] text-[16px]'>Don’t know your BVN? <span className=' text-primary-color'>Dial *565*0#</span> </p>
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Add BVN'
              />
              </div>
              </form>
                    </div>
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}