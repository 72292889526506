import  React, {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import PinInput from 'react-pin-input';
import {  Outlet, useNavigate } from 'react-router-dom';
import Back from '../Back';
import postService from '../../Services/post-service';
import Loading from '../SelectValue/Loading';
import getServices from '../../Services/get-service';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePaystackPayment } from 'react-paystack';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function Pin({open, handleClose, id, unit, method, amount}) {
    const [pin, setPin] =useState('')
    const [loading, setLoading] = useState(false)
    
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const email = user.emailAddress;

    const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
    const [configP, setConfig] = useState(initialConfig);
    let initializePayment = usePaystackPayment(configP);
    const navigate = useNavigate()
    const handleSubmit = (e) =>{
      e.preventDefault()
      
    
        setLoading(true)
        postService.buyProject(id,unit, pin, method).then(
          (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            if(response.data.data === null){
              toast.success("Investment made succesfully");
              setTimeout(() => {
                navigate("/dashboard")
              }, 5000);
              // swal(response.data.description).then((value) => {
              //   navigate('/buy')
              // });
             console.log(response.data.description)
            }else{
              const reference =  response.data.data.reference
              
              console.log(reference)
              setConfig({
                ...configP, channel: [
                  'card',
                ], email: email, reference: reference, amount: parseInt(amount) * 100,
              });
            }
            
            }else{
              toast.error(response.data.description);
            setTimeout(() => {
              window.location.reload()
            }, 5000);
          //  swal(response.data.description).then((value) => {
          //   window.location.reload()
          // });
           
          }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
              });
            }
        )
      
      // else{
      //   setLoading(true)
      //   postService.buyProjec(id,unit, pin, method).then(
      //     (response) => {
      //       console.log(response.data)
      //       setLoading(false)
      //      if(response.data.status === '2000'){
           
      //       const reference =  response.data.data.reference
              
      //       console.log(reference)
      //       setConfig({
      //         ...configP, channel: [
      //           'card',
      //         ], email: email, reference: reference, amount: parseInt(amount) * 100,
      //       });
           
      //     }
           
      //     },
      //     (error) => {
      //       setLoading(false)
      //         return  swal(error.message)
      //         .then((value) => {
      //           window.location.reload()
      //         });
      //       }
      //   )
      // }
      
      
    }
    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      console.log(reference);
       verifyPayment(reference.reference)
    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }
    const verifyPayment = (ref) =>{
      setLoading(true)
      getServices.getVerifySavingPayment(ref).then(
        (response) => {
         setLoading(false)
          console.log(response.data)
          navigate('/buy')
        
        },
        (error) => {
          setLoading(false)
        
          navigate('/buy')
        
        }
      )
    }
    

    useEffect(() => {
      console.log(configP)
      if (configP.reference === null || configP.email === undefined) return;
      initializePayment(onSuccess, onClose)
    }, [configP])
  return (
    <div>
  
     <Outlet/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
            open={loading}
          />
          {/* <ToastContainer/> */}
            <div className=' md:mt-8'>
           
                <div className=' flex justify-end mb-3'>
                   
                    <div>
                    {/* <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/> */}
                    <Back/>
                    </div>
                </div>
                <div className='mt-10'>
                  <p>{method}</p>
                        <p className=' text-[18px] font-[500] text-back-color2 mb-4 '>Enter your Zouse PIN</p>
                        <p className=' text-[16px] text-[#808285] mb-5 text-center' >Enter your 4-Digit PIN to confirm transaction?</p>
                </div>
                <form onSubmit={handleSubmit}>
              <div>
              <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    handleSubmit()
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              
                <div className=' mt-16 mb-8 grid grid-cols-1'>
                   <Button
                        name='Confirm'
                        
                    />
                  
                </div>
                </form>
                
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}