
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import getServices from '../../Services/get-service'
import InvestCom from '../Dashboard/InvestCom'
import House from '../../Assets/images/house.png';
import {  ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function TopInvestment() {
  const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)

    // const [minP, setMinP] = useState(0.0)
    // const[maxP, setMaxP]= useState(0.0)
    // const[location, setLocation]= useState('')
    // const[type, setType] = useState('')
    // const [name, setName] = useState('')
    // const [id, setId] = useState()
   
      useEffect(() =>{
        setLoading(true)
        getServices.getAllProject('',0.0,0.0,'','').then(
            
            (response) => {
                setLoading(false)
              if(response.data.status === "2000"){
                setProjects(response.data.data.reverse())
              }else{
               toast.error(response.data.description)
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                swal('No project found')
            }
          )
    }, [])
  return (
    <div>
    <ToastContainer/>
        <div className=' flex justify-between'>
            <p className=' text-[#808285] text-[16px]'>Top investments</p>
            <p className=' text-primary-color text-[14px] border-b border-b-primary-color'>Go to investments</p>
        </div>
        <div className=' mt-4 grid sm:grid-cols-2 lg:grid-cols-3 gap-3'>
           
            {projects && projects
            .map((project, index) =>{
              if(index <3){
                return  <div key={project.id}>
                <InvestCom
                id={project.propertyId}
               link={`productdetails/${project.projectId}`}
               img={project.imageUrl ?? House} 
                percentage={project.percentSold}
                per={Math.floor(project.percentSold)}
                name={project.projectName}
                state={project.projecLocation ?? 'Lagos'}
                target={project.projectPriceTotal}
               raised={project.pricePerUnit * project.unitSold}
            />
              
                </div>
              }
              return null
             
            }).filter(Boolean)}
            </div>
            {projects.length === 0 && <div><p className=' text-center mt-9 text-red-700'>No data found </p>
               </div>} 
              
    </div>
  )
}

export default TopInvestment