import React, { useEffect, useState } from 'react'
import Card from '../../Assets/images/carddb.png'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddBank from './AddBank';
import DeleteBank from './DeleteBank';
import getServices from '../../Services/get-service';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loading';
function Bank() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const [loading, setLoading] = useState(false)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleOpen1 = () => setOpen1(true)
    const handleClose1 = () => setOpen1(false)
    const [userBank, setUserBank] = useState([])
    useEffect(() =>{
        setLoading(true)
        getServices.getUserBanks().then(
            (response) => {
               
                if(response.data.status === "2000"){
                    setUserBank(response.data.data);
                    console.log(response.data.data)
                }else{
                    toast('Session Expired', {
                        onClose: () => {
                            window.location.href = '/'
                        },
                        autoClose: 5000
                      })
                }
                setLoading(false)
              
            },
            (error) => {
                setLoading(false)
                toast('Session Expired', {
                    onClose: () => {
                      window.location.href = '/'
                    },
                    autoClose: 5000
                  })
            }
          )
    }, [])
  return (
    <div className='   mt-5'>
    <AddBank
        open={open}
        handleClose={handleClose}
    />
    <DeleteBank
        open={open1}
        handleClose={handleClose1}
    />
     <ToastContainer/>
     <Loading
        open={loading}
     />
    <div className=' flex'>
    {userBank.map((bank, index) =>{
        return  <div  className=' bank-bg p-2  mr-6 relative'>
            <div className=' flex justify-end pt-5 px-3'>
                <DeleteOutlinedIcon onClick={handleOpen1} className=' text-white'/>
            </div>
            <div className=''>
                <p className=' text-start px-3 pt-6 text-white font-[500] text-[16px]'>{bank.accountName}</p>
            </div>
            <div className=' flex pt-10 py-3 gap-5 sm:gap-10 px-3 justify-between'>
            <div className=' '>
                <p className=' text-white font-[500] text-[16px]'>{bank.accountNumber}</p>
            </div>
            <div className=''>
                <p className=' text-white font-[500] text-[16px]'>{bank.bankName}</p>
            </div>
            </div>
            
        </div>
    })}
       
   
     </div>
       
        {/* <div className=' border border-primary-color5 rounded-2xl flex flex-col justify-center h-[148px] w-[335px]'>
            <div className=' flex justify-center'>
            <div className=' mt-2 md:mt-0 cursor-pointer' onClick={handleOpen}>
            <div className=' flex justify-center '>
               <p className=' text-center'><AddOutlinedIcon className=' mx-auto text-primary-color'/></p> 
                </div>
                <div>
                <p className=' text-[16px] font-[700] mt-4 text-primary-color'>ADD BANK ACCOUNT</p>
                </div>
            </div>
                
               
            </div>
        </div> */}
        
    </div>
  )
}

export default Bank