import  React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, Outlet } from 'react-router-dom';

import Tag from '../../Assets/images/rental1.png'
import Star from '../../Assets/images/Star0.png'
import Button from '../SelectValue/Button';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function RentSavingPlan({open ,handleClose}) {
     
   
  return (
    <div>
   
   
     <Outlet/>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-2xl  '> 
            <div className=''>
            <div className=' bg-[#FDEAD2] rounded-t-2xl p-4 pb-10 pt-10'>
                <div className=' flex justify-between mb-10'>
                    <p className=' text-back-color2 text-[18px] font-[500]'>Create a rent savings plan</p>
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <img src={Tag} className=' mx-auto' alt='tag'/>
             </div> 
              <div className=' bg-white rounded-b-2xl mb-10 p-4 pt-10'>
                <p className=' text-back-color2 text-[18px] font-[500] mb-4' >Rent savings</p>
                <p className=' text-primary-color6 text-[14px] mb-4 font-[400]'>Achieve those aspirations with our goal based
                savings plan.  With zouse, no goal is out of reach, all you have to do is start.</p>
                <div className=' flex gap-2 mb-3'>
                    <div className=' pt-2'>
                        <img src={Star} alt='Star'/>
                    </div>
                    <div>
                        <p className=' text-primary-color6'>Reach your personal goals with our target savings plan</p>
                    </div>
                </div>
                <div className=' flex gap-2 mb-3'>
                    <div className=' pt-1'>
                        <img src={Star} alt='Star'/>
                    </div>
                    <div>
                        <p className=' text-primary-color6'>Easy entry, disciplined exits.</p>
                    </div>
                </div>
                <div className=' flex gap-2 mb-3'>
                    <div className=' pt-1'>
                        <img src={Star} alt='Star'/>
                    </div>
                    <div>
                        <p className=' text-primary-color6'>Suitable for investors with a low risk appetite</p>
                    </div>
                </div>
              </div> 
              
              <Link to='/save/plan/nameplan/rent'>
              <div className=' grid grid-cols-1 p-4 mb-3'>
              <Button
                name='Get started'
                onClick={handleClose}
              /> 
              </div>
              </Link>
              
              
             
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}