import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import {  useNavigate } from 'react-router-dom'
import HourRadio from './HourRadio'
import { addMonths } from 'date-fns'
// import Constant from '../../Constant'
function HowLong() {

  const [dura, setDuration] = useState('')
    const navigate = useNavigate()
    const initialValues = { 
        date: '',

      }
      const validationSchema = Yup.object({
        // date:Yup
        // .string()
        // .required('Select a date'),
      
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        sessionStorage.setItem('savingDuration', dura)
        const {date} = values
        sessionStorage.setItem('savingEndDate', date)

        navigate('/save/plan/often')
      }

  return (
    <div className=''>
        <div className=' h-[70vh] '>
            <p className=' text-[18px] text-back-color2 mb-5 font-[500]'>For how long  would you like to save?</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            <HourRadio
              value={dura}
              handleChange={setDuration}
            />
               {/* <FormikControl
                control='date'
                label='Set preferred date'
                name='date'
                minDate={new Date()}
                maxDate={addMonths(new Date(), 12)}
                showIcon
                showDisabledMonthNavigation
              />   */}
               
              
              <div className=' grid grid-cols-1 mb-5 mt-16'>
              <Button
                name='Next'
              />
              </div>
            
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default HowLong