import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import {  useNavigate, useParams } from 'react-router-dom'
// import Constant from '../../Constant'
function NameePlan() {

  const {type} = useParams()
    const navigate = useNavigate()
    const initialValues = { 
        name: '',

      }
      const validationSchema = Yup.object({
       name:Yup.string().required('Required'),
      
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        const {name} = values
        sessionStorage.setItem('savingsName', name)
        sessionStorage.setItem('savingsType', type)
        navigate('/save/plan/start')
      }

  return (
    <div className=''>
        <div className=' h-[60vh] '>
            <p className=' text-[18px] text-back-color2 mb-5 font-[500]'>Name your plan</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                type='text'
                label='Plan name'
                name='name'
              />  
               
              
              <div className=' grid grid-cols-1 mb-5 mt-16'>
              <Button
                name='Next'
              />
              </div>
            
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default NameePlan