import React from 'react';
import ReactPlayer from 'react-player';

const YouTubeVideoComponent = ({ videoId }) => {
//     const url = new URL(videoIdd);
// const searchParams = new URLSearchParams(url.search);
// const videoId = searchParams.get('v');
  // const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className=' w-full h-full'>
        <ReactPlayer url={videoId} controls={true} width="100%" height="100%" />
    </div>
  );
};

export default YouTubeVideoComponent;
