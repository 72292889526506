import React from 'react'
import Progress from '../Home/Progress'
import Verify from '../../Assets/images/verii.png'
import Identify from '../../Assets/images/veri.png'
import Wallet from '../../Assets/images/wa.png'
import Line from '../../Assets/images/Line.png'
import StartComp from './StartComp'
function Start() {
    const users = localStorage.getItem('userdetails')
    const user =  JSON.parse(users)
  return (
    <div className=' p-4 my-4 rounded-lg border border-[#E6E6E7]'>
    <div className=' mb-5 grid grid-cols-7 gap-2'>
    <div className=' col-span-2'>
    <p className=' text-[16px] font-[500] inline-block text-back-color2'>Start with the basics - </p>
        <p className=' text-[#808285] pl-1 inline-block text-[14px] font-[400]'> Complete your KYC</p>
    </div>
       <div className=' col-span-2 mt-3' >
       {user.accountStatus  ? <Progress value={100}/> : <Progress value={1}/>}
       
       </div>
       
        <p className=' text-back-color2 text-[14px]'>{user.accountStatus ? '100% complete': '1% complete'}  </p>
    </div>
    <div className='mt-6 flex  gap-10'>
        <StartComp
            img={Verify}
            title='Verify BVN'
            sub='Verify your BVN'
        />
        <div className=' flex flex-col justify-center'>
        <img src={Line} alt='lines'/>
        </div>
       
        <StartComp
            img={Identify}
            title='Identity verification'
            sub='Complete identification  process'
        />
        <div className=' flex flex-col justify-center'>
        <img src={Line} alt='lines'/>
        </div>
       
        <StartComp
            img={Wallet}
            title='Link your bank'
            sub='Link your bank to your zouse account'
        />
    </div>
    </div>
  )
}

export default Start