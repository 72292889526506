import React from 'react'
import Content from '../../Components/Rentals/Content'


function Rental() {
  return (
    <div>
       <Content/>
    </div>
  )
}

export default Rental