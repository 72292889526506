import React, { useState } from 'react'
import Cardd from '../../Assets/images/cardd.png'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddBank from './AddBank';
import DeleteBank from './DeleteBank';
function Card() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleOpen1 = () => setOpen1(true)
    const handleClose1 = () => setOpen1(false)
  return (
    <div className=' md:flex  mt-5'>
    <AddBank
        open={open}
        handleClose={handleClose}
    />
    <DeleteBank
        open={open1}
        handleClose={handleClose1}
    />
        <div className='mr-6 relative'>
            <img src={Cardd} alt='card' />
            <div className=' 6 absolute top-2 right-6'>
                <DeleteOutlinedIcon onClick={handleOpen1} className=' text-white'/>
            </div>
            <div className=' absolute top-12 left-7'>
                <p className=' text-white font-[500] text-[16px]'> **** 2974</p>
            </div>
            <div className=' absolute bottom-6 left-7'>
                <p className=' text-white font-[500] text-[12px]'>Expires 09/2024</p>
            </div>
           
        </div>
        <div className='  border border-primary-color5 rounded-2xl flex flex-col justify-center h-[148px] w-[335px]'>
            <div className=' flex justify-center'>
            <div className=' mt-2 md:mt-0 cursor-pointer' onClick={handleOpen}>
            <div className=' flex justify-center '>
               <p className=' text-center'><AddOutlinedIcon className=' mx-auto text-primary-color'/></p> 
                </div>
                <div>
                <p className=' text-[16px] font-[700] mt-4 text-primary-color'>ADD Card</p>
                </div>
            </div>
                
               
            </div>
        </div>
    </div>
  )
}

export default Card