import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import Constant from '../../Constant';
function ActiveInvestmentCom({img, title, sub, onClick, link}) {
  return (
    <div className=' my-4 rounded-lg border border-[#D2D3D5] p-4'>
               <Link to={link}>
                <div className=' flex gap-3 cursor-pointer justify-between' onClick={onClick}>
                <div className=' flex gap-3'>
                <img src={img} alt='Target'/>
                <div>
                    <p className=' text-[14px] capitalize text-[#808285] mb-3'>{title} </p>
                    <p className=' text-[16px] font-[500] text-[#002615] mb-3'>₦{Constant.FormatNumber(sub) }</p>
                </div>
                </div>
                <div className=' pt-5'>
                  <KeyboardArrowRightIcon  className='  text-back-color2 cursor-pointer'/>
                  </div>
                </div>
                </Link>
                
            </div>
  )
}

export default ActiveInvestmentCom