import React, { useEffect, useState } from 'react'
import LandCom from '../Buy/LandCom'
import House from '../../Assets/images/hh.png';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import { TablePagination } from '@mui/material';
import Loading from '../SelectValue/Loading';
function Investment() {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = useState(false)
  const handleClose1 = () => setOpen1(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
      useEffect(() =>{
        setLoading(true)
        getServices.getSavedProduct().then(
            
            (response) => {
                setLoading(false)
              if(response.data.status === "2000"){
                setProjects(response.data.investmentPlans.reverse())
              }else{
               swal(response.data.description)
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                swal('No project found')
            }
          )
    }, [])
  return (
    <div className=' mt-4'>
    <Loading
      open={loading}
    />
    <div className=' grid sm:grid-cols-2 lg:grid-cols-3 gap-4'>
    { projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(project =>{
            return  <div key={project.id}>
                <LandCom
                bool={true}
                saveStatus={true}
                id={project.propertyId}
                img={project.imageUrl?? House}
                //onClick={handleChange}
                link={`/buy/productdetails/${project.projectId}`}
                percentage={project.percentSold}
                per={Math.floor(project.percentSold)}
                name={project.projectName}
                state={project.projecLocation?? 'Lagos'}
                totalAmount={project.projectPriceTotal}
                raisedAmount={project.pricePerPlot * project.unitSold}
            />

                </div> 
             
            })}
            
    </div>
    {projects.length === 0 && <div><p className=' text-center mt-9 text-red-700'>No data found </p>
               </div>} 
               <div className=' flex justify-center'>
               <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={projects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
    </div>
  )
}

export default Investment