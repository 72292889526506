import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import { Link, useNavigate } from 'react-router-dom'
import Constant from '../../Constant'
import postService from '../../Services/post-service'
import Loading from '../SelectValue/Loading'
import Logo from '../../Assets/images/Zlogo.png'
import Noticee from '../../Assets/images/notice.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function SignupForm() {
   
    const[open, setOpen] = useState(false)
    const navigate = useNavigate()
    const [datee, setDatee] = useState('')
    const initialValues = { 
        fname: '',
        lname: '',
        mname: '',
        pnumber: '',
        email: '',
        password: '',
        sex: '',
        dob: '',
        cpassword: ''
      }
      const validationSchema = Yup.object().shape({
        fname: Yup.string().required('Required'),
        lname: Yup.string().required('Required'),
        mname:Yup.string().required('Required'),
        pnumber: Yup.string().matches(Constant.phoneRegExp, 'Phone number is not valid'),
        email: Yup.string().email('Required'),
        password: Yup.string().required('Required'),
        sex: Yup.string().required('Required'),
        dob: Yup.string().required('Required'),
        cpassword: Yup.string().when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          )
        }),
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setOpen(true)
        const {fname, lname,mname, pnumber, email, password, sex, dob} = values
        setDatee(dob.toISOString())
        postService.signupUser(datee,email,mname,fname,sex,lname,pnumber,password).then(
          (response) => {
              console.log(response.data)
              setOpen(false)
             if(response.data.status === '2000'){
              localStorage.setItem('user', JSON.stringify(response.data.data))
              sessionStorage.setItem('sEmail', email)
              return toast.success('Account created successfully', {
                             
                onClose: () => {
                  navigate('/verifyaccount')
                }
              })
              
             }else{
              toast.error(response.data.description)
             
             }
             
            },
            (error) => {
              setOpen(false)
                return   toast.error('Signup failed!!')
              }
        )
    
        // navigate('/verifyaccount')
      }
      const dropdownOptions = [
        { key: 'Select an option', value: '' },
        { key: 'Male', value: 'male' },
        { key: 'Female', value: 'female' },
        
      ]
      const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 16);
  return (
    <div className=' bg-signin-bg '>
     <ToastContainer/>
    <div className='grid h-screen md:h-[90vh] py-7 px-4 place-items-center'>
    <Loading
      open={open}
    />
        <div className=' md:w-[70%] h-[80vh] overflow-y-auto bg-white shadow-md rounded-[16px] p-5 md:p-10'>
        <img src={Logo} width='152' height='40' className=" mx-auto"  alt=" logo" /> 
            <h1 className=' text-[18px] mt-6 text-back-color2 mb-5 md:text-[40px] font-[500]'>Welcome to Zouse</h1>
            <p className=' mb-3 text-[#44474E]'>Let’s get started with some basic information about you.</p>
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
        <p className=' flex mb-3'><img src={Noticee} className=' text-black inline-block' alt='notice'/> Note: Your names should be similar with your BVN for KYC verification</p>
            <div className=' grid mb-3 md:grid-cols-3 gap-5'>
            <FormikControl
                control='input'
                type='text'
                label='First name'
                name='fname'
              />   
              <FormikControl
                control='input'
                type='text'
                label='Middle name'
                name='mname'
              />  
              <FormikControl
                control='input'
                type='text'
                label='Surname'
                name='lname'
              /> 
            </div>
            <div className=' grid mb-3 md:grid-cols-2 gap-5'>
            <FormikControl
                control='input'
                type='tell'
                label='Phone number'
                name='pnumber'
              />  
               <FormikControl
                control='input'
                type='email'
                label='Email address'
                name='email'
              />  
            </div>
            <div className=' grid mb-3 md:grid-cols-2 gap-5'>
            <FormikControl
                control='select'
                label='Select gender'
                 name='sex'
                  options={dropdownOptions}
              />  
               <FormikControl
                control='date'
                maxDate={minDate}
                label='Select date of birth'
                name='dob'
              />  
            </div>
            <div className=' grid mb-3 md:grid-cols-2 gap-5'>
            <FormikControl
                control='password'
                label='Create password'
                name='password'
              /> 
                <FormikControl
                control='password'
                label='Repeat password'
                name='cpassword'
              /> 
            </div>
              
              <div className=' grid grid-cols-1 mb-5 mt-10'>
              <Button
                name='Continue'
              />
              </div>
             <div>
             <p className=' text-center mt-6'>Have an account? <span className=' text-primary-color'><Link to='/'>Login</Link></span></p>
             </div>
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
    </div>
  )
}

export default SignupForm