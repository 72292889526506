import React, { useState } from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Divider } from '@mui/material';
import Constant from '../../Constant';
import Calender from '../../Assets/images/calendar.svg'
import Save from '../../Assets/images/saa.svg'
import { toast } from 'react-toastify';
import postService from '../../Services/post-service';
import Loading from '../SelectValue/Loading';
function ListingCom({onClick, name,header, location, dis, img, date, amount, area, id, saveStatus}) {
        const [loading, setLoading] = useState(false)
    const reduceStringTo50Characters = (inputString) => {
        if (inputString.length <= 50) {
          return inputString;
        } else {
          return inputString.substring(0, 50) + '...';
        }
      };
      const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
          postService.saveProduct(id,header).then(
            (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
                toast.success("Listing Saved");
                // setTimeout(() => {
                //   window.location.reload()
                // }, 5000);
            
              }else{
                toast.error(response.data.description,
                  {
                      position: toast.POSITION.TOP_LEFT
                    }
                  );
             // setTimeout(() => {
               // window.location.reload()
               //}, 5000);
            
             
            }
             
            },
            (error) => {
              setLoading(false)
                 toast.error(error.message, {
                  position: toast.POSITION.TOP_LEFT
                });
                setTimeout(() => {
               window.location.reload()
               }, 5000);
              }
          )
        
        
      }
  return (
    <div  className=' my-4'>
    <Loading
        open={loading}
    />
    <div onClick={onClick} className=' border-t border-x mt-6 border-x-primary-color5 border-t-primary-color5 rounded-t-lg p-4'>
        <p className=' mb-3 text-back-color2 capitalize'>{name} </p>
        <div className=' grid grid-cols-5 gap-6'>
            <div className='col-span-2 relative'>
                <img src={img}  alt='List'/>
                <div className=' bg-white p-1 absolute top-2 left-2 rounded'>
                    {/* <p className=' text-xs'> <PhotoCameraOutlinedIcon/> 10</p> */}
                </div>
            </div>
            <div className=' col-span-3'>
                <p className=' text-primary-color text-[14px] mb-2 font-[500] capitalize'> {header} Listing</p>
                <p className=' font-[500] text-back-color2 mb-2 text-[14px] capitalize'><PlaceOutlinedIcon className=' text-back-color2'/>{location}</p>
                <p className=' text-[14px] text-[400] text-[#808285] capitalize'>{reduceStringTo50Characters(dis)}
                <span onClick={onClick} className=' cursor-pointer text-primary-color'> See more details</span>
                </p>
                <p className=' pb-2  text-xs mt-1 capitalize'> <img src={Calender} className=' inline-block' alt='Calender'/> <span className=' pt-2'> Added on {date}</span></p>
                <Divider/>
                <p className='pt-3 text-[12px] font-[400] text-[#808285]'> <span className=' text-primary-color  text-[14px] font-[500]'>NGN {Constant.FormatNumber(amount) }</span> {header === 'land'? 'per plot' :''} </p>
                
            </div>

        </div>
    </div>
    <div className=' p-3 border border-primary-color5 bg-[#F8FAFC] rounded-b-lg'>
        <div className=' flex justify-between'>
        <div>
        <p className=' text-xs pt-2 text-back-color2'>{area} {header === 'land'? 'total area' :''} </p>
        </div>
           {!saveStatus &&
            <div onClick={handleSubmit} className=' cursor-pointer'>
                <p className=' text-back-color2 text-[14px] font-[600] '><img src={Save} className=' inline-block' alt='save'/> <span className=' pt-3'>Save</span></p>
            </div>
           }
            
        </div>
    </div>
    </div>
  )
}

export default ListingCom