import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {useEffect, useState} from 'react'
import Land from '../../Assets/images/hs.png'
import ActiveInvestmentCom from '../Buy/ActiveInvestmentCom';
import getServices from '../../Services/get-service';
import Constant from '../../Constant';
const drawerWidth = 650;

function TargetRentDrawer({open, handleClose, window}) {
  const [savings, setSavings] = useState([])
  useEffect(() =>{
    getServices.savings().then(
      (response) => {
       if(response.data.status === '2000'){
         setSavings(response.data.data);
         console.log(response.data.data)
       }else if (response.data.status === '4000') {
         window.location.href = '/';
       }
        
      },
      (error) => {
        
      }
    )
  }, [])
  const filteredData = savings.filter(item => item.savingStatus === 'active' &&  item.savingsType === 'rent');
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
        <div className=' p-4 border-b border-b-[#f0f0f0] flex justify-between'>
            <p className=' text-back-color2 text-[18px] pt-3'>Rent savings plans</p>
            <div className='pt-2'>
            <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/>
            </div>  
        </div>
        
       </div>
          <div className='p-3 grid md:grid-cols-1 gap-3'>
          {filteredData.map(e =>{
        return <div key={e.id}>
        <ActiveInvestmentCom
                 img={Land}
                 title={e.planName}
                 sub={e.amount}
                 onClick={handleClose}
                 link={`plan/${e.planName}/details/${e.planId}/${Constant.FormatNumber(e.cashout)}/${e.planName}/${e.endDate}/${e.startDate}/${Math.ceil(e.percentAchieve)}/${Math.ceil(e.targeted)}/${e.savingStatus}/${e.amount}`}
            />
                </div>
              })}
            
              {filteredData.length === 0 && <p className=' text-[18px] text-center font-bold text-red-700'> No active rent savings plan</p>}
           
            
          </div>

        </Drawer>
    </div>
  )
}

export default TargetRentDrawer