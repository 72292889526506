import React from 'react'
import NavbarBack from '../../Components/NavbarBack'
import VerifyAccountForm from '../../Components/Signup/VerifyAccountForm'

function VerifyAccount() {
  return (
    <div>
      <NavbarBack/>
      <VerifyAccountForm/>
    </div>
  )
}

export default VerifyAccount