import React, {useState} from 'react'
import Target from '../../Assets/images/tar1.png'
import House from '../../Assets/images/hs.png'
import SavePlanCom from './SavePlanCom'

import TargetSavingPlan from './TargetSavingPlan'
import RentSavingPlan from './RentSavingsPlan'
function SavingPlans() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  return (
    <div className=' my-8' id='plan'>
      <TargetSavingPlan
          open={open1}
          handleClose={handleClose1}
        />
        <RentSavingPlan
          open={open}
          handleClose={handleClose}
        />

        <p className=' text-primary-color6 text-[14px] mb-4'>Create a new savings Plan</p>
        <div className=' grid md:grid-cols-3 gap-5' >
            <SavePlanCom
                color=' bg-[#FDEAD2] border-[#FCDBB4]'
                img={House}
                title='Rent Savings'
                sub='Save towards your rent with the Rent savings plan'
                onClick={handleOpen}
            />
             <SavePlanCom
                color=' bg-[#CCF2E1] border-[#AAE9CC]'
                img={Target}
                title='Target savings'
                sub='Save towards an investment with the target savings plan'
                onClick={handleOpen1}
            />
        </div>
        <div className=' mb-8' id='plans'>

        </div>
    </div>
  )
}

export default SavingPlans