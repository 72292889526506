import  React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../Assets/images/Zlogo.png'
import { Link, NavLink, useLocation} from 'react-router-dom';
import Dashboardimg from '../../Assets/images/Category.svg'
import Buys from '../../Assets/images/Activity.png'
import Saves from '../../Assets/images/Graph.png'
import Listings from '../../Assets/images/b2.png'
import Wallets from '../../Assets/images/Wallet.png'
import Bank from '../../Assets/images/card.png'
import Noti2 from '../../Assets/images/noti6.svg'
import Note from '../../Assets/images/note.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import getServices from '../../Services/get-service';
import cookie from 'react-cookies';
import Routess from './Routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Save from '../../Assets/images/saa.svg'
import Notification from '../../Components/Dashboard/Notification';


const drawerWidth = 240;




function Dashboard(props) {
    const { window } = props;
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const location = useLocation();
    const [click, setClick] = useState('')
    const hadleOpem = () => setClick(true)
    const handleCloss = () => setClick(false)
    console.log(location)
    const [open, setOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [noti, setNotification] = useState(1)
  useEffect(() =>{
     
      getServices.notification().then(
          (response) => {
            setNotification(response.data.data.length);
            console.log(response.data.data)
          },
          (error) => {
            
          }
        )
  }, [])
  
  useEffect(() =>{
    let interval= "";
    const check = ()=>{
      interval = setInterval(e=>{
      const token = cookie.load("token");
  
      if (!token) {
        clearInterval(interval);
        window.location.href = '/';
        this.props.navigate(0);
        return;
      }
    },10000);
    }
      check();
      getServices.refreshToken().then(
        (response) => {
            console.log(response.data)
           
           if(response.data.status === '2000'){
            cookie.save("token", response.data.data.Token, {
              path: "/",
              expires: new Date(Date.now() + 60 * 10 * 1000),
            });
            

           }else{
            
           
           }
           
          },
          (error) => {
            
            }
      )
      
  }, [])


  useEffect(() =>{
    console.log("heeloo")
  getServices.refreshToken().then(
    (response) => {
        console.log(response.data)
       
       if(response.data.status === '2000'){
        cookie.save("token", response.data.data.Token, {
          path: "/",
          expires: new Date(Date.now() + 60 * 10 * 1000),
        });
        

       }else{
        toast('Session Expired', {
          onClose: () => {
            window.location.href = '/'
          },
          autoClose: 5000
        })
       
       }
       
      },
      (error) => {
        toast('Session Expired', {
          onClose: () => {
            window.location.href = '/'
          },
          autoClose: 5000
        })
        }
  )
      })

 

  const [sidebar, setSidebar] = useState([
    {
        name: 'Dashboard',
        icon: (
            <img src={Dashboardimg} className=' inline-block' alt='Dashboard'/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Investment',
        icon: (
          <img src={Buys} className=' inline-block'  alt='buy'/>
        ),
        link: '/buy',
        id: 'buy',
    },
   
    {
        name: 'Save',
        icon: (
          <img src={Saves} className=' inline-block' alt='Save'/>
        ),
        link: '/save',
        id: 'save',
    },
   
    {
        name: 'Land listing',
        icon: (
          <img src={Listings} className=' inline-block' alt='Listings'/>
        ),
        link: '/landlisting',
        id: 'listings',
    },
    {
      name: 'Property listing',
      icon: (
        <img src={Listings} className=' inline-block' alt='Listings'/>
      ),
      link: '/propertylisting',
      id: 'plistings',
    },
    // {
    //   name: 'Rentals',
    //   icon: (
    //     <img src={Note} className=' inline-block' alt='note'/>
    //   ),
    //   link: '/rental/active',
    //   id: 'rental',
    // },
    {
      name: 'Wallet',
      icon: (
        <img src={Wallets} className=' inline-block' alt='Wallet'/>
      ),
      link: '/wallet',
      id: 'wallet',
  },
  {
    name: 'Banks & Cards',
    icon: (
      <img src={Bank} className=' inline-block' alt='Banks & Cards'/>
    ),
    link: '/bankcards',
    id: 'bankcard',
},
{
  name: 'Settings',
  icon: (
    <SettingsIcon/>
  ),
  link: '/settings',
  id: 'settings',
},
  ])
  const drawer = (
    <div>
     <div className=' pt-2 border-b border-b-gray-300 mb-2 pb-4 bg-[#F8FAFC]  '>
        <img src={Logo}  alt='logo' className='  mx-auto'/>
        
          </div>
     
      <List>
          {sidebar.map((text, index) => (
            <NavLink
             className={({isActive}) =>{
                            return( ' block p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : ' bg-[#FDFDFE] border-l-4  border-l-primary-color text-back-color2 block'
                            ))
                        }}  
                      to={text.link}>
              {text.icon}
              <span className=' pl-3'>{text.name}</span>
           
            </NavLink>
           
            
          ))}
          
        </List>
      <div className=' mt-40'>
         <p className=' text-primary-color6 text-center'> ©️ 2023 - Zouse v1 </p>
      </div>
    </div>
  );
  const convertToTitleCase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };


  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
    <ToastContainer/>
    <Notification
      open={click}
      handleClose={handleCloss}
    />
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
       
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
         backgroundColor: '#F8FAFC',
        }}
      >
      <div className=' flex justify-between'>
      <div className=' flex justify-between'>
      <Toolbar>
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
         
                
       
        </Toolbar>
        <div className=' flex flex-col justify-center'>
          <p className=' text-black md:text-[24px] capitalize font-[400]'>Welcome  {convertToTitleCase(user.firstName)} </p>
         </div>
      </div>
     
            <div className=' md:flex hidden justify-center gap-2'>
            <div className=' flex flex-col justify-center'>
            <Link to={"/saveproducts"}>
           <img src={Save} className=' inline-block' height={"25"} width='25'  alt='buy'/>
           </Link> 
            </div>
          
            <div className='pt-5 relative'>
            <img src={Noti2} onClick={hadleOpem} className=' inline-block'  width='25'   alt='Notification'/>
            <div className='absolute top-4 right-1'>
              <p className=' rounded-[44px] text-white p-1  text-[8px]  bg-red-400 font-[900] '>{noti}</p>
            </div>
            </div>
           
            <div className=' mt-3 flex gap-2 mr-3 relative cursor-pointer' onClick={() => setOpen(!open)}>
            <div>
            <p className=' px-3 py-2 rounded-[44px] bg-back-color2'> {Array.from(user.lastName)[0]}</p> 
            </div>
           
            <div>
            <p className=' text-black inline-block text-[16px] mr-4'>{convertToTitleCase(user.firstName)}  {convertToTitleCase(user.lastName)} </p>
            <p className=' text-back-color2 text-[12px]'>{ user.emailAddress} </p>
            </div>
            <div className=' pt-2'>
            {open ?<ArrowDropUpIcon className=' text-back-color2'/> :<ArrowDropDownIcon className=' text-back-color2'/>} 
              {open && <div className=' mt-3 absolute top-12 right-0 rounded-lg p-6 pr-16 shadow-md text-back-color2 bg-white'>
                <p className=' mb-4'> <Link to='/settings'><SettingsIcon/> <span>Settings</span></Link> </p>

                <p className=' mb-4'><Link to='/logout'><LogoutIcon/> <span>Logout</span></Link></p>

              </div>}
            </div>
               
            </div>
                    </div>
                 </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
          {drawer}
          

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  backgroundColor: 'white' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      <div className=' w-full' >
          <Routess/>
      </div>
        
      </Box>
    </Box>
    </div>
  )
}
Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Dashboard