import React from 'react'
import SearchButton from '../SelectValue/SearchButton'
import RentalListCom from './RentalListCom'

function RentalList() {
  return (
    <div className=' mt-3 p-2'>
        <SearchButton/>
        <div className=' pt-4'>
            <RentalListCom/>
            <RentalListCom/>
            <RentalListCom/>
            
        </div>
    </div>
  )
}

export default RentalList