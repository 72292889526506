import  React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
//import { useNavigate } from 'react-router-dom';
import User from '../../Assets/images/userr.png'
import Button from '../SelectValue/Button'
import SelectBank from './SelectBank';
import TextBox from '../SelectValue/TextBox';
import getServices from '../../Services/get-service';
import swal from 'sweetalert';
import Pin from './Pin';
import postService from '../../Services/post-service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function AddBank({ open, handleClose}) {
    // const navigate = useNavigate()
    const [bankCode, setBankCode] = useState('')
    const [acc, setAcc] = useState('')
    const [accName, setAccName] = useState('')
    const [opens, setOpens] = useState(false)
    const handleCloses = () => setOpens(false)
    const handleOpens = () => setOpens(true)
    const [loading, setLoading] = useState(false)
    const [pin, setPin] = useState('')
   const [bName, setBName]= useState('')
    const handleSubmit = (e) =>{
      e.preventDefault()
      console.log('tyuyghghghg' +bName)
      if(accName.length !== 0){
        handleOpens()
           
      }else{
          swal(' Wrong account number')
      }
    }
     
      useEffect(() =>{
          if(acc.length === 10 && bankCode.length !== 0){
            setLoading(true)
            getServices.verifyBankAccount(acc,bankCode).then(
              (response) => {
                setLoading(false)
                if(response.data.status === "2000"){
                  setAccName(response.data.data.accountName);
                }else{
                  setAccName('')
                }
               
                console.log(response.data.data)
                
              },
              (error) => {
                setLoading(false)
              }
            )
          }
      }, [acc, bankCode, accName])

      const handleFinalSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
        postService.addBankAcc(acc, bName,pin,bankCode).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              swal('Bank added successfully').then((value) => {
                window.location.reload()
              });
              
             }else{
              swal(response.data.description).then((value) => {
                window.location.reload()
              });
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  swal('Next of kin setup failed Failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
      }
  return (
    <div>
      <Pin
        open={opens}
        handleClose={handleCloses}
        pin={pin}
        setPin={setPin}
        onSubmit={handleFinalSubmit}
        loading={loading}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Fade in={open}>
       
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          
            <div className=' h-[70vh] md:mt-5'>
                <div className=' mt-6 flex justify-between mb-3'>
                    <p className=' text-back-color2 text-[18px]  font-[500]'>Add Bank</p>
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div >
                <p className=' text-primary-color6 mt-7 mb-3 '>We pay your withdrawals into your bank account. Please make sure the account belongs to you.</p>
                <form onSubmit={handleSubmit}>
                <div>
                  <TextBox
                    label='Account number'
                    type='text'
                      value={acc}
                      onChange={setAcc}
                  />
                </div>
               
               <div className=' my-3'>
               <label className=' block mb-2'>Select Bank</label>
               <SelectBank
                bool={true}
                value={bankCode}
                onChange={setBankCode}
                bName={setBName}
              />
               </div>
              
               <div className='my-5 flex gap-4'>
                <img src={User} alt='user' />
                <p className=' text-[18px] pt-2 text-back-color2 font-[500]'>{accName}</p>
               </div>
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Add bank'
              />
              </div>
              </form>
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}