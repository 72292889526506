
import { TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import getServices from '../../Services/get-service'
import Button from '../SelectValue/Button'
import Inputtt from '../SelectValue/Inputtt'
import Loading from '../SelectValue/Loading'
import AdvanceSearch from './AdvanceSearch'
import ListingCom from './ListingCom'
import ListPropertyDrawer from './ListPropertyDrawer'
import Listing from '../../Assets/images/listing.png'

function MainSection2() {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [projects, setProjects] = useState([])
    const [value, setValues] = useState('')
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [minP, setMinP] = useState(0.0)
    const[maxP, setMaxP]= useState(0.0)
    const[location, setLocation]= useState('')
    const[type, setType] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    //   const filteredPlate = projects.filter(
    //     person => {
    //       return (
    //         person
    //         .projectName
    //         .toLowerCase()
    //         .includes(name.toLowerCase()) 
    //       );
    //     }
    //   );
    useEffect(() =>{
        setLoading(true)
        getServices.getAllProperty(location,maxP,minP,name,type).then(
            
            (response) => {
                setLoading(false)
              if(response.data.status === "2000"){
                setProjects(response.data.propertyPlan)
                setMaxP(0.0)
                setMinP(0.0)
              }else{
               swal(response.data.description)
              }
             
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                swal('No project found')
            }
          )
    }, [location,maxP,name,minP,type])
    const filteredData = projects.filter(item => {
      return(
        item.propertyType === 'property' && item.propertyStatus === 'active'
      )
      });
    const filtereQuery = filteredData.filter(
        person => {
          return (
            person
            .title
            .toLowerCase()
            .includes(value.toLowerCase()) 
          );
        }
      );
  return (
    <>
    <ListPropertyDrawer
        open={open}
        handleClose={handleClose}
        id={id}
    />
    <Loading
        open={loading}
    />
    <div className=' grid md:grid-cols-3 gap-3'>
        <div className=' md:col-span-2 md:overflow-y-auto md:h-[85vh]'>
            <div className=' flex gap-3'>
                <Inputtt
                    placeholder='e.g property name'
                    onChange={setValues}
                />
                {/* <Button
                    name='Search'
                /> */}
            </div>
            <div>

            { filtereQuery.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(project =>{
                return <div key={project.id}>
                <ListingCom
                    name={project.title}
                    id={project.propertyId}
                    header={project.propertyType}
                    location={project.locality}
                    img={project.imageUrl[0] ??Listing }
                    dis={project.description === null ?project.description : project.description.slice(0,150)}
                    date={project.createdDate === null ? project.createdDate :project.createdDate.slice(0,10)}
                    amount={project.price}
                    area={project.totalArea}
                    onClick={() =>{
                        setId(project.propertyId)
                        setOpen(true)
                    }}
                />
                </div>
            })}

               {filtereQuery.length === 0 && <div><p className=' text-center text-red-700 mt-9 text-red'>No data found </p>
               </div>} 
               <TablePagination
          rowsPerPageOptions={[5,10, 25, 100]}
          component="div"
          count={filtereQuery.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
            </div>
        </div>
        <div>
            <AdvanceSearch
              setMin={setMinP}
              setMax={setMaxP}
              
            />
        </div>
    </div>
    </>
  )
}

export default MainSection2