import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import { Link } from 'react-router-dom'
import Loading from '../SelectValue/Loading'
import postService from '../../Services/post-service'
import Logo from '../../Assets/images/Zlogo.png'
import cookie from 'react-cookies'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Constant from '../../Constant'
function Loginform() {
  const [loading, setLoading] = useState(false)
    const initialValues = { 
        email: '',
        password: '',

      }
      const validationSchema = Yup.object({
        
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
       password: Yup.string()
       .required('Please Enter your password')
     
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {email, password} = values
        postService.userLogin(email, password).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              localStorage.setItem('userdetails', JSON.stringify(response.data.data))
              localStorage.setItem('accountStatuss',response.data.data.accountStatus )
              cookie.save("token", response.data.data.token, {
                path: "/",
                expires: new Date(Date.now() + 60 * 10 * 1000),
              });
              return   window.location.href = '/dashboard'
             }else{
            
              toast.error(response.data.description,
                {
                  position: toast.POSITION.TOP_LEFT
                })
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error('Login failed. Try again',
                {
                  position: toast.POSITION.TOP_LEFT
                })
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' '>
    <Loading
      open={loading}
    />
    <ToastContainer/>
     <img src={Logo} width='152' height='40' className=" mx-auto mb-5 "  alt=" logo" /> 
        <div className=' bg-white shadow-lg p-10 rounded-[16px]'>
       
            <p className=' mt-6 text-[18px] text-back-color2 mb-5 md:text-[40px] font-[500]'>Login to your account</p>
           
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                label='Email address'
                name='email'
              />  
               
               <FormikControl
                control='password'
                label='Password'
                name='password'
              /> 
              <div className=' flex justify-end mt-3 mb-7'>
                <Link to='/forgetpassword'><span className=' text-primary-color'>Forgot password?</span></Link>
              </div> 
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Login'
              />
              </div>
              <div className=' mt-4'>
                <p className=' font-[500] text-center'>Don’t have an account? <Link to='/signup' className=' text-primary-color'>Create account</Link> </p>
              </div>
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default Loginform