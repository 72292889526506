import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import {  useNavigate } from 'react-router-dom'
import StartRadio from './StartRadio'
import { addMonths } from 'date-fns'
// import Constant from '../../Constant'
function SavingStart() {
  

    const navigate = useNavigate()
    const initialValues = { 
        name: new Date(),

      }
      const validationSchema = Yup.object({
      //   name:Yup
      //  .string()
      //  .required('Select a date'),
      
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        const {name} = values
        sessionStorage.setItem('startSate', name)
        navigate('/save/plan/long')
      }

  return (
    <div className=''>
        <div className=' h-[60vh] '>
            <p className=' text-[18px] text-back-color2 mb-5 font-[500]'>When will you like to start?</p>
           
            <div className='mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
                >
        {formik => (
        <Form>
            <StartRadio/>
               {/* <FormikControl
                control='date'
                label='Set preferred date'
                name='name'
                minDate={new Date()}
                maxDate={addMonths(new Date(), 12)}
                showIcon
                showDisabledMonthNavigation
              />   */}
               
              
              <div className=' grid grid-cols-1 mb-5 mt-16'>
              <Button
                name='Next'
              />
              </div>
            
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default SavingStart