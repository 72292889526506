import React from 'react'

function SelectD({label, options, value, onChange}) {
  return (
    <div className=' mb-3'>
     <label className=' text-black block mb-2' >{label}</label>
        <select  value={value}  required onChange={(e) => onChange(e.target.value) } className=' w-full border rounded-lg bg-[#FAFBFC] border-gray-200 p-5'>
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          )
        })}
        </select>
    </div>
  )
}

export default SelectD