import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import {  useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading'
import postService from '../../Services/post-service'
import Logo from '../../Assets/images/Zlogo.png'

function ForgetPasswordForm() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const initialValues = { 
        email: '',
      }
      const validationSchema = Yup.object({
        email:Yup
        .string()
        .email()
        .required('Please Enter your Email'),
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {email} = values
        postService.forgetPassword(email).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              localStorage.setItem('requestId', response.data.data.requestId)
              localStorage.setItem('email', email)
              return swal('Otp sent to your mail')
              .then((value) => {
                navigate('/resetpassword')
              }); 
             }else{
              swal(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  swal('Password reset failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
            // navigate('/resetpassword')
      }

  return (
    <div className='bg-signin-bg h-screen'>
    <div className='grid  w-11/12 md:w-8/12 mx-auto  h-[80vh] place-items-center'>
      <Loading
        open={loading}
      />
        <div className='shadow-lg bg-white rounded-[16px] p-16 '>
        <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
            <p className='pt-7 text-[18px] text-back-color2 mb-5 md:text-[40px] font-[500]'>Forgot your Password?</p>
           <p className=' mb-3 text-[#44474E]'>We'll email/sms you an OTP to reset your password</p>
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                type='email'
                label='Email address'
                name='email'
              />  
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Reset password'
              />
              </div>
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
    </div>
  )
}

export default ForgetPasswordForm