import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import SignupModal from './SignupModal';
import swal from 'sweetalert';
import postService from '../../Services/post-service';
import Logo from '../../Assets/images/Zlogo.png'
import Loading from '../SelectValue/Loading';

function ConfirmPinForm() {
  const cPin = localStorage.getItem('pin')
  const [loading, setLoading] = useState(false)
  const[open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
  
    const navigator = useNavigate()
    const [pin, setPin] =useState('')
    const onSubmit = (e) =>{
        e.preventDefault()

        if(pin === cPin){
          setLoading(true)
          postService.setupTransactionPin(pin).then(
            (response) => {
                console.log(response.data)
                setOpen(false)
               if(response.data.status === '2000'){
                setLoading(false)
                return   setOpen(true)
               }else{
                setLoading(false)
                swal(response.data.description).then((value) => {
                  window.location.reload()
                });
               
               }
               
              },
              (error) => {
                setLoading(false)
                setOpen(false)
                  return  swal('Pin setup failed')
                  .then((value) => {
                    window.location.reload()
                  });
                }
          )
          
          console.log(pin)
        } else{
          setLoading(false)
          return  swal('Pin not a match')
        }
           
    }
  return (
    <div className=' bg-signin-bg h-[90vh]'>
    <>
     <SignupModal
      open={open}
      handleClose={handleClose}
    />
     <div className='grid h-[85vh] p-3 md:p-0 place-items-center'>
    <Loading
      open={loading}
    />
    <div className=' md:w-1/3 p-5 bg-white shadow-lg rounded-[16px] md:p-10 '>
    <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
        <h1 className=' text-[18px] mt-4 text-back-color2 mb-5 md:text-[40px] font-[500]'>Confirm your Zouse PIN</h1>
       <p className=' mb-3 text-[#44474E]'>This PIN will be used for every transaction on Zouse. Keep it safe!</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Confirm PIN'
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
   </div>
    </>
    </div>
  )
}

export default ConfirmPinForm