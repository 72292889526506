import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import Loading from '../SelectValue/Loading';
import swal from 'sweetalert';
import Logo from '../../Assets/images/Zlogo.png'
function ResetPasswordForm() {
  const mail = localStorage.getItem('email')
    const navigator = useNavigate()
    const [pin, setPin] =useState('')
    const [loading, setLoading] = useState(false)
    const onSubmit = (e) =>{
        e.preventDefault()
          if(pin.length === 6){
            localStorage.setItem('resetOtp', pin)
            navigator('/newpassword')
          }else{
            swal('Otp should be 6 digit')
          }
           
        console.log(pin)
    }
  return (
    <div className='bg-signin-bg h-screen'>

    
    <div className='grid h-[80vh] place-items-center'>
    <Loading
      open={loading}
    />
    <div className=' md:w-1/3 bg-white shadow-lg rounded-[16px] p-6 md:p-16'>
    <img src={Logo} width='152' height='40' className=" "  alt=" logo" /> 
        <p className=' text-[18px] pt-7 text-back-color2 mb-5 md:text-[40px] font-[500]'>Reset password</p>
       <p className=' mb-3 text-[#44474E]'>An OTP has been sent to your associated email address <span className=' font-[500] text-back-color2'>{mail}</span>. Enter the 6-digit code to recover your password.</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={6}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Reset password'
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
</div>
  )
}

export default ResetPasswordForm