import React from 'react'
import { Link } from 'react-router-dom'
import Star from '../Assets/images/Star.png'
function NavbarSignin() {
  return (

    <div className=' flex justify-between p-5 md:px-16 w-full border border-b-[#D2D3D5] bg-white   md:bg-white '>
        <div>
       
       
        </div>
        <div>
            <div className=' flex pt-3'>
            <div className=' flex flex-col justify-center'>
            <img src={Star} alt='Star' />
            </div>
           
            <div className=' pl-3'>
                <p>Have an account? <span className=' text-primary-color'><Link to='/'>Login</Link></span></p>
            </div>
            </div>
        </div>
    </div>
  )
}

export default NavbarSignin