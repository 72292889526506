import React from 'react'
import SavingPlans from '../../Components/Save/SavingPlans'
import Savings from '../../Components/Save/Savings'
import Topsec from '../../Components/Save/TopSec'

function Save() {
  return (
    <div>
      <Topsec/>
      <Savings/>
      <SavingPlans/>
    </div>
  )
}

export default Save