import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function TransactionSkeleton() {
  return (
    <Stack className=' my-4' spacing={1}>
    <div className=' flex justify-between gap-5'>
    <div className=' flex gap-5'>
    <Skeleton variant="circular" width={40} height={40} />
    <div>
    <Skeleton variant="text" width={100} height={20} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={60} height={20} sx={{ fontSize: '1rem' }} />
    </div>
     
    </div>
   <div>
   <Skeleton variant="text" width={100} height={20} sx={{ fontSize: '1rem' }} />
   </div>
    </div>
    
      
    </Stack>
  );
}