import  React,{useState} from 'react';
import PinInput from 'react-pin-input';
import Button from '../SelectValue/Button'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import postService from '../../Services/post-service';
import Loading from '../SelectValue/Loading';

export default function ConfirmPin() {
    const oldPin = localStorage.getItem('currentpin')
    const newPin = localStorage.getItem('newpin')
    const [loading, setLoading] = useState(false)
  const navigator = useNavigate()
    const [pin, setPin] =useState('')
    const onSubmit = (e) =>{
        setLoading(true)
        e.preventDefault()
        if(pin.length === 4){
            setLoading(true)
            if(newPin === pin){
                    postService.changePin(pin, oldPin).then(
                        (response) => {

                            console.log(response.data)
                           
                           if(response.data.status === '2000'){
                           setLoading(false)
                            return  swal('Account created successfully')
                           
                           }else{
                            setLoading(false)
                            swal(response.data.description)
                           
                           }
                           
                          },
                          (error) => {
                            setLoading(false)
                              return  swal('Change of pin Failed')
                            }
                      )
            }else{
                swal('Pin not a match')
            }
        }else{
            setLoading(false)
            swal('Pin should be 4 degit')
        }
           
    }
    
  return (
    <div className=' mt-10 '>
      <Loading
        open={loading}
      />
    <p className=' mb-3 text-back-color2'>Confirm your new  Zouse PIN</p>
     <div className='  mt-5'>

     <div >
     <form onSubmit={onSubmit}> 
     <div className=' w-full flex justify-center'>
 
             <PinInput 
             length={4}
             initialValue={pin}
             secret  
             onChange={(value, index) => {
                 setPin(value)
             }} 
             
             type="numeric" 
             inputMode="number"
             style={{padding: '8px', }}  
             inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
             inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
             onComplete={(value, index) => {
                 pin(value)
                 }}
                 autoSelect={true}
                 regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
             />
     </div>
     
     <div className=' grid grid-cols-1 mb-5 mt-6'>
     <Button
         name='Change Pin'
       />
     </div>
    
     </form>
      
       </div>
   
     </div>
 </div>
  );
}