import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,

} from "recharts";


export default function TransactionChart() {
  
      const data = [{name: 'Jan', orders: 16},{name: 'Feb', orders: 36},{name: 'Mar', orders: 53},{name: 'Apr', orders: 96},{name: 'May', orders: 22}, {name: 'Jun', orders:  53}, {name: 'Jul', orders:  94}, {name: 'Aug', orders: 28}, {name: 'Sep', orders: 38}, {name: 'Oct', orders: 17}, {name: 'Nov', orders: 29}, {name: 'Dec', orders: 100}];
      
      
  return (
    <div className=" mt-3">
     
    <ResponsiveContainer width="100%" height={100}>
    <AreaChart data={data}>
      <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#00BC67" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#BFE8FF" stopOpacity={0.05} />
          </linearGradient>
        </defs>
        <Area dataKey="orders" stroke="#00BC67" fill="url(#color)" />

          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            color='white'
          />

          <YAxis
            datakey="orders"
            
            tickCount={8}
           
          />
           <Tooltip />
    </AreaChart>
    </ResponsiveContainer>
     
    </div>
  );
}
