import  {useEffect, useState} from 'react'
import getServices from '../../Services/get-service';


function SelectBank({ value, onChange, bName}) {
  const [bank, setBank] = useState([])
    useEffect(()=>{
      getServices.getAllBank().then(
            (response) => {
              setBank(response.data.data);
              console.log(response.data.data)
              
            },
            (error) => {
              
            }
          )
    }, [])
       function handleChange(event) {
      onChange(event.target.value);
      console.log("Selected value:", event.target.value);
      console.log("Selected name:", event.target.name);
        const bankName = bank.find(item=> {return item.bankName === event.target.value })
        bName(bankName.bankCode);
    }
  return (
    <div className=' w-full'>
    <label className=' mb-3 block'>Select a bank</label>
    <input list="data" className=' bg-white border border-gray-300 p-3 rounded-md w-full' value={value} onChange={handleChange} placeholder="Search" />
            <datalist className=' bg-white' id="data">
                {bank.map((i)=><option key={i.bankCode}  value={i.bankName}>{i.bankName}</option>)}
            </datalist>
    
    </div>
  )
}


export default SelectBank