import React, { useEffect, useState } from 'react'
import {  Route, Routes } from 'react-router-dom';

import ProductDetails from '../../Components/Buy/Productdetails';
import BuyUnits from '../../Components/Buy/Buyunit';
import Pay from '../../Components/Buy/Pay';
import ProjectDetails from '../../Components/Buy/ProjectDetails';
import ProjectDetailsCom from '../../Components/Buy/ProjectDetailsCom';
import DetailsTransaction from '../../Components/Buy/DetailsTransaction';
import NamePlan from '../../Components/Save/NamePlan';
import NameePlan from '../../Components/Save/NameePlan';
import SavingStart from '../../Components/Save/SavingStart';
import HowLong from '../../Components/Save/HowLong';
import HowOften from '../../Components/Save/HowOften';
import CreateCard from '../../Components/Save/CreateCard';
import SavingSummary from '../../Components/Save/SavingSummary';
import MakePayment from '../../Components/Save/MakePayment';
import TargetPlanname from '../../Components/Save/TargetPlanname';
import TargetDetails from '../../Components/Save/TargetDetails';
import TargetTransaction from '../../Components/Save/TargetTransaction';
import BuyTarget from '../../Components/Save/BuyTarget';
import TargetCard from '../../Components/Save/TargetCard';
import TargerNotice from '../../Components/Save/TargerNotice';
import TargetPin from '../../Components/Save/TargetPin';
import Settings from '../Setting';
import ChangePin from '../../Components/Setting/ChangePin';
import MainDashboaard from '../MainDashboard';
import Buy from '../Buy';
import Listing from '../Listing';
import Wallet from '../Wallet';
import BankCard from '../BankCard';
import Save from '../Save';
import CurrentPin from '../../Components/Setting/CurrentPin';
import NewPin from '../../Components/Setting/NewPin';
import Pin from '../../Components/BankCard/Pin';
import BankAdded from '../../Components/BankCard/BankAdded';
import BankTransfer from '../../Components/Wallet/BankTransfer';
import WithdrawalPin from '../../Components/Wallet/Pin';
import WalletSuccess from '../../Components/Wallet/WalletSuccess';
import ConfirmPin from '../../Components/Setting/ConfirmPin';
import ResetPin from '../../Components/Setting/ResetPin';
import ResetPinPin from '../../Components/Setting/ResetPinpin';
import ResetPinOtp from '../../Components/Setting/ResetPinOtp';
import PropertyListing from '../Listing/PropertyListing';
import Payment from '../Payment';
import Rental from '../Rentals';
import Activerent from '../../Components/Rentals/Activerent';
import Pendingrent from '../../Components/Rentals/Pendingrent';
import SaveProducts from '../SaveProducts';

function Routess() {
  const [accountSta, setAccountSta] = useState(true)
   
   useEffect(() =>{
    setAccountSta(localStorage.getItem('accountStatuss'))
   },[])

   useEffect(()=>{
    console.log(accountSta)
    console.log(accountSta == "true")
   }, [accountSta])
  return (
    <div>
    

    {accountSta  === "true"  ? (
      <Routes>
              <Route path='/dashboard' element={<MainDashboaard/>}/> 
              <Route path='/payment/:ref/:transtype' element={<Payment/>}/>
              <Route path='/rental' element={<Rental/>}>
              <Route path='active' element={<Activerent/>}/>
              <Route path='pending' element={<Pendingrent/>}/>
              </Route>
              <Route path='/save' element={<Save/>}>
                  <Route path='plan' element={<NamePlan/>}>
                      <Route path='nameplan/:type' element={<NameePlan/>}/>
                      <Route path='start' element={<SavingStart/>}/>
                      <Route path='long' element={<HowLong/>}/>
                      <Route path='often' element={<HowOften/>}/>
                      <Route path='createpayment' element={<CreateCard/>}/>
                      <Route path='paymentsummary' element={<SavingSummary/>}/>
                      <Route  path='payment' element={<MakePayment/>}/>
                  </Route>
                  <Route path='plan/:name' element={<TargetPlanname/>}>
                    <Route path='details/:planid/:cashout/:name/:enddate/:startdate/:per/:target/:status/:amount' element={<TargetDetails/>} />

                    <Route path='transactions/:id/:status/:amount/:date' element={<TargetTransaction/>}/>
                    <Route path='buytarget' element={<BuyTarget/>}>
                    <Route path='card' element={<TargetCard/>}/>
                    <Route path='notice' element={<TargerNotice/>}/>
                    <Route path='pin' element={<TargetPin/>}/>
                  </Route>
                  </Route>
                
              </Route>
              <Route path='/buy' element={<Buy/>}>
              
              

              <Route path='productdetails/:id' element={<ProductDetails/>}>
              <Route path='buy/:id' element={<BuyUnits/>}/>
              <Route path='pay/:id' element={<Pay/>}/>
              </Route>
              <Route path='projectdetails' element={<ProjectDetails/>}>
                <Route path='details/:id/:unit/:dur/:cashout/:pid/:name/:amount/:perBought/:endDate/:purchaseid/:existStatus' element={<ProjectDetailsCom/>}/>
                <Route path='transactions/:id' element={<DetailsTransaction/>}/>
              </Route>
              </Route>
              <Route path='/pay/:id' element={<Pay/>}/>
              <Route path='/landlisting' element={<Listing/>}/>
              <Route path='/propertylisting' element={<PropertyListing/>}/>
              <Route path='/wallet' element={<Wallet/>}>
                <Route path='banktransfer/:bname/:aname/:accno' element={<BankTransfer/>}/>
                <Route path='pin' element={<WithdrawalPin/>}/>
                <Route path='success' element={<WalletSuccess/>}/>
              </Route>
                <Route path='/saveproducts' element={<SaveProducts/>}/> 
              <Route path='/bankcards' element={<BankCard/>}>
              <Route path='pin' element={<Pin/>}/>
              <Route path='bankadded' element={<BankAdded/>}/>
              </Route>
              <Route path='/settings' element={<Settings/>}>
              <Route path='resetpin' element={<ResetPin/>}>
              <Route path='pin' element={<ResetPinPin/>}/>
              <Route path='otp' element={<ResetPinOtp/>}/>
              </Route>
              <Route path='changepin' element={<ChangePin/>}>
              <Route path='newpin' element={<NewPin/>}/>
              <Route path='current' element={<CurrentPin/>}/>
              <Route path='confirmpin' element={<ConfirmPin/>}/>

              </Route>
              </Route>
              <Route path='*'element={<MainDashboaard/>}/>
            </Routes>
              )
    : 
    <Routes>
    <Route path='/settings' element={<Settings/>}>
              <Route path='resetpin' element={<ResetPin/>}>
                <Route path='pin' element={<ResetPinPin/>}/>
                <Route path='otp' element={<ResetPinOtp/>}/>
              </Route>
              <Route path='changepin' element={<ChangePin/>}>
                <Route path='newpin' element={<NewPin/>}/>
                <Route path='current' element={<CurrentPin/>}/>
                <Route path='confirmpin' element={<ConfirmPin/>}/>

              </Route>
      </Route>
      <Route path='*' element={<Settings/>}/>
      </Routes>
      }
  
    
        
    </div>
  )
}

export default Routess