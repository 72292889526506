import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Success from '../../Assets/images/success2.png'
import Button from '../SelectValue/Button';
import { Link } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export default function TargetSuccess({open, handleClose}) {
 

  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className='grid h-[45vh] place-items-center'>
                    <div>
                        <img src={Success} className=' mb-3 mx-auto' alt='Sucess'/>
                        <p className=' text-back-color2  mb-3 text-center'>Successful</p>
                        <p className=' text-primary-color6 text-[16px] text-center mb-6'>You have funded your Target savings plan with <span className=' text-back-color2 font-[700]'>NGN 100,000</span> </p>
                        <Link to='/save'>
                        <div className=' mt-10 grid grid-cols-1'>
                            <Button
                                name='Go to plan'
                            />
                        </div>
                        </Link>
                      
                    </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}