import React from 'react'
import { Outlet } from 'react-router-dom'
import Topsec from '../../Components/Wallet/Topsec'
import Transactions from '../../Components/Wallet/Transactions'

function Wallet() {
  return (
    <div>
      <Topsec/>
      <Outlet/>
      <Transactions/>
    </div>
  )
}

export default Wallet