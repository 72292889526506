import React from 'react'
import ResetPasswordForm from '../../Components/Login/ResetPasswordForm'
import NavbarBack from '../../Components/NavbarBack'

function ResetPassword() {
  return (
    <div>
        <NavbarBack/>
        <ResetPasswordForm/>
    </div>
  )
}

export default ResetPassword