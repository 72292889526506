import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import KYCMessage from '../../Components/Setting/KYCMessage'
import TopSec from '../../Components/Setting/TopSec'

function Settings() {
  const accountStatus = localStorage.getItem('accountStatuss')
   const [open, setOpen] = useState(false)
   const handleClose = () => setOpen(false)
   useEffect(() =>{
      if(accountStatus == 'false'){
        setOpen(true)
      }
       
     
   },[accountStatus])
  return (
    <div>
     <KYCMessage
      open={open}
      handleClose= {handleClose}
    />
        <TopSec/>
        <Outlet/>
    </div>
  )
}

export default Settings