import { TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Success from '../../Assets/images/success.png'
import Red from '../../Assets/images/successf.png'
import getServices from '../../Services/get-service'
import TranCom from '../Buy/TranCom'
import TransactionSkeleton from '../SkeletonComponent/TransactionSke'

function TargetTransaction() {
  const{id, status, amount, date} = useParams()
  const [loading, setLoading] = useState(false)
  const [savings, setSavings] = useState([])
  const elements = Array(10).fill(null); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() =>{
    setLoading(true)
    getServices.getSavingPlanDetails(id,'recursive').then(
     
      (response) => {
        setLoading(false)
       if(response.data.status === '2000'){
         setSavings(response.data.data.reverse());
         console.log(response.data.data)
       }else if (response.data.status === '4000') {
        window.location.href = '/';
       }
        
      },
      (error) => {
        setLoading(false)
        window.location.href = '/';
      }
    )
  }, [id])
  return (
    
    <div>
         <div className=' mt-4'>
         {/* {loading && 
          <TranCom
                img={Success}
                header={amount}
                sub='success'
                date={date === null ?'No date' :date.slice(0,10)}
            />
         }
         */}
         {loading && <>
          {elements.map((_, index) => (
            <TransactionSkeleton key={index}/>
       
      ))}
          

         </>}
         {savings
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
         .map((e, i) =>{
          return e.status === 'success' ?
          <TranCom
              key={i}
                img={Success}
                header={e.amount}
                sub={e.status}
                date={e.debitDate === null ?'No date' :e.debitDate.slice(0,10)}
            />
            :
            <TranCom
            key={i}
                img={Red}
                header={e.amount}
                sub={e.status}
                date={e.debitDate === null ?'No date' :e.debitDate.slice(0,10)}
            />
         })}
         {!loading && 
          <TranCom
                img={Success}
                header={amount}
                sub='success'
                date={date === null ?'No date' :date.slice(0,10)}
            />
         }
              {/* {savings.length === 0 && !loading && <p className=' mt-3 text-center text-red-700'>No transaction yet</p>} */}
              <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={savings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        </div>
    </div>
  )
}

export default TargetTransaction