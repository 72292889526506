import React, { useState, useEffect } from 'react'
import Invest from '../../Assets/images/in.png'
import Saving from '../../Assets/images/sav.png'
import Wallet from '../../Assets/images/wallett.png'
import getServices from '../../Services/get-service'
import DashCardComp from './DashCardComp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constant from '../../Constant'
function Topsec() {
  const [savings, setSavings] = useState('0.0')
  const [accBalance, setAccBalance] = useState('0.0')
  const [investmentPlan, setInvestmentPlan] = useState('0.0')
  const [lB, setLB] = useState('0.0')
  useEffect(() => {
    getServices.getWallerBalance().then(
      (response) => {
       
        if(response.data.status === "2000"){
          setSavings(response.data.data.totalSavingsPlan)
          setAccBalance(response.data.data.accountBalance)
          setInvestmentPlan(response.data.data.totalInvestmentPlan)
          setLB(response.data.data.ledgerBalance)
        }else{
          toast('Session Expired', {
            onClose: () => {
              window.location.href = '/'
            },
            autoClose: 5000
          })
         
        }
       
        console.log(response.data.data)
        
      },
      (error) => {
        toast('Session Expired', {
          onClose: () => {
            window.location.href = '/'
          },
          autoClose: 5000
        })
       
      }
    )
  
   
  }, [])
  
  return (
    <div>
    <ToastContainer/>
       <div className=' grid md:grid-cols-3 gap-4 mb-6'>
        <DashCardComp
            img={Invest}
            title='Investment balance'
            sub={investmentPlan === 'nu' ? '0.00':Constant.FormatNumber(investmentPlan) }
        />
        <DashCardComp
            img={Saving}
            title='Savings balance'
            sub={savings === 'nu' ? '0.00':Constant.FormatNumber(savings) }
        />
        <DashCardComp
            img={Wallet}
            title='Wallet balance'
            sub={accBalance === 'nu' ? '0.00':Constant.FormatNumber(accBalance)}
            bool={true}
            leadBalance={Constant.FormatNumber(lB)}
        />
    </div>
    </div>
   
  )
}

export default Topsec