import React from 'react'
import { Outlet } from 'react-router-dom'
import ActiveInvestment from '../../Components/Buy/ActiveInvestment'
import MakeInvestment from '../../Components/Buy/MakeInvestment'
import TopInvestment from '../../Components/Buy/TopInvestment'
import Topsec from '../../Components/Buy/Topsec'

function Buy() {
  return (
    <div>
      <Outlet/>
      <Topsec/>
      <ActiveInvestment/>
      <div  id='newinvest'>
      <MakeInvestment/>
      </div>
     
      <TopInvestment/>
    </div>
  )
}

export default Buy