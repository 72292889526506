import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import Loading from '../SelectValue/Loading';
import swal from 'sweetalert';
import postService from '../../Services/post-service';
function CurrentPin() {
    const navigator = useNavigate()
    const [loading, setLoading] = useState(false)
    const [pin, setPin] =useState('')
    const onSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
          if(pin.length === 4){
            setLoading(false)
            localStorage.setItem('currentpin', pin)
            navigator('/settings/changepin/newpin')
        console.log(pin)
          }else{
            setLoading(false)
            swal('Pin should be 4 degit')
          }
            
    }

    const resetPin = () =>{
      setLoading(true)
      postService.forgetPin().then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            localStorage.setItem('requestIdr', response.data.data.requestId)
            swal(response.data.description).then((value) => {
              navigator('/settings/resetpin/otp')
            });
            
           }else{
            swal(response.data.description).then((value) => {
              window.location.reload()
            });
           
           }
           
          },
          (error) => {
            setLoading(false)
              return  swal('Pin reset Failed')
              .then((value) => {
                window.location.reload()
              });
            }
      )
    }

  return (
    <div className=''>
    <Loading
      open={loading}
    />
    <div className=' mt-10 '>
      
       <p className=' mb-3 text-back-color2'>Enter your current  Zouse PIN</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <p className=' font-[500] text-primary-color6 text-center mt-10'>Forgot PIN?<span onClick={resetPin} className=' cursor-pointer text-primary-color'>Reset</span></p>

        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Next'
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
  )
}

export default CurrentPin