import React from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from '../SelectValue/Loading';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function Pin({ open, handleClose, pin, setPin, onSubmit, loading}) {
  
    
  
  return (
    <div>
     
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <Loading
            open={loading}
          />
            <div className=' h-[60vh] md:mt-5'>
                <div className=' mt-6 flex justify-end mb-3'>
                    
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div >
                <div className=''>
    <div className=' mt-10 '>
      
       <p className=' mb-3 text-back-color2'>Enter your Zouse PIN</p>
       <p className=' mb-3 text-[#808285]'>Enter your 4-Digit PIN to confirm transaction</p>
        <div className='  mt-5'>

        <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>

        
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Next'
            
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
         
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}