import React from 'react'
import Youtube from '../../Assets/images/utube.png'
import YouTubeVideoComponent from '../Buy/Videoo'
function Video({video}) {
  return (
    <div className=' bg-[#E6E6E7] rounded-lg grid h-[35vh] place-items-center'>
       <YouTubeVideoComponent
        videoId={video}
       />
    </div>
  )
}

export default Video