import React from 'react'
import { Link } from 'react-router-dom'
import Notice from '../../Assets/images/notice.png'
import Button from '../SelectValue/Button'

function TargerNotice() {
  return (
    <div className=' grid h-[50vh] place-items-center'>
        <div>
            <img src={Notice} className=' mx-auto mb-4' alt='notice'/>
            <p className=' text-center text-back-color2 text-[18px] mb-3'>Are you sure?</p>
            <p className=' text-center text-[16px] font-[400] text-primary-color6  mb-7'>Are you sure you want to fund this plan with <span className=' text-back-color2 font-[700]'> NGN 100,000</span></p>
                    <div className=' flex justify-center'>
                      
                        <div>
                        <Link to='/save/targetplanname/buytarget/pin'>
                            <Button
                                name='Fund plan'
                            />
                            </Link>
                            <div>
                            
                            <Link to='/save'>
                                <p className=' p-4 mt-8 px-16 bg-[#FAFBFC] text-center inline-block rounded-lg text-back-color2'>
                                Cancel
                                </p>
                            </Link>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
  )
}

export default TargerNotice