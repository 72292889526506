import React, {  useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '../SelectValue/Button';
import FundWalletPin from './Pins';
import postService from '../../Services/post-service';
import { usePaystackPayment } from 'react-paystack';
import getServices from '../../Services/get-service';
import {  toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NonnegativeInputField from '../SelectValue/NonnegativeInputfield';
import WalletSuccess from './WalletSuccess';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};


function SelectBank({ open, handleClose}) {
 
  const [opeen, setOpeen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [amountt, setAmount] = useState('')
  
  const [show, setShow] = useState(false)
 
  const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const email = user.emailAddress;
    const [ref, setRef] = useState('')
    const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
    const [configP, setConfig] = useState(initialConfig);
    let initializePayment = usePaystackPayment(configP);
  const handleCloose = () => setOpeen(false)
  const [pin, setPin] = useState('')
  
    
    const onSubmit = (e) => {
      e.preventDefault()
      setOpeen(true)
      
    }
    const FinalSubmit = (e) =>{
      e.preventDefault()
      console.log(amountt)
      setLoading(true)
      postService.fundWallet(amountt * 100,email, pin).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            const reference = response.data.data.reference
            
            setConfig({
              ...configP, channel: [
                'card',
                'bank',
                'ussd',
                'qr',
                'mobile_money',
                'bank_transfer',
                'eft',
              ], email: email, reference: reference, amount: amountt * 100,
            });
         
           console.log(response.data.description)
           }else{
            toast.error(response.data.description)
           
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
              });
            }
      )
    }
    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      console.log( reference);
      console.log()
      setRef(reference.reference)
      setShow(true)
      // verifyPayment(reference.reference)
    };
    const handleClos = () => {
      setShow(false)
      verifyPayment(ref)
  }
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
      window.location.reload()
    }
  
  // const initializePayment = usePaystackPayment(config);

  

  const verifyPayment = (ref) =>{
    setLoading(true)
    getServices.getVerifyPayment(ref).then(
      (response) => {
       setLoading(false)
        console.log(response.data)
        window.location.reload()
      },
      (error) => {
        setLoading(false)
        window.location.reload()
      }
    )
  }
  useEffect(() => {
    console.log(configP)
    if (configP.reference === null || configP.email === undefined) return;
    initializePayment(onSuccess, onClose)
  }, [configP])
  return (
    <>
    <div>  
    <FundWalletPin
      open={opeen}
      handleClose={handleCloose}
      loading={loading}
      pin={pin}
      setPin={setPin}
      onSubmit={FinalSubmit}
    />
    <WalletSuccess
     open={show}
     handleClose={handleClos}
     header='Funding successful'
     con={<p>You have funded your wallet with <span className='text-back-color2 font-[500]'>NGN {amountt}</span>!!</p>}
    />
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <Box sx={style} className=' rounded-lg p-6 pt-10'> 
        <div className=' h-[40vh] '>
            <div className=''>
              
              <div className=' flex justify-between mb-3'>
                   <div>
                    <p className=' text-[18px] text-[500] text-back-color2'>Fund wallet using paytack</p>
                   </div>
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
              
              </div>

              <div className=' mt-7'>
        <form onSubmit={onSubmit}>
            
               <NonnegativeInputField
               valuee={amountt}
                label='Enter amount(NGN)'
                onChange={setAmount}
              />  
             
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Fund wallet'
              />
              </div>
        </form>
              {/* {userBank.map(bank =>{
                return <div key={bank.accountId}>
                <BankComp
                    bankName={bank.bankName}
                    accName={bank.accountName}
                    accNo={bank.accountNumber}
                    link={`/wallet/banktransfer/${bank.bankName}/${bank.accountName}/${bank.accountNumber}`}
                />
                </div>
              })} */}
              
                 {/* <BankComp
                    bankName='Name of Bank PLC'
                    accName='Account name'
                    accNo='01911029292'
                /> */}
              </div>
            </div>
            
        </div>
      </Box>
    </Fade>
  </Modal></div>
  </>
  )
}

export default SelectBank