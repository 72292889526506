
import React from 'react'

function BuyDetails1({des, bathroom,date, type, toilet, srooms,references, street, locality, bedrooms}) {
  return (
    <div>
        <div className=' p-3 mb-7 border border-primary-color6 rounded-lg'>
                <p className=' text-[16px] mb-5 font-[700] text-back-color2'>Land description</p>
                <p className=' text-[16px] mt-3 font-[400] text-[#808285]'>{des}</p>
        </div>
        <div className=' p-3 mb-7 border border-primary-color6 rounded-lg'>
                <p className=' text-[16px] mb-5 font-[700] text-back-color2'>Land details</p>
                <div className=' flex justify-between  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Bathrooom: <span className=' text-back-color2 capitalize'>{bathroom}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Toilets:  <span className=' text-back-color2 capitalize'>{toilet}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Sitting Room:  <span className=' text-back-color2 capitalize'>{srooms}</span></p>      
                </div>
                <div className=' flex justify-between  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Bedrooms: <span className=' text-back-color2 capitalize'>{bedrooms}</span></p>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Locality: <span className=' text-back-color2 capitalize'>{locality}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Street: <span className=' text-back-color2 capitalize'>{street}</span></p>      
                </div>
                <div className=' flex justify-between  mb-5 gap-5'>
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Property Ref:  <span className=' text-back-color2 capitalize'>{references}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Added on: <span className=' text-back-color2 capitalize'> {date}</span></p> 
                <p className=' text-[16px] mb-2 font-[500] text-[#808285]'>Type:   <span className=' text-back-color2  capitalize'>{type}</span></p>      
                </div>
                </div>
    </div>
  )
}

export default BuyDetails1