import React from 'react'
import MainSection2 from '../../Components/Listing/MainSection2'

function PropertyListing() {
  return (
    <div>
        <MainSection2/>
    </div>
  )
}

export default PropertyListing