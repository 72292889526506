import React from 'react'
import PaymentMethod from '../Buy/PaymentMethod'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
//import BlackButton from '../SelectValue/BlackButton'
import Button from '../SelectValue/Button'
//import AddIcon from '@mui/icons-material/Add';
import {  useNavigate } from 'react-router-dom'
function CreateCard() {
       
        const navigate = useNavigate()
        
        const initialValues = { 
            amount: '',
    
          }
          const validationSchema = Yup.object({
            amount:Yup
           .string()
           .required('Required'),
          
                
          })
          const onSubmit = values => {
            console.log('Form data', values)
            console.log('Saved data', JSON.parse(JSON.stringify(values)))
            const {amount} = values
            
            sessionStorage.setItem('savingsAmount', amount)
            navigate('/save/plan/paymentsummary')
          }
  return (
    <div>
        <div className=' h-[60vh] md:mt-8'>
                
                <div className=' mt-4'>
                    <h1 className=' text-back-color2 text-[18px] mb-2 font-[500]'>How many units would you like to buy?</h1>
                    
                    
                </div>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
              {formik => (
                    <Form>
                    <div>
                    <p className=' my-2 text-[#1E222B]'>Or enter amount manually</p>
                    <FormikControl
                        control='input'
                        type='text'
                        label=''
                        name='amount'
                    />  
                </div>
              <div className=' my-4 '>
                <p className=' font-[500] mb-3 text-back-color2 text-[16px]'>Payment method</p>
                
                </div>
                <div className=' w-full'>
                    <PaymentMethod/>
                </div>
                {/* <div className=' mt-3 flex gap-3'>
                    <BlackButton
                        name='Choose other card'
                    />
                    <div>
                        <button className=' rounded-lg bg-[#FAFBFC] p-3 text-center text-primary-color'>
                        New card <span className=' pl-1'> <AddIcon/></span>
                        </button>
                    </div>
                </div>  */}
                <div className=' mt-16 mb-3 grid grid-cols-1'>
                <Button
                        name='Next'   
                    />
                     </div>  
                </Form>
                )}
            </Formik>
               
            </div>
    </div>
  )
}

export default CreateCard