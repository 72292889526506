import React from 'react'
import NewPasswordForm from '../../Components/Login/NewPasswordForm'
import NavbarBack from '../../Components/NavbarBack'

function NewPassword() {
  return (
    <div>
      <NavbarBack/>
      <NewPasswordForm/>
    </div>
  )
}

export default NewPassword