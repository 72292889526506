import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
import {  useNavigate } from 'react-router-dom'
// import Constant from '../../Constant'
function AdvanceSearch({setMin, setMax,}) {

    const navigate = useNavigate()
    const initialValues = { 
        min: '',
        max: '',

      }
      const dropdownOptions = [
        { key: 'Select an option', value: '' },
        { key: 'Option 1', value: 'option1' },
        { key: 'Option 2', value: 'option2' },
        { key: 'Option 3', value: 'option3' }
      ]
      const validationSchema = Yup.object({
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
      //  password: Yup.string()
      //  .required('Please Enter your password')
      //  .matches(
      //    Constant.passwordValidation,
      //    Constant.passwordValidationMessage
      //  ),
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        const{min, max} = values
        setMin(min)
        setMax(max)
        
        // navigate('/dashboard')
      }

  return (
    <div className=''>
        <div className=' p-3 border border-primary-color5 bg-[#F8FAFC] rounded-t-lg'>
        <div className=' flex justify-between'>
            <p className=' text-[14px] pt-2 text-back-color2'>Advanced Search option</p>
            
        </div>
    </div>
        <div className=' border-b border-x   border-b-primary-color5 rounded-b-lg p-4'>
         
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               {/* <FormikControl
                control='select'
                label='Location'
                name='email'
                options={dropdownOptions}
              />  
                 <FormikControl
                control='select'
                label='Category'
                name='email'
                options={dropdownOptions}
              />   */}
              <div className=' grid md:grid-cols-2 gap-3'>
              <FormikControl
                control='input'
                type = 'text'
                label='Min. Price'
                name='min'
              /> 
              <FormikControl
                control='input'
                type = 'text'
                label='Max. Price'
                name='max'
              /> 
              </div>
               
              
              <div className=' grid grid-cols-1 mb-5 mt-10'>
              <Button
                name='Search'
              />
              </div>
              
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default AdvanceSearch