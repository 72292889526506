import React from 'react'
import TransactionChart from '../Buy/TransactionChart'
import Progress from '../Home/Progress'
import Button from '../SelectValue/Button'
import Lock from '../../Assets/images/lock.png'
import { Link, NavLink, useParams } from 'react-router-dom'
function TargetDetails() {
    const {planid,cashout,name,enddate,startdate, per,target,status, amount} = useParams()
  return (
    <div>

    <div className=' flex mb-5'>
             <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 py-2 border rounded-[4px] px-6 ' + (
                              !isActive ? ' bg-[#F7F8FB] ' : ' border-gray-200  bg-white '
                            ))
                            }} to='details'>
                <p>Details</p>
            </NavLink>
            <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 py-2 border rounded-[4px] px-6 ' + (
                              !isActive ? ' bg-[#F7F8FB] ' : ' border-gray-200  bg-white '
                            ))
                            }} to={`/save/plan/${name}/transactions/${planid}/${status}/${amount}/${startdate}`}>
                <p>Transactions</p>
            </NavLink>
          </div>
        <p className=' text-primary-color6 mb-2'>Current balance</p>
        <p className=' text-back-color2 text-3xl mb-4 font-[500]'>NGN {cashout}</p>
        <p className=' text-primary-color6 mb-4'>{per === 'null' ? ' N/A' : per} % achieved of your NGN {target === "null" ? ' N/A' : target} target</p>
        <Progress
          value={per === 'null' ? 0 : per}
        />
        <div className=' mt-5 grid grid-cols-3 gap-4'>
        <Link to='/save/targetplanname/buytarget/card'>
        {/* <div className='   col-span-2'>
            <Button
                name='Add cash'
                
            />
        </div> */}
        </Link>
       
            
        </div>
        <div className=' mt-5'>
                <TransactionChart/>
            </div>
        <div className=' flex rounded-lg gap-3 border border-primary-color6 p-3 mt-8'>
            <div>
                <img src={Lock} alt='Lock'/>
            </div>
            <div>
                <p className=' text-back-color2 text-[16px] font-[500] mb-1'>Your plan is locked</p>
                <p className=' text-primary-color6'>Your plan is locked from withdrawal until {enddate.slice(0,10)}<br></br>
                 you funds will be sent to your wallet on maturity.</p>
            </div>
        </div>
    </div>
  )
}

export default TargetDetails