import React from 'react'
import { useParams } from 'react-router-dom'
import Send from '../../Assets/images/send.png'
function Payment() {
    const {ref, transtype} = useParams()
  return (
    <div className=' flex justify-center bg-signin-bg items-center h-screen '>
    <div>
        <div className='  bg-white p-10'>
               <img src={Send} className=' mx-auto' alt='send'/>
               <h1 className=' text-center font-bold my-3 text-3xl'>  Transaction completed successfully!!!</h1>
               <p> A transaction mail would be sent to you shortly.</p>
               <p> Thank you for using Zouse.</p> 
        </div>
    </div>
    </div>
  )
}

export default Payment