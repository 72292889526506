import React, {useEffect, useState} from 'react'
import Land from '../../Assets/images/tar1.png'
import Bank from '../../Assets/images/hs.png'
import ActiveInvestmentCom from '../Buy/ActiveInvestmentCom'
import TargetSavingDrawer from './TargetSavingDrawer'
import TargetRentDrawer from './TargetRentDrawer'
import getServices from '../../Services/get-service'
import { toast } from 'react-toastify'
function Savings() {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const [open1, setOpen1] = useState(false)
    const handleClose1 = () => setOpen1(false)
    const handleOpen1 = () => setOpen1(true)
    const [savingsRent,setSavingsRent] = useState(0)
    const [savingsTarget,setSavingsTarget] = useState(0)
    useEffect(() => {
      getServices.getWallerBalance().then(
        (response) => {
         
          if(response.data.status === "2000"){
           
            setSavingsRent(response.data.data.totalRentSavings)
            setSavingsTarget(response.data.data.totalTargetSavings)
          }
          else{
            toast('Session Expired', {
              onClose: () => {
                window.location.href = '/'
              },
              autoClose: 5000
            })
          }
          
         
          console.log(response.data.data)
          
        },
        (error) => {
          toast('Session Expired', {
            onClose: () => {
              window.location.href = '/'
            },
            autoClose: 5000
          })
        }
      )}, [])
  return (
    <div className=' my-6' >
       
        <TargetSavingDrawer
      open={open}
      handleClose={handleClose}
    />
    <TargetRentDrawer
      open={open1}
      handleClose={handleClose1}
    />
        <p className=' text-[14px] font-[500] text-[#808285] mb-2'>My savings</p>
        <div className='  grid md:grid-cols-2 gap-4'>
            <ActiveInvestmentCom
                img={Land}
                title='Target savings'
                sub={savingsTarget}
                onClick={handleOpen}
            />
             <ActiveInvestmentCom
                img={Bank}
                title='Rent savings'
                sub={savingsRent}
                onClick={handleOpen1}
            />
        </div>
    </div>
  )
}

export default Savings