import React from 'react'
import NavbarBack from '../../Components/NavbarBack'
import CreatePinForm from '../../Components/Signup/CreatePinForm'

function CreatePin() {
  return (
    <div>
      <NavbarBack/>
      <CreatePinForm/>
    </div>
  )
}

export default CreatePin