import React, {useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import postService from '../../Services/post-service'
import Logo from '../../Assets/images/Zlogo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../Components/SelectValue/Loading'
import FormikControl from '../../Components/SelectValue/FormikControl'
import Button from '../../Components/SelectValue/Button'
// import Constant from '../../Constant'
function DeleteForm() {
  const [loading, setLoading] = useState(false)
    const initialValues = { 
        email: '',
        password: '',

      }
      const validationSchema = Yup.object({
        
      //  email:Yup
      //  .string()
      //  .email()
      //  .required('Please Enter your Email'),
       password: Yup.string()
       .required('Please Enter your password')
     
            
      })
      const onSubmit = values => {
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        setLoading(true)
        const {email, password} = values
        postService.deAccount(email, password).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              toast.success('Account deactivation request sent')
             }else{
            
              toast.error(response.data.description,
                {
                  position: toast.POSITION.TOP_LEFT
                })
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error(error.message,
                {
                  position: toast.POSITION.TOP_LEFT
                })
              }
        )
        // navigate('/dashboard')
      }

  return (
    <div className=' '>
    <Loading
      open={loading}
    />
    <ToastContainer/>
     <img src={Logo} width='152' height='40' className=" mx-auto mb-5 "  alt=" logo" /> 
        <div className=' bg-white shadow-lg p-10 rounded-[16px]'>
       
            <p className=' mt-6 text-[25px] text-back-color2 mb-5 md:text-[40px] font-[700]'>Request to delete accout</p>
           <p className=' mt-6 text-[14px] text-back-color2 mb-5  font-[400]'>Enter the details below to confirm you are the account owner</p>
            <div className='  mt-5'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
        {formik => (
        <Form>
            
               <FormikControl
                control='input'
                label='Email address'
                name='email'
              />  
               
               <FormikControl
                control='password'
                label='Password'
                name='password'
              /> 
              
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                name='Login'
              />
              </div>
             
              
        </Form>
        )}
            </Formik>
            </div>
        </div>
    </div>
  )
}

export default DeleteForm