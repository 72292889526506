import React from 'react'
import MainSection from '../../Components/Listing/MainSection'

function Listing() {
  return (
    <div>
      <MainSection/>
    </div>
  )
}

export default Listing