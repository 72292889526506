import React, { useState } from 'react'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import House from '../../Assets/images/hh.png';
import Progress from '../Home/Progress';
import { Link } from 'react-router-dom';
import Constant from '../../Constant';
import { toast } from 'react-toastify';
import postService from '../../Services/post-service';
import Loading from '../SelectValue/Loading';

function LandCom({bool, onClick, link, name,img, state,per, percentage, totalAmount, raisedAmount, id, saveStatus}) {
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
          postService.saveProduct(id,'investment').then(
            (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
                toast.success("Investment Saved");
                // setTimeout(() => {
                //   window.location.reload()
                // }, 5000);
            
              }else{
                toast.error(response.data.description,
                  {
                      position: toast.POSITION.TOP_LEFT
                    }
                  );
             // setTimeout(() => {
               // window.location.reload()
               //}, 5000);
            
             
            }
             
            },
            (error) => {
              setLoading(false)
                 toast.error(error.message, {
                  position: toast.POSITION.TOP_LEFT
                });
                setTimeout(() => {
               window.location.reload()
               }, 5000);
              }
          )
        
        
      }
  return (
   <div>
       <Loading
            open={loading}
        />
   
    <div className=' p-2  grid grid-cols-5 gap-3  border border-gray-200 shadow rounded-lg '>
    <div className=' col-span-2'>
    <Link to={link}>
    <img src={img} height={50} width={50} className=' h-32 pt-3 w-full' alt='House'/>
    </Link>
    </div>
        <div className=' col-span-3 w-full'>
           
            <div className=' flex justify-between mt-3 mb-1'>
                <p className=' text-back-color2 font-[500] capitalize text-[16px]'>{name}</p>
                {!saveStatus &&
                  <div onClick={handleSubmit} className=' cursor-pointer'>
                <BookmarkBorderIcon className={bool ?'text-primary-color6' : 'text-[#00BC67]'}/>
                </div>
                }
                
               
            </div>
            <Link to={link}>
            <div className=' mb-3'>
                <PlaceOutlinedIcon onClick ={onClick} fontSize="small" className='text-primary-color6'/> <p className=' text-[#44474E] text-[14px] capitalize inline-block pl-1'>{state}</p>
            </div>
            <div>
                <p className=' text-[14px] pb-2'>{per}% sold</p>
                <Progress value={percentage}/>
                <div className=' mb-4 flex justify-between'>
                    <p className='text-primary-color6 text-[14px]'>Target</p>
                    <p className='text-primary-color6 text-[14px]'>Raised</p>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <p className=' text-[14px] text-start'>NGN {Constant.FormatNumber(totalAmount) }</p>
                    <p className='text-[14px] text-end text-primary-color'>NGN {Constant.FormatNumber(raisedAmount)}</p>
                </div>
            </div>
           </Link>

        </div>
    </div>
    </div>
  )
}

export default LandCom