import React from 'react'

function SavePlanCom({color, img, title, sub, onClick}) {
  return (
    <div onClick={onClick} className={` p-4  cursor-pointer border  rounded-lg ${color}`}>
    
    <div className=' pt-5'>
        <img src={img}  alt='cardimg' />
    </div>
    <div>
        <p className=' text-[#1E222B] text-[24px] font-[500] my-4'>{title}</p>
        <p className=' text-back-color2 font-[400] text-[18px] mb-3'>{sub}</p>
    </div>
</div>
  )
}

export default SavePlanCom