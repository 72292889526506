import React, {useEffect, useState} from 'react'
import Invest from '../../Assets/images/wa.png'
import Line from '../../Assets/images/Line.png'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import ButtonPlus from '../SelectValue/ButtonPlus';
import SelectBank from './SelectBank';
import Withdrawal from './Withdrawal';
import getServices from '../../Services/get-service';
import { ToastContainer, toast } from 'react-toastify';
import Constant from '../../Constant'
import 'react-toastify/dist/ReactToastify.css';

function Topsec() {
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleOpen1 = () => setOpen1(true)
    const handleClose1 = () => setOpen1(false)
    const[accBalance, setAccBalance] = useState(0)
    const [lB, setLB] = useState('0.0')
    useEffect(() => {
        getServices.getWallerBalance().then(
          (response) => {
           
            if(response.data.status === "2000"){
             
              setAccBalance(response.data.data.accountBalance)
              setLB(response.data.data.ledgerBalance)
            }else{
              toast('Session Expired', {
                onClose: () => {
                  window.location.href = '/'
                },
                autoClose: 5000
              })
            }
           
            console.log(response.data.data)
            
          },
          (error) => {
            toast('Session Expired', {
              onClose: () => {
                window.location.href = '/'
              },
              autoClose: 5000
            })
          }
        )}, [])
  return (

    <>
    <SelectBank
        open={open}
        handleClose={handleClose}
    />
    <Withdrawal
    open={open1}
        handleClose={handleClose1}
    />
    <ToastContainer/>
    <div className=' flex gap-4 my-4 rounded-lg p-4 border-gray-200 border'>

         <div className=' flex gap-3'>
            <div>
            <img src={Invest}  alt='Invest'/>
            </div>
            <div onClick={ () => setShow(!show)}>
                <p className=' text-back-color2 text-[14px] mb-1'>Wallet Balance <span className=' pl-2 text-[#808285]'>{show ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}</span></p>
                <p className=' text-2xl text-back-color2'>{show ? '*******' : `₦ ${Constant.FormatNumber(accBalance) }`}</p>
                <div className=' flex justify-between'>
            <p className=' text-back-color2 text-[14px] mb-1'>Ledger Balance :</p>
                    <p className=' text-[14px] text-back-color2'>{show ? '*******' : `₦ ${Constant.FormatNumber(lB) }`}</p>

            </div>
            </div>
           
        </div>
        <div className=' flex flex-col justify-center'>
            <img src={Line} alt='line'/>
        </div>
        <div className=' flex justify-center flex-col'>
        <ButtonPlus
            name='Fund wallet'
            onClick={handleOpen}
        />
        </div>
        <div className=' flex justify-center flex-col'>
        <div onClick={handleOpen1} className=' cursor-pointer p-3 px-4 rounded-lg pt-5 bg-[#FAFBFC]'>
            <p  className=' text-[16px] text-back-color2'>Withdraw <NorthEastOutlinedIcon/></p>
        </div>
        </div>
        
    </div>
    </>
  )
}

export default Topsec