import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../SelectValue/Button'

function SavingSummary() {
        const amount = sessionStorage.getItem('savingsAmount')
        const name = sessionStorage.getItem('savingsName')
        const fre = sessionStorage.getItem('savingsFre')
        const startDate = sessionStorage.getItem('startSate')
        const endDate = sessionStorage.getItem('savingEndDate')
        const duration = sessionStorage.getItem('savingDuration')

  return (
    <div>
         <div className='h-[60vh] mt-4'>
                    <h1 className=' text-back-color2 text-[18px] mb-2 font-[500]'>Summary</h1>
                    <div className=' my-4 flex justify-between'>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-1'>GOAL NAME</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] '>{name}</p>
                        </div>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-1'>TARGET AMOUNT</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] text-end'>NGN {amount}</p>
                        </div>
                    </div>
                    <div className=' my-4 flex justify-between'>
                        <div>
                            <p className=' text-[14px] text-primary-color6'>FREQUENCY</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] capitalize'>{fre}</p>
                        </div>
                        <div>
                            <p className=' text-[14px] text-primary-color mb-2'>PAYMENT METHOD</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500]  text-end'>Card </p>
                            {/* <span className=' p-1 text-white  text-[14px] rounded-[32px] bg-[#F7941D]'>Change</span> */}
                        </div>
                    </div>
                    <div className=' my-4 flex justify-between'>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-2'>START DATE</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] text-start '>{startDate.slice(0,15)}</p>
                        </div>
                        <div>
                            <p className=' text-[14px] text-primary-color6 mb-2'>END DATE</p>
                            <p className=' text-back-color2 md:text-[18px] font-[500] text-start '>{duration === '90' ? '3 month' :duration === '180' ? '6 months' : duration === '270'? ' 9 months': ' 1 year'}</p>
                        </div>
                    </div>
                    <Link to='/save/plan/payment'>
                        <div className=' grid grid-cols-1 mt-16'>
                            <Button
                                name='Confirm'
                            />
                        </div>
                    </Link>
                </div>
    </div>
  )
}

export default SavingSummary