import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Paid from '../../Assets/images/paid.png'
import Button from '../SelectValue/Button';
import { Link } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
   
    boxShadow: 24,
   
  };
  
function RequestModal({ open, handleClose}) {
  return (
    <div>
          <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' flex justify-center  '>
            <div>
            <img src={Paid} alt='Send' className=' mx-auto' />
            <div className=' mt-3'>
                <p className=' font-[500] text-[20px] text-back-color2 text-center mb-2'>Request sent to join #45436</p>
                <p className=' font-[500] text-[16px] text-[#808285] text-center mb-2'>You wil get an invite to select your apartment shortly. Thank you</p>
            <div className=' flex justify-center'>
            
            <Button
             name='See pending rent'
             onClick={handleClose}
                />
            </div>
               
            </div>
            </div>
             
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}

export default RequestModal