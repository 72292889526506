import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import TargetSuccess from './TargetSuccess';
function TargetPin() {
    const navigator = useNavigate()
    const [pin, setPin] =useState('')
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    // const onSubmit = (e) =>{
    //     e.preventDefault()
    //         navigator('/confirmpassword')
    //     console.log(pin)
    // }
  return (
    <div>
    <TargetSuccess
        open={open}
        handleClose={handleClose}
    />
        <div className='grid h-[40vh]  place-items-center'>
    <div >
        <p className=' text-[18px] text-back-color2 mb-5  font-[500]'>Enter your Zouse PIN</p>
       <p className=' mb-3 text-[#44474E]'>Enter your 4-Digit PIN to confirm transaction</p>
        <div className='  mt-5'>

        <div >
        <form > 
        <div className=' w-full flex justify-center'>
    
                <PinInput 
                length={4}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
        </div>
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Confirm'
            onClick={handleOpen}
          />
        </div>
       
        </form>
         
          </div>
      
        </div>
    </div>
</div>
    </div>
    
  )
}

export default TargetPin