import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
function ResetPinOtp() {
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const onSubmit = (e) =>{
        e.preventDefault()
        localStorage.setItem('optreset', otp)
            navigate('/settings/resetpin/pin')
    }
  return (
    <div>
        <div className=' mt-10 '>
            
            <p className=' mb-3 text-back-color2'>Reset your Zouse PIN</p>
            <p className=' mb-3 text-[#808285]'>Enter your 6-Digit OTP  sent to your mail</p>
              <div className='  mt-5'>

              <div >
              <form onSubmit={onSubmit}> 
              <div className=' w-full flex justify-center'>
                      <div className=' my-3'>
                      <PinInput 
                      length={6}
                      initialValue={otp}
                      secret  
                      onChange={(value, index) => {
                          setOtp(value)
                      }} 
                      type="numeric" 
                      inputMode="number"
                      style={{padding: '8px', }}  
                      inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                      inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                      onComplete={(value, index) => {
                          otp(value)
                          }}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      </div>
                      
              </div>

              
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                  name='Next'
                
                />
              </div>
            
              </form>
              
                </div>
            
              </div>
          </div>
    </div>
  )
}

export default ResetPinOtp