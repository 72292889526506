import React from 'react'
import Notification from '../../Assets/images/notii.svg'
function Noticom() {
  return (
    <div className=' py-3 grid-cols-6 grid gap-4 border-b border-back-color2'>
        <div>
            <img src={Notification} alt='Notification'/>
        </div>
        <div className=' col-span-4 '>
            <p className=' font-[500] text-[16px]'>New property listed by name of devloper</p>
        </div>
        <div>
        <p className=' font-[500] text-[16px]'>2 days ago</p>
        </div>
    </div>
  )
}

export default Noticom