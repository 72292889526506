import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import RentalList from './RentalList'
function Content() {
  return (
    <div>
         <div className=' grid gap-4 md:grid-cols-2'>
            <div className=' border rounded border-gray-200 p-3 '>
                <div className=' bg-rental-bg p-2'>
                        <h1 className=' font-[900] text-white text-[20px]'>Rent Payment Solutions to Ease Your Mind!</h1>
                        <p className=' text-white font-[400] text-[16px] rounded'>Experience peace of mind while enjoying your new home. Explore our solutions today!"</p>
                </div>
                <div>
                    <RentalList/>
                </div>
            </div>
            <div className=' border rounded border-gray-200 p-3 '>
            <div className=' grid grid-cols-2 mb-5'>
             <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 py-2 border rounded-[4px] px-6 ' + (
                              !isActive ? ' bg-[#F7F8FB] ' : ' border-gray-200  bg-white '
                            ))
                            }} to='active'>
                <p>Active rent</p>
            </NavLink>
            <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 py-2 border rounded-[4px] px-6 ' + (
                              !isActive ? ' bg-[#F7F8FB] ' : ' border-gray-200  bg-white '
                            ))
                            }} to='pending'>
                <p>Pending rent</p>
            </NavLink>
          </div>
          <div className=''>
                        <Outlet/>
          </div>
            </div>
        </div>
    </div>
  )
}

export default Content