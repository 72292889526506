
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from '@mui/material';
import Noticom from './Noticom';
import { useEffect, useState } from 'react';
import getServices from '../../Services/get-service';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};
function Notification({open, handleClose}) {
    const [noti, setNotification] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() =>{
        setLoading(true)
        getServices.notification().then(
            (response) => {
              setNotification(response.data.data.reverse());
              console.log(response.data.data)
              setLoading(false)
            },
            (error) => {
              
            }
          )
    }, [])
  return (
    <div>
<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
          <div className=' h-[60vh] overflow-y-hidden  md:mt-5'>
                <div className=' mt-6 flex justify-between mb-3'>
                <div>
                    <h1 className=' font-[500] text-[20px] text-back-color2'>Notifications</h1>
                </div>
                <div>
                 <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div> 
                   
                </div>
                <Divider/>
                    <div className=' pt-5'>
                    {noti.map(( notii, index) =>{
                        return <div key={index}>
                        <Noticom/>
                        </div>
                    })}
                    {!loading && noti.length === 0 &&
                    <div>
                        <p className=' text-center text-red-700 text-xl'>No Notification yet</p>
                    </div>
                    }
                    </div>
          </div>
          </Box>
          </Fade>
      </Modal>
    </div>
  )
}

export default Notification