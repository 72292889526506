import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function HourRadio({value, handleChange}) {
  

  return (
   
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => handleChange(e.target.value) }
      >
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>3 months</p>
      </div>
     
      <FormControlLabel value='90'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
    
  }}/>}  />
      </div>
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>6 months</p>
      </div>
     
      <FormControlLabel value='180'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>9 months</p>
      </div>
     
      <FormControlLabel value='270'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
      <div className=' flex mb-4  justify-between gap-3'>
      <div className=' pt-2'>
      <p className=' text-back-color2 text-[16px]'>1 year</p>
      </div>
     
      <FormControlLabel value='365'  control={<Radio  required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}  />
      </div>
      </RadioGroup>
   
  );
}