import { TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Success from '../../Assets/images/success.png'
import Red from '../../Assets/images/successf.png'
import getServices from '../../Services/get-service'
import TransactionSkeleton from '../SkeletonComponent/TransactionSke'
import TranCom from './TranCom'

function DetailsTransaction() {
  const [investment, setInvestment] = useState([])
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [page, setPage] = useState(0)
  const elements = Array(10).fill(null); 
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  const {id} = useParams()
     useEffect(() =>{
      setLoading(true)
       getServices.investmentHistory(id).then(
         (response) => {
          setLoading(false)
          if(response.data.status === '2000'){
            setInvestment(response.data.data.reverse());
            console.log(response.data.data)
          }else if (response.data.status === '4000') {
            window.location.href = '/';
          }
           
         },
         (error) => {
          setLoading(false)
         }
       )
     }, [id])
  return (

    <div>
        <div className=' mt-4'>
        {loading && <>
          {elements.map((_, index) => (
            <TransactionSkeleton key={index}/>
       
      ))}
          

         </>}
        {investment && investment.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invest, i) =>{
          return <div key={i}>
          <TranCom
                img={Success}
                header={`${invest.totalAmount}`}
                sub={invest.status}
                date={invest.startDate.slice(0,10)}
            />
          </div>
        })}
           
            {/* <TranCom
                img={Red}
                header='+NGN500.00'
                sub='Success'
                date='Sep 4, 2021'
            /> */}
            <p className=' text-red-700  text-center'>{investment.length === 0 && !loading && 'No transaction Fund'}</p>
            <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={investment.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </div>
    </div>
  )
}

export default DetailsTransaction