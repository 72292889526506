import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Bank from '../../Assets/images/send.png'
import { Link } from 'react-router-dom';
import Button from '../SelectValue/Button';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};


function WalletSuccess({ open, handleClose, con, header}) {
  return (
    <div>  
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <Box sx={style} className=' rounded-lg p-6 '> 
        <div className='  '>
            <div className=''>
              
            
              <div className=' grid h-[60vh] place-items-center'>
              <div>
               <img src={Bank} className=' mx-auto' alt='Bank'/>
                <p className=' text-center text-[18px] mb-3 font-[500] text-back-color2'>{header}</p>
                <p className=' text-center text-[16px] mb-3 font-[500] text-[#808285]'>{con}</p>
                <div className=' mt-7'>
                
                <div className=' grid grid-cols-1 '>
                <Button
                        name='Done' 
                        onClick={handleClose}
                    />
                </div>
                </div>
              </div>
              
              </div>
             
            </div>
            
        </div>
      </Box>
    </Fade>
  </Modal></div>
  )
}

export default WalletSuccess