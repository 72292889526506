import React, { useState } from 'react'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ChangePassword from './ChangePassword';
import { Link } from 'react-router-dom';
import BVN from './BVN';
function Security() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleOpen1 = () => setOpen1(true)
    const handleClose1 = () => setOpen1(false)
    let accountSta = localStorage.getItem('accountStatuss')
  return (
    <div>
    <ChangePassword
        open={open}
        handleClose={handleClose}
    />
   <BVN
     open={open1}
     handleClose={handleClose1}
   />
        <p className=' text-[#44474E] border-b-2 border-b-gray-200 pb-3 font-[500] mb-10 text-[16px] md:text-2xl'>Verification Details</p>
       
        <div className=' md:flex gap-10'>
        <div>
        <p className=' text-back-color2  text-2xl font-[200]'>BVN verification</p>
            <p className=' text-back-color2  text-[18px] font-[400]'>******</p>
        </div>
           <div>
           {accountSta  === "true"  ?
           <p className=' rounded-[32px] inline-block text-back-color2 p-2 bg-[#CCF2E1]'>Verified</p>
           : <p className=' rounded-[32px] inline-block text-back-color2 p-2 bg-[#FFDEDE]'>Not Verified</p>}
           </div>
          
            <div>
          
            <p onClick={handleOpen1} className=' cursor-pointer text-primary-color mt-1 border-b pb-1 border-b-primary-color'>Apply for verification</p>
           
            </div>
           
        </div>
        {/* <div className=' mt-10 flex gap-10'>
            <p className=' text-back-color2 text-2xl font-[200]'>Identity document</p>
>>>>>>> master
            <p className=' text-back-color2 text-[18px] font-[400]'>National identification number(NIN)</p>
            <p className=' rounded-[32px] text-back-color2 p-2 bg-[#FFDEDE]'>Verified</p>
            <div>
            <p className=' text-primary-color mt-1 border-b pb-1 border-b-primary-color'>Apply for verification</p>
            </div>

        </div> */}
        <p className=' text-[#44474E] border-b-2 border-b-gray-200 pb-3 mt-24 mb-7 text-2xl'>Password & Pin</p>
        <div className=' my-4 grid  grid-cols-2 md:grid-cols-5 gap-8'>
            <div className=' col-span-2'>
                <p className=' text-back-color2 font-[200] mb-2 text-2xl'>Update Password</p>
                <p className=' text-back-color2 font-[400] text-[18px]'>Change your old password to a new one</p>
            </div>
            <div>
                <p className=' text-primary-color font-[500] text-[16px] cursor-pointer' onClick={handleOpen}>Change password <BorderColorOutlinedIcon className=' text-primary-color'/></p>
            </div>
        </div>
        <div className=' my-4 grid grid-cols-2 md:grid-cols-5 gap-8'>
            <div className=' md:col-span-2'>
                <p className=' text-back-color2 font-[200] mb-2 text-2xl'>Update Pin</p>

                <p className=' text-back-color2 font-[400] text-[18px]'>Change or reset your Zouse pin</p>
            </div>
            <div>
                <p className=' text-primary-color font-[500] text-[16px]'  > <Link to='changepin/current'>Change pin <BorderColorOutlinedIcon className=' text-primary-color'/></Link> </p>
            </div>
        </div>

    </div>
  )
}

export default Security