import React from 'react'
import AwaitInvite from './AwaitInvite'
import InviteReceived from './InviteReceived'

function Pendingrent() {
  return (
    <div>
        <InviteReceived/>
        <AwaitInvite/>
    </div>
  )
}

export default Pendingrent