import React from 'react'
import House from '../../Assets/images/apa1.png'
import StarRateIcon from '@mui/icons-material/StarRate';
function Allapartmentcom() {
  return (
    <div className=' my-4 border border-gray-300 rounded-lg flex gap-3'>
        <img src={House} alt='House'/>
        <div className=' p-3'>
        <div className=' mb-3 flex justify-between'>
        
        <p className=' font-[500] text-[14px] '>4-Bed apartment in Lekki</p>
        <p><StarRateIcon className=' text-primary-color'/> 4.0 (26)</p>
    </div>
    <p className='font-[400] mb-2 text-[14px] '>Located in the Eixample neighborhood, the house has authentic modernist architecture, a symbol of Barcelona. </p>
    <p className=' text-back-color2 mb-4 text-[14px] font-[700]'> NGN250,000 <span className=' text-[##808285]'>/year</span>  <span className=' text-primary-color'>Available</span></p>
    <div >
        <button  className=' p-2 border border-gray-200 text-primary-color rounded'>
        See apartment class
        </button>
    </div>
        </div>
        
    </div>
  )
}

export default Allapartmentcom