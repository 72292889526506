import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import Wallet from '../../Assets/images/w.png'
import Card from '../../Assets/images/c1.png'
import Master from '../../Assets/images/master.png'
export default function PaymentMethod() {
  const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <> 
   
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        className=' w-full'
        onChange={handleChange}
      >
      {/* <div className=' w-full rounded-lg border p-2 mb-3 border-[#E6E6E7] flex justify-between'>
        <div className=' flex gap-3'>
            <div>
            <img src={Wallet} alt='Wallet'/>
            </div>
            
            <p className=' text-back-color2 md:text-[16px] pt-2'>Wallet</p>
        </div>
        <div className=' flex gap-3'>
        <p className=' text-back-color2 font-[700] md:text-[16px] pt-2'>NGN 50,000.89</p>
        <FormControlLabel value="female" control={<Radio />}  />
        </div>
       
      </div> */}
      <div className=' w-full rounded-lg border p-2 border-[#E6E6E7] flex justify-between'>
        <div className=' flex gap-3'>
            <div>
            <img src={Card} alt='Card'/>
            </div>
            
            <p className=' text-back-color2 pt-2 md:text-[16px]'>Card</p>
        </div>
        <div className=' flex gap-3'>
        <p className=' text-back-color2 font-[700] md:text-[16px] pt-2'>Paystack</p>
        <div>
        <img src={Master} className=' pt-2' alt='Wallet'/>
        </div>
       
        <FormControlLabel value="male" control={<Radio sx={{
    '&, &.Mui-checked': {
      color: '#00BC67',
    },
  }}/>}   />
        </div>
       
      </div>
        
        
      </RadioGroup>
    
    </>
  );
}