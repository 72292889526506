import React from 'react'
import NavbarBack from '../../Components/NavbarBack'
import ConfirmPinForm from '../../Components/Signup/ConfirmPinForm'

function ConfirmPassword() {
  return (
    <div>
      <NavbarBack/>
      <ConfirmPinForm/>
    </div>
  )
}

export default ConfirmPassword