
import React, {useEffect, useState} from 'react'
import Button from '../SelectValue/Button'
import postService from '../../Services/post-service'
import Pin from './Pin'
import SelectBank from '../BankCard/SelectBank'
import getServices from '../../Services/get-service'
import TextBox from '../SelectValue/TextBox'
import SelectD from './SelectD'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function NextofKim() {
  const [loading, setLoading] = useState(false)
  const [pin, setPin] = useState('')
  const [bank, setBank] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [nextKinDetails, setNextKinDetails] = useState([])
  const [accNo, setAccNo] = useState('')
  const [fullName, setFullName] = useState('')
  const [pNo, setPNo] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [nextKin, setNextKin] = useState('')
  const [sex, setSex] = useState('')
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  
  const dropdownOptions = [
    { key: 'Select an option', value: '' },
    { key: 'Male', value: 'male' },
    { key: 'Female', value: 'female' },
    
  ]
  
  const dropdownChild = [
    { key: 'Select an option', value: '' },
    { key: 'Spouse', value: 'spouse' },
    { key: 'Parent', value: 'parent' },
    { key: 'Children', value: 'children' },
    { key: 'Others', value: 'others' },
  ]
  useEffect(() =>{
      getServices.getNextOfKin().then(
        (response) => {
          setLoading(false)
          if(response.data.status === "2000"){
            
            setNextKinDetails(response.data.data);
            
              setAccNo(nextKinDetails[0].nextOfKinBankAccountNumber)
              setSex(nextKinDetails[0].nextOfKinGender)
              setAddress(nextKinDetails[0].nextOfKinContactAddress)
              setFullName(nextKinDetails[0].nextOfKinFullname)
              setPNo(nextKinDetails[0].nextOfKinPhoneNumber)
              setNextKin(nextKinDetails[0].nextOfKinRelationship)
              setEmail(nextKinDetails[0].nextOfKinEmail)
              setBank(nextKinDetails[0].nextOfKinBankName)
            
           
            
          }else{
            setNextKinDetails('')
          }
         
          console.log(response.data.data)
          
        },
        (error) => {
          setLoading(false)
        }
      )
  }, [nextKinDetails])

      const handleSubmit = (e) =>{
        e.preventDefault()
        setOpen(true)

      }

      const finalSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
        if(pin.length === 4){
          setLoading(true)
           postService.nextOfKins(address,accNo,bank,email,fullName,sex,pNo,nextKin,pin).then(
             (response) => {
                 console.log(response.data)
                 setLoading(false)
                if(response.data.status === '2000'){
                  toast.success(response.data.description);
                  setTimeout(() => {
                    window.location.reload()
                  }, 5000);
                 
                }else{
                  toast.error(response.data.description);
                  setTimeout(() => {
                    window.location.reload()
                  }, 5000);
                
                }
                
               },
               (error) => {
                 setLoading(false)
                   return  toast.error(error.message);
                 }
           )
         }else{
          setLoading(false)
          toast.error('Pin most be 4-Digit');
         
         }
      }
     
  return (
    <div className=''>
    
    <Pin
      open={open}
      handleClose={handleClose}
      pin={pin}
      setPin= {setPin}
     onSubmit={finalSubmit}
     loading={loading}
    />
        <div className=' '>
        <ToastContainer/>
            <form onSubmit={handleSubmit}>
            <div className='  mt-5'>
         
        
        <p className=' text-[#44474E] text-2xl mb-7 font-[400]'>Bank Information</p>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
            <div className=' flex flex-col justify-center'>
            <p className=' text-2xl font-[500] text-back-color2'>Account number</p>
            </div>
               <div className=' col-span-2'>
               <TextBox
                type='text'
                label='Account number'
               value={accNo}
               onChange={setAccNo}
              />
               </div>
                 
               
               
            </div>
            <div className='mb-7 mt-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Bank name</p>
                <div className=' col-span-2'>
               <SelectBank
                bName={setBank}
                value={bankCode}
                onChange={setBankCode}
               />
                </div>
                
            </div>
            <p className=' text-[#44474E] text-2xl mb-7 font-[400]'>Next of Kin Personal Information</p>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
            <div className=' flex flex-col justify-center'>
            <p className=' text-2xl font-[500] text-back-color2'>Full name</p>
            </div>

            <div className=' col-span-2'>
                <TextBox
                type='text'
                label='First name'
                value={fullName}
                onChange={setFullName}
              />  
            </div>  
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Phone number</p>
                <div className=' col-span-2'>
                <TextBox
                type='tel'
                label='Phone number'
                value={pNo}
                onChange={setPNo}
              />  
                </div>
                
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Email</p>
                <div className=' col-span-2'>
                <TextBox
                type='email'
                label='Email'
                value={email}
                onChange={setEmail}
              />  
                </div>
                
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Home Address</p>
                <div className=' col-span-2'>
                <TextBox
                type='text'
                label='Address '
                value={address}
                onChange={setAddress}
              />  
                </div>
                
            </div>
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Gender</p>
                <div className=' col-span-2'>
                <SelectD
                label='Select gender'
                value={sex}
                onChange={setSex}
                options={dropdownOptions}
              />  
                </div>
                
            </div>
            
            <div className=' my-4 md:grid grid-cols-4 gap-5'>
                <p className=' text-2xl font-[500] text-back-color2'>Relationship</p>
                <div className=' col-span-2'>
                <SelectD
                label='Relationship'
                value={nextKin}
                onChange={setNextKin}
                options={dropdownChild}
              />  
                </div>
                </div>
              <div className=' md:grid grid-cols-4 gap-5 mb-5 mt-6'>
              <div></div>
              <div className=' col-span-2'>
              <Button
                name='Save changes'
                bool={true}
              />
              </div>
             
              </div>
      
            </div>
            </form>
        </div>
    </div>
  )
}

export default NextofKim