import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {useEffect, useState} from 'react'
import {  Outlet, useNavigate, useParams } from 'react-router-dom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Bg from '../../Assets/images/pimg.png'
//import Tb from '../../Assets/images/bg1.png'
import Progress from '../Home/Progress';
import Button from '../SelectValue/Button';
import Section from './Section';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import getServices from '../../Services/get-service';
// import { toast } from 'react-toastify';
// import { format, parseISO } from 'date-fns';
import BuyUnits from './Buyunit';
import Loading from '../SelectValue/Loading';
import ImageSlider from './ImageSlider';
import Constant from '../../Constant';
const drawerWidth = 750;

function ProductDetails() {
    const navigate = useNavigate();
    const handleBack = () => navigate(-1)
    const [projectDetail, setProjectDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [image, setImages ] = useState([])
    const {id, status} = useParams()
    useEffect(() =>{
      setLoading(true)
            getServices.getSingleInvestProject(id, status).then(
        
                (response) => {
                    setLoading(false)
                //   if(response.data.status === "2000"){
                //     setProjectDetails(response.data.data)
                //   }else{
                // //    swal(response.data.description)
                //    toast('Session Expired', {
                //     onClose: () => {
                       
                //     },
                //     autoClose: 5000
                //   })
                //   }
                setProjectDetails(response.data.data[0])
                setImages(response.data.data[0].imageUrl)
                console.log('sss' + image)
                  console.log(response.data.data)
                  
                },
                (error) => {
                    setLoading(false)
                    // toast('Session Expired', {
                    //     onClose: () => {
                    //       window.location.href = '/'
                    //     },
                    //     autoClose: 5000
                    //   })
                }
              )
        }, [id])
        const videoId = Constant.getYouTubeVideoId(projectDetail.videoLink?? '');
  return (
    <div>
        <Outlet/>
        <BuyUnits
          open={open}
          handleClose= {handleClose}
          number= {projectDetail.unitAvailable}
          amount= {projectDetail.pricePerUnit}
          id={projectDetail.projectId}
         
        />
        <Drawer
          anchor='right'
          variant="temporary"
          open={true}
          
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
        <Loading
          open={loading}
        />
       <div>
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
          <div className=' flex gap-5'>
          <div className=' pt-3'>
          <KeyboardBackspaceIcon onClick={handleBack} className=' cursor-pointer'/>
          </div>
           
            <p className=' text-back-color2 text-[18px] pt-3'>Project details</p>
          </div>
            
            <div className='pt-2'>
            <CancelIcon onClick={handleBack} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
            </div>  
        </div>
      </div>
      <div className='p-4 gap-3  grid md:grid-cols-2 mt-3'>
            <div className=' mt-2'>
           {/* {projectDetail.projectId} */}
               {/* <div>
                <img src={projectDetail.imageUrl ??Bg} height={450} width={450} alt='Bg'/>
               </div>  */}
               <ImageSlider imageList={image}/>
               {/* <div className='mt-3 grid grid-cols-4 gap-4'>
               <img src={Tb} alt='Bg'/>
               <img src={Tb} alt='Bg'/>
               <img src={Tb} alt='Bg'/>
               <img src={Tb} alt='Bg'/>
               </div> */}
            </div>
            <div className=' px-2'>
            <div className=' flex justify-between  mb-1'>
                <p className=' text-back-color2 font-[500] capitalize text-[16px] '>{projectDetail.projectName}</p>
                <div className=' pt-1'>
                <BookmarkBorderIcon className='text-primary-color6'/>
                </div>
               
            </div>
            <div className=' mb-3'>
                <PlaceOutlinedIcon  fontSize="small" className=' text-primary-color'/> <p className=' text-[#44474E] text-[14px] capitalize inline-block pl-1'>{projectDetail.projecLocation}</p>
            </div>
            <p className=' rounded-[32px] bg-[#F5F7FA] text-[16px] p-3 text-[#1E222B] mb-3' >Investment time period : {projectDetail.projectDuration}days</p>
            <div className=' flex gap-3'>
            <p className=' rounded-[32px] text-xs bg-[#CCF2E1] p-3 text-[#1E222B] mb-3' >NGN {Constant.FormatNumber(projectDetail.pricePerUnit) } </p>
            <p className=' rounded-[32px] text-xs bg-[#FFDEDE] p-3 text-[#1E222B] mb-3' >{projectDetail.unitAvailable} Units remaining</p>
           
            </div>
            <p className='text-[#1E222B] mb-3 capitalize font-[500]'>By: {projectDetail.projectName}</p>
            <div>
                <p className=' text-[14px] pb-2'>{Math.floor(projectDetail.percentSold)}% sold</p>
                <Progress value={projectDetail.percentSold}/>
                <div className=' mb-4 flex justify-between'>
                    <p className='text-primary-color6 text-[14px]'>Target</p>
                    <p className='text-primary-color6 text-[14px]'>Raised</p>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <p className=' text-[14px]'>NGN { Constant.FormatNumber(projectDetail.pricePerUnit * projectDetail.unitSold)}</p>
                    <p className='text-[14px] text-primary-color'>NGN {Constant.FormatNumber(projectDetail.projectPriceTotal) }</p>
                </div>
            </div>
            {projectDetail.unitAvailable === 0 ?
              <div className=' grid grid-cols-1'>
               <div className=' p-3 text-[#b00020] bg-[#efccd2] text-center rounded-lg'>
               Sold out
               </div>
           </div>
             :
              <div className=' grid grid-cols-1'>
               
                <Button
                    name='Buy units'
                   onClick={handleOpen}
                />
            </div>
            }
          
            </div>
      </div>
            <div className=' p-2 '>
                <Section
                  date={projectDetail.dateCreated}
                  cArea= '222sqr'
                  des={projectDetail.projectDescription}
                  refs={projectDetail.projectId}
                  statuss={projectDetail.projectStatus === 'pending' ? 'Available' : ' Unavaliable'}
                  type={projectDetail.projectType}
                  videolink={projectDetail.videoUrl}
                  tA='238sqr'
                />
            </div>
        </Drawer>
    </div>
  )
}

export default ProductDetails