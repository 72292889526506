import React from 'react'
import NavbarSignin from '../../Components/NavbarSignin'
import SignupForm from '../../Components/Signup/SignupForm'
import Mobile from '../../Components/Mobile'

function Signup() {
  return (
    <>
     <div className=' block md:hidden'>
      <Mobile/>
    </div>
    <div className=' hidden md:block'>
      <NavbarSignin/>
      <SignupForm/>
    </div>
    </>
  )
}

export default Signup