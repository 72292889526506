import React, {useEffect, useState} from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Progress from '../Home/Progress';
import TransactionChart from './TransactionChart';
import BuyDetaill from './BuyDetiall';
import { NavLink, useParams } from 'react-router-dom';
import getServices from '../../Services/get-service';
import { toast } from 'react-toastify';
import Loading from '../SelectValue/Loading';
import Button from '../SelectValue/Button';
import LiquidateInvestment from './LiquidateInvestment';
function ProjectDetailsCom() {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const elements = Array(10).fill(null); 
     const handleOpen = () => setOpen(true)
    const [projectDetail, setProjectDetails] = useState({})
    // const {cashh, setCashh} = useState(0)
    const {id,unit,dur,cashout,pid,name,amount,perBought,endDate,purchaseid, existStatus} = useParams()
    const handleClose = () => setOpen(false)

    // useEffect(() =>{
    //     setCashh(cashout)
    // }, [cashout])
    
    useEffect(() =>{
      setLoading(true)
        getServices.getSingleInvestProject(pid).then(
    
            (response) => {
                setLoading(false)
            //   if(response.data.status === "2000"){
            //     setProjectDetails(response.data.data)
            //   }else{
            // //    swal(response.data.description)
            //    toast('Session Expired', {
            //     onClose: () => {
            //         window.location.href = '/'
            //     },
            //     autoClose: 5000
            //   })
            //   }
            setProjectDetails(response.data.data[0])
              console.log(response.data.data)
              
            },
            (error) => {
                setLoading(false)
                toast('Session Expired', {
                    onClose: () => {
                      window.location.href = '/'
                    },
                    autoClose: 5000
                  })
            }
          )
    }, [pid])
  return (
    <div>
       <LiquidateInvestment
         open={open}
         handleClose= {handleClose}
         id={purchaseid}
       />
       <div className=' flex '>
             {/* <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 py-2 border rounded-[4px] px-6 ' + (
                              !isActive ? ' bg-[#F7F8FB] ' : ' border-gray-200  bg-white '
                            ))
                            }} to='details'> */}
                <p className='border-gray-200 border rounded-[4px] px-6 py-2 cursor-pointer bg-white'>Details</p>
            {/* </NavLink> */}
            <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 py-2 border rounded-[4px] px-6 ' + (
                              !isActive ? ' bg-[#F7F8FB] ' : ' border-gray-200  bg-white '
                            ))
                            }} to={`/buy/projectdetails/transactions/${pid}`}>
                <p>Transactions</p>
            </NavLink>
          </div>
          <Loading
            open={loading}
          />
       <div className=' my-3 flex justify-between'>
        <div>
            <p className=' text-[#808285] mb-3 text-[14px]'>Current balance</p>
            <p className=' text-[#002615] text-[32px] font-[500]'>NGN {cashout}</p>
            {/* <p className=' text-red-600'><CallReceivedIcon/><span className=' pl-1'>0.01%</span></p> */}
        </div>
        <div className=' flex flex-col justify-center'>
           <p className=' text-[16px] font-[500] text-[#808285]'><span className=' text-[32px]'>{unit}</span>units</p> 
        </div>
        
       </div>
       {/* <div className=' grid grid-cols-3 my-3'>
            <Button
                name='Buy more units'
                onClick={handleOpen}
                
            />
            <div className=' px-6 py-3 bg-[#FAFBFC]'>
                <p className=' text-back-color2 text-center'>Sell units</p>
            </div>
        </div> */}
        <div className=' my-4'>
            <p className=' text-back-color2 text-[16px] font-[500] capitalize'>{name}</p>
            <p className=' text-[14px] font-[400] text-[#808285]'><PlaceOutlinedIcon className=' text-primary-color'/> <span className=' pl-1'>Lagos, Ikeja</span></p>
        </div>
        <div className=' flex gap-3'>
            <p className=' p-3 rounded-[32px] text-[16px] text-back-color2 bg-[#CCF2E1]'>NGN {projectDetail.pricePerUnit} unit</p>
            <p className=' p-3 px-4 rounded-[32px] text-[16px] text-back-color2 bg-[#FFDEDE]'>{projectDetail.unitAvailable} units remaining</p>
        </div>
    
        {existStatus === 'true'  ?
        <div className='my-4 grid grid-cols-1'>
               
               <Button
                   name='Liquidate Investment'
                 onClick={handleOpen}
               />
           </div>
             :
            <div className=' grid my-4 grid-cols-1'>
               <div className=' p-3 text-[#b00020] bg-[#efccd2] text-center rounded-lg'>
               Liquidate Investment
               </div>
           </div>
            }
        <div className=' my-3'>
            <p className=' text-[16px] font-[500] mb-2'>By: {projectDetail.projectName}</p>
            <div className=' bg-[#F8FAFC] p-3'>
            <div>
                <p className=' pb-2'>{Math.ceil(projectDetail.percentSold)}% sold</p>
                <Progress value={projectDetail.percentSold}/>
                <div className=' mb-4 mt-2 flex justify-between'>
                    <p className='text-primary-color6'>Target</p>
                    <p className='text-primary-color6'>Raised</p>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <p className=' text-back-color2 font-[500] text-[16px]'>NGN {projectDetail.pricePerUnit * projectDetail.unitSold}</p>
                    <p className=' text-primary-color'>NGN {projectDetail.projectPriceTotal}</p>
                </div>
                
            </div>
            </div>
            <div className=' my-4'>
            <TransactionChart/>
            </div>
        </div>
    </div>
  )
}

export default ProjectDetailsCom