import React, {useState, useEffect} from 'react'
import Invest from '../../Assets/images/sav.png'
import Line from '../../Assets/images/Line.png'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ButtonPlus from '../SelectValue/ButtonPlus';
import getServices from '../../Services/get-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constant from '../../Constant'
function Topsec() {
    const [show, setShow] = useState(false)
    const[savingsBalance, setSavingsBalance] = useState(0)
    useEffect(() => {
        getServices.getWallerBalance().then(
          (response) => {
           
            if(response.data.status === "2000"){
             
              setSavingsBalance(response.data.data.totalSavingsPlan)
             
            }
            else{
              toast('Session Expired', {
                onClose: () => {
                  window.location.href = '/'
                },
                autoClose: 5000
              })
            }
            
           
            console.log(response.data.data)
            
          },
          (error) => {
            toast('Session Expired', {
              onClose: () => {
                window.location.href = '/'
              },
              autoClose: 5000
            })
          }
        )}, [])

  return (
    <>
      <ToastContainer/>
      <div className=' my-4 border rounded-md p-5 border-[#E6E6E7] md:flex gap-3'>
        <div className=' flex gap-3'>
            <div>
            <img src={Invest}  alt='Invest'/>
            </div>
            <div onClick={ () => setShow(!show)}>
                <p className=' text-back-color2 text-[14px] mb-1'>Savings  balance <span className=' pl-2 text-[#808285]'>{show ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}</span></p>
                <p className=' text-2xl text-back-color2'>{show ? '*******' : `${savingsBalance === 'nu' ? '₦ 0.0': `₦ ${ Constant.FormatNumber(savingsBalance) }`}`}</p>
            </div>
        </div>
        {/* <div className=' pt-2'>
        <img src={Line} className=' hidden md:block' alt='Line'/>
        </div> */}
        {/* <div className=' pt-2'>
        <a href='#plans'>
        <ButtonPlus
                name='Fund a plan'
            />
        </a>
           
        </div> */}
    </div>
    </>
   
  )
}

export default Topsec