import React from 'react'

function InvestComp({img, title, sub, color}) {
  return (
    <div className={` p-4 flex gap-3 border  rounded-lg ${color}`}>
        <div className=' pt-1'>
            <img src={img} width='140' height='80' alt='cardimg' />
        </div>
        <div>
            <p className=' text-[#1E222B] text-[16px] font-[500] mb-1'>{title}</p>
            <p className=' text-back-color2 font-[400] text-[12px] mb-3'>{sub}</p>
        </div>
    </div>
  )
}

export default InvestComp