import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
// import List from '../../Assets/images/listing.png'
import Button from '../SelectValue/Button';
import getServices from '../../Services/get-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Section1 from './Section1';
import Loading from '../SelectValue/Loading';
import BuyUnits from './Buyunit';
import ImageSlider from './ImageSlider';
import Constant from '../../Constant';
const drawerWidth = 650;

function ListPropertyDrawer({open, handleClose, window, id}) {
        const [projectDetails, setProjectDetails]= useState({})
        const [loading, setLoading] = useState(false)
        const [images, setImages] = useState([])
        const [opens, setOpen] = useState(false)
        const handleOpens = () => setOpen(true)
        const handleCloses = () => setOpen(false)
        useEffect(() =>{
            if(id !== undefined){
              setLoading(true)
                getServices.getSingleProject(id).then(
                    (response) => {
                        setLoading(false)
                      if(response.data.status === "2000"){
                        setProjectDetails(response.data.data[0])
                        setImages(response.data.data[0].imageUrl)
                      }else{
                    //    swal(response.data.description)
                       toast('Session Expired', {
                        onClose: () => {
                            window.location.href = '/'
                        },
                        autoClose: 5000
                      })
                      }
                      setLoading(false)
                     
                      console.log(response.data.data)
                      
                    },
                    (error) => {
                        setLoading(false)
                        toast('Session Expired', {
                            onClose: () => {
                              window.location.href = '/'
                            },
                            autoClose: 5000
                          })
                    }
                  )
            }
            
        }, [id])
        
  return (
    <div>
        <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >

       <div >
       <BuyUnits
          open={opens}
          handleClose={handleCloses}
          number={1}
          id={projectDetails.propertyId}
          amount={projectDetails.price}
          type='property'
        />
       <Loading
        open={loading}
       />
       <ToastContainer/>
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 capitalize text-[18px] pt-3'>{projectDetails.title} property for sale </p>
            <div className='pt-2'>
            <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/>
            </div>  
        </div>
        <div className=' px-4 pt-2'>
        <div className=' flex justify-between '>
            <div>
                <p className=' text-primary-color mb-2 text-[14px] capitalize font-[500]'>{projectDetails.projectName}</p>
                <p className=' text-back-color2 font-[500] capitalize text-[14px]'><PlaceOutlinedIcon/> {projectDetails.locality}</p>
            </div>
            <div>
                <p className=' text-back-color2 font-[400] text-[14px]'> <span className=' text-primary-color font-[500] text-[24px]'>NGN {Constant.FormatNumber(projectDetails.price)}  </span></p>
            </div>
        </div>
        <div className=' my-3'>
          <ImageSlider
            imageList={images}
          />
            {/* <img src={projectDetails.imageUrl ?? Listing} alt='Listing' className=' w-full h-56 my-3'/> */}
            {/* <div className=' grid grid-cols-7 gap-4'>
            <img src={List} alt='Listing' className=' my-3'/>
            <img src={List} alt='Listing' className=' my-3'/>
            <img src={List} alt='Listing' className=' my-3'/>
            <img src={List} alt='Listing' className=' my-3'/>
            <img src={List} alt='Listing' className=' my-3'/>
            <img src={List} alt='Listing' className=' my-3'/>
            <img src={List} alt='Listing' className=' my-3'/>
            </div> */}
        </div>
        <div className=' my-5'>
            <Button
                name='Buy this property'
                bool={true}
                onClick={handleOpens}
            />
        </div>
        {/* <div className=' my-5'>
            <p className=' font-[400] text-back-color2 text-center text-[16px]'>Interested in this property?</p>
            <div className=' mt-2 gap-3 flex justify-center'>
                <p className='font-[500] text-[16px] pt-2'> <span className='font-[400] text-[16px] '>Call :</span> 0817 XXXXX</p>
                <Button
                    name='Show phone'
                />
            </div>
        </div> */}
        <div>
        
            <Section1
                des={projectDetails.description}
                type={projectDetails.propertyType}
                bathroom={projectDetails.bedrooms}
                bedrooms={projectDetails.bedrooms}
                street={projectDetails.street}
                locality={projectDetails.locality}
                references={projectDetails.propertyId}
                toilet={projectDetails.toilets}
                duration={projectDetails.projectDuration}
                video={projectDetails.videoLink}
                date={projectDetails.createdDate === undefined ? projectDetails.createdDate :projectDetails.createdDate.slice(0,10)}
            />
        </div>
        </div>
       
         </div>
        </Drawer>
    </div>
  )
}

export default ListPropertyDrawer