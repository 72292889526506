import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import { Outlet } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
 
};

export default function ResetPin() {
   
   
  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' h-[60vh] md:mt-5'>
                <div className=' mt-6 flex justify-end mb-3'>
                    
                    <div>
                    <CancelIcon  className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <div >
                <div className=''>
                  <Outlet/>
               </div>
         
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}