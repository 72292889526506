import React from 'react'
import Back from './Back';
function NavbarBack() {
  return (
    <div className=' flex justify-between p-5 md:px-16 w-full border border-b-[#D2D3D5] bg-white   md:bg-white '>
        
       
        
        <div className=' pt-2'>
           <Back/>
        </div>
    </div>
  )
}

export default NavbarBack