import React from 'react'
import { useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';

function Back() {
    const navigate = useNavigate();
  return (
    <div className='cursor-pointer' onClick={() => navigate(-1)}>
        <WestIcon/> <span className=' pl-2'>Back</span>
    </div>
  )
}

export default Back