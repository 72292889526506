import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Bank from '../../Assets/images/bank.png'
import { Link, useParams } from 'react-router-dom';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};


function BankTransfer({ open, handleClose}) {
  const{bname,aname,accno} = useParams()
  return (
    <div>  
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={true}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={true}>
      <Box sx={style} className=' rounded-lg p-6 pt-10'> 
        <div className=' h-[60vh] '>
            <div className=''>
              
              <div className=' flex justify-end mb-3'>
                  
                    <div>
                    <Link to='/wallet'><CancelIcon  className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/></Link>
                    </div>
              
              </div>
              <div className=' flex justify-center'>
              <div>
               <img src={Bank} className=' mx-auto' alt='Bank'/>
                <p className=' text-center text-[18px] mb-3 font-[500] text-back-color2'>Bank transfer</p>
                <p className=' text-center text-[16px] mb-3 font-[500] text-[#808285]'>Send money to this account to fund your Zouse wallet.</p>
                <div className=' border border-[#808285] rounded-lg my-4 p-4'>
                    <div className=' flex mb-3 justify-between'>
                        <p className=' text-[16px]  font-[500] text-[#808285]'>Bank name</p>
                        <p className=' text-center text-[14px]  font-[500] text-back-color2' >{bname}</p>
                    </div>
                    <div className=' flex mb-3 justify-between'>
                        <p className=' text-[16px]  font-[500] text-[#808285]'>Account number</p>
                        <p className=' text-center text-[14px]  font-[500] text-back-color2' >{accno}</p>
                    </div>
                    <div className=' flex mb-3 justify-between'>
                        <p className=' text-[16px]  font-[500] text-[#808285]'>Account name</p>
                        <p className=' text-center text-[14px]  font-[500] text-back-color2' >{aname}</p>
                    </div>
                </div>
                <div className=' my-7 bg-back-color2 p-4 rounded-lg flex justify-between'>
                    <p className=' text-white text-[16px]'>Copy account details</p>
                    <ContentCopyOutlinedIcon className=' text-white'/>
                </div>
              </div>
              
              </div>
             
            </div>
            
        </div>
      </Box>
    </Fade>
  </Modal></div>
  )
}

export default BankTransfer