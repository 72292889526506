import React from 'react'
import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Allapartmentcom from './Allapartmentcom';
const drawerWidth = 650;
function AllApartment({open, handleClose}) {
  return (
    <div>
         <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
        <>
      
            <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 capitalize text-[18px] pt-3'>All apartment classes</p>
            <div className='pt-2'>
            <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/>
            </div>  
        </div>
      <div className=' p-3'>
        <Allapartmentcom/>
        <Allapartmentcom/>
        <Allapartmentcom/>
      </div>
        </>
        </Drawer>
    </div>
  )
}

export default AllApartment