import React from 'react'
import TopSec from '../../Components/SaveProduct/Topsec'
import Back from '../../Components/Back'

function SaveProducts() {
  return (
    <div>
    <div className=' mb-3'>
        <Back/>
    </div>
        <TopSec/>
    </div>
  )
}

export default SaveProducts