import React, { useState } from 'react'
import House from '../../Assets/images/houso.png'
import RentalSidebar from './RentalSidebar'
function ActiverentCom() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
  return (
    <>
    <RentalSidebar
        open={open}
        handleClose= {handleClose}
    />
 <div onClick={handleOpen} className=' border border-gray-200 my-3 rounded p-3 '>
    <div className=' flex justify-between'>
    <img src={House} alt='House'/>
    <div>
        <p className=' text-[#002615] p-2 text-[12px] rounded-[24px] bg-[#CCF2E1]'>Active</p>
    </div>
    </div>
    <div className=' pt-3'>
        <p className=' font-[500] text-back-color2 text-[14px]'>5-Bed apartment in Lekki (Room 209)</p>
        <p className=' font-[400] text-back-color2 text-[12px]'>Rent tenure - 2 years (1 year, 200 days left)</p>
    </div>
       
    </div>
    </>
   
  )
}

export default ActiverentCom