import  React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../SelectValue/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import PassError from '../SelectValue/PassError';
import PasswordField from '../SelectValue/PasswordFieldC';
import swal from 'sweetalert';
import postService from '../../Services/post-service';
import Loading from '../SelectValue/Loading';
import Pin from './Pin';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function ChangePassword({open, handleClose}) {
  const [error, setError] = useState('');
  const [charError, setCharError] = useState(false);
  const [upperError, setUpperError] = useState(false);
  const [lowerError, setLowerError] = useState(false);
  const [numError, setNumError] = useState(false);
  const [specialError, setSpecialError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [cpassword, setCPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [openn, setOpen] = useState(false)
  const [pin, setPin] = useState('')
  const handleClosee = () => setOpen(false)
  const [password, setPassword] = useState('')
  const handleChange = (event) => {
    setPassword(event.target.value)
    if (password.length < 8) {
      setCharError(true);
    } else {
      setCharError(false);
    }
    if (!password.match(/[A-Z]/)) {
      setUpperError(true);
    } else {
      setUpperError(false);
    }
    if (!password.match(/[a-z]/)) {
      setLowerError(true);
    } else {
      setLowerError(false);
    }
    if (!password.match(/\d/)) {
      setNumError(true);
    } else {
      setNumError(false);
    }
    if (!password.match(/[@$!%*?&]/)) {
      setSpecialError(true);
    } else {
      setSpecialError(false);
    }
    if (password.length <= 10) {
      setMaxError(true);
    } else {
      setMaxError(false);
    }
    if(!charError && !upperError && !lowerError && !numError && !specialError && !maxError) {
      setError('');
    //   onChange(event, true);
    } else {
      setError('Invalid Password');
    //   onChange(event, false);
    }
  };

    const onSubmit = (e) =>{
        e.preventDefault()
        
        console.log(password)
        console.log(cpassword)
        if(password === cpassword){
            setOpen(true)
            
        }else{
          swal('Password not a match')
        }
       
    }
    const onSubmitFinal = (e) =>{
      e.preventDefault()
      setLoading(true)
      if(pin.length === 4){
        postService.changePassword(cpassword,pin).then(
         (response) => {

             console.log(response.data)
            
            if(response.data.status === '2000'){
            setLoading(false)
             return  swal(response.data.description).then((value) => {
              window.location.reload()
            })
            
            }else{
             setLoading(false)
             swal(response.data.description).then((value) => {
              window.location.reload()
            })
            
            }
            
           },
           (error) => {
             setLoading(false)
               return  swal('Change of pin Failed')
             }
       ) 
     }else{
      setLoading(false)
       swal('Pin should be up to 4 digits')
     }
    }
  return (
    <div>
      <Pin
        open={openn}
        handleClose= {handleClosee}
        pin={pin}
        setPin={setPin}
        onSubmit={onSubmitFinal}
        loading={loading}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' h-[70vh] md:mt-8'>
                <div className=' mt-6 flex justify-between mb-3'>
                    <p className=' text-back-color2 text-[18px]  font-[500]'>Change password</p>
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="medium"/>
                    </div>
                </div>
                <div >
        <form onSubmit={onSubmit}> 
        <div className=' w-full mb-3'>
            <PasswordField
                label='Enter your new password'
                onChange={handleChange}
            />
        </div>
        <div className=' w-full mb-3'>
            <PasswordField
                label='Confirm your new password'
                onChange={(e) =>setCPassword(e.target.value)}
                value={cpassword}
            />
        </div>

        <div className='mt-4 flex gap-3'>
        <PassError
            bool={charError}
            text='8+ Characters'
        />
         <PassError
            bool={upperError}
            text='Uppercase'
        />
        <PassError
            bool={lowerError}
            text='Lowercase'
        />
        </div>
        <div className=' mt-4 flex gap-3'>
        <PassError
            bool={numError}
            text='1+ Number'
        />
        <PassError
            bool={specialError}
            text='1 Special Characters'
        />
        </div>
        <div className=' mt-4 flex gap-3'>
        <PassError
            bool={maxError}
            text='10 Characters maximum'
        />
        </div>
       
        <div className=' grid grid-cols-1 mb-5 mt-6'>
        <Button
            name='Change password'
          />
        </div>
       
        </form>
         
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}