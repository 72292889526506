import  React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, Outlet } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
 
  boxShadow: 24,
 
};

export default function ChangePin({ open, handleClose}) {
  
  
  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Box sx={style} className=' rounded-lg p-6 pt-10'> 
            <div className=' h-[70vh] md:mt-5'>
                <div className=' mt-6 flex justify-between mb-3'>
                    <p className=' text-back-color2 text-[18px]  font-[500]'>Change PIN</p>
                    <div>
                    <Link to='/settings'><CancelIcon onClick={handleClose} className=' text-back-color2 cursor-pointer text-[38px]' fontSize="large"/></Link>
                    </div>
                </div>
                <div >
           <Outlet/>
         
          </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}