import React, {useState} from 'react'
import Button from '../SelectValue/Button'
import PinInput from 'react-pin-input';
import postService from '../../Services/post-service';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loading';
import { useNavigate } from 'react-router-dom';
function ResetPinPin() {
    const [pin, setPin] = useState('')
    const [loading, setLoading] = useState(false)
    const requestId = localStorage.getItem('requestIdr')
    const otp = localStorage.getItem('optreset')
    const navigate = useNavigate()
    const onSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)
       postService.resetPin(otp,requestId,pin).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            swal(response.data.description).then((value) => {
              navigate('/settings')
            });
            
           }else{
            swal(response.data.description).then((value) => {
                navigate('/settings')
            });
           
           }
           
          },
          (error) => {
            setLoading(false)
              return  swal('Change of Pin Failed')
              .then((value) => {
                navigate('/settings')
              });
            }
      )
         
    }
  return (
    <div>
    <Loading
        open={loading}
    />
        <div className=' mt-10 '>
            
            <p className=' mb-3 text-back-color2'>Reset your Zouse PIN</p>
            <p className=' mb-3 text-[#808285]'>Enter your 4-Digit Pin  </p>
              <div className='  mt-5'>

              <div >
              <form onSubmit={onSubmit}> 
              <div className=' w-full flex justify-center'>
                      <div className=' my-3'>
                      <PinInput 
                      length={4}
                      initialValue={pin}
                      secret  
                      onChange={(value, index) => {
                          setPin(value)
                      }} 
                      type="numeric" 
                      inputMode="number"
                      style={{padding: '8px', }}  
                      inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                      inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                      onComplete={(value, index) => {
                          pin(value)
                          }}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                      </div>
                      
              </div>

              
              <div className=' grid grid-cols-1 mb-5 mt-6'>
              <Button
                  name='Reset'
                
                />
              </div>
            
              </form>
              
                </div>
            
              </div>
          </div>
    </div>
  )
}

export default ResetPinPin