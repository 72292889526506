import { Drawer } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, {useEffect, useState} from 'react'
import ActiveInvestmentCom from './ActiveInvestmentCom';
import House from '../../Assets/images/hh.png'
import getServices from '../../Services/get-service';
const drawerWidth = 650;

function LandActive({open, handleClose}) {
    
     const [investment, setInvestment] = useState([])
 
     useEffect(() =>{
       getServices.getUserInvestment().then(
         (response) => {
          setInvestment(response.data.data);
        //   if(response.data.status === '2000'){
        //     setInvestment(response.data.data);
        //     console.log(response.data.data)
        //   }else if (response.data.status === '4000') {
        //     // window.location.href = '/';
        //   }
          
           
         },
         (error) => {
           console.log(error.description)
         }
       )
     }, [])
     const approvedData = investment.filter(item => item.projectType === 'land');
  return (
    <div>
             <Drawer
          anchor='right'
          variant="temporary"
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
       <div>
        <div className=' p-4 border-b border-b-[#808285] flex justify-between'>
            <p className=' text-back-color2 text-[18px] pt-3'>Land banking active investments</p>
            <div className='pt-2'>
            <CancelIcon onClick={handleClose} className=' text-back-color2 text-[38px]' fontSize="large"/>
            </div>  
        </div>
       <div className='p-3 mt-5'>
       {approvedData.map(e =>{
        return <div key={e.id}>
        <ActiveInvestmentCom
                img={e.imageUrl ?? House}
                title={e.projectName}
                sub={e.totalAmount}
                link={`projectdetails/details/${e.id}/${e.units}/${e.projectDuration}/${e.cashout.toFixed(2)}/${e.projectId}/${e.projectName}/${e.totalAmount}/${e.percentBought}/${e.endDate}/${e.purchaseId}/${e.exitStatus}`}
            />
        </div> 
       })}
          {approvedData.length === 0 && <p className=' text-[18px] font-bold text-red-700'> No active Land investment</p>}
       </div>
       </div>
       
         
        </Drawer>
    </div>
  )
}

export default LandActive